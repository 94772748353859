import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountryModel, extractDefaultStoreId, HxAuthService, HxStoreService, HxUserService, UserProfileModel } from 'hx-services';
import { Subscription } from 'rxjs';
import { HcToastrService } from '@manager-app/service/toastr.service';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';
import { Pagination } from '@manager-app/models/pagination';

@Component({
  selector: 'app-user-list.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit, OnDestroy {
  @ViewChild('templateRef') public templateRef: TemplateRef<any>;
  @ViewChild('fireTemplateRef') public fireTemplateRef: TemplateRef<any>;
  users: UserProfileModel[] = [];
  pagination: Pagination = {
    currentPage: 1,
    allItemCount: 0,
    limit: 20,
    maxPage: 1,
  };
  query = '';
  page = 1;
  counter = 1;
  isLoading = {
    users: false,
  };
  selectedUser?: UserProfileModel;
  country?: CountryModel;
  storeId?: number;
  private sub: Subscription;

  constructor(
    private modal: NgbModal,
    private userService: HxUserService,
    private toastr: HcToastrService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private storeService: HxStoreService,
    private errorHandler: ErrorHandlerService,
    private auth: HxAuthService,
  ) { }

  ngOnInit() {
    this.sub = this.aRoute.queryParams.subscribe(params => {
      this.page = params['page'] ? Number(params['page']) : 1;
      this.query = params['query'] ?? '';
      this.storeId = params['storeId'] ? Number(params['storeId']) : extractDefaultStoreId();
      if (this.storeId) {
        this.loadList();
      } else {
        this.users = [];
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  showAction(phone: string): boolean {
    return `${phone}`.startsWith(this.country.phonePrefix.replace(/\D+/g, ''));
  }

  removeProfile(item: UserProfileModel) {
    this.selectedUser = {...item};
    this.modal.open(this.templateRef, { size: 'sm', centered: true });
  }

  fireEmployee(item: UserProfileModel) {
    this.selectedUser = {...item};
    this.modal.open(this.fireTemplateRef, { size: 'sm', centered: true });
  }

  removeUser(modal) {
    if (!this.selectedUser.id || !this.storeId) {
      return;
    }

    this.userService.removeUserFromStore(this.selectedUser.id, this.storeId).subscribe(() => {
      this.toastr.success('user.profile.delete.success');
      modal.dismiss();
      this.loadList();
    }, err => {
      this.errorHandler.check(err.error);
      modal.dismiss();
    });
  }

  fire(modal) {
    if (!this.selectedUser.id) {
      return;
    }

    this.userService.fireUser(this.selectedUser.id, this.storeId).subscribe(() => {
      this.toastr.success('user.profile.fire.success');
      modal.dismiss();
      this.loadList();
    }, err => {
      this.errorHandler.check(err.error);
      modal.dismiss();
    });
  }

  getTypeFilter() {
    this.pageChanged(1);
  }

  resetFilter() {
    this.query = '';
    this.storeId = undefined;
    this.pageChanged(1);
  }

  pageChanged(page: number) {
    this.router.navigate(['./'], {
      queryParams: {
        page: page,
        query: this.query,
        storeId: this.storeId,
      },
      relativeTo: this.aRoute
    });
  }

  private loadList() {
    this.users = [];
    this.isLoading.users = true;

    this.storeService.getCountryByStoreId(this.storeId).then(country => {
      this.country = country;
      this.userService.getUserProfiles({
        page: this.page,
        query: this.query,
        storeId: this.storeId,
      }).subscribe(result => {
        this.users = result.list;
        this.pagination.allItemCount = result.count;

        if (this.pagination.currentPage && this.pagination.currentPage > 1) {
          this.counter = (this.pagination.currentPage - 1) * this.pagination.limit + 1;
        } else {
          this.counter = 1;
        }
        this.isLoading.users = false;
      }, () => this.isLoading.users = false);
    });
  }
}
