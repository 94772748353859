import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

/**
 * Own service for toster, implement ng2-toastr methods
 *
 * @author Talgat Uspanov talgat.uspanov@yandex.kz
 * @export
 * @class HcToastrService
 */
@Injectable({
  providedIn: 'root'
})
export class HcToastrService {
  constructor(
    private toastr: ToastrService,
    private translate: TranslocoService
  ) { }

  success(code: string, data?: any) {
    this.toastr.success(this.getTrans(code, data));
  }

  error(code: string, data?: any) {
    this.toastr.error(this.getTrans(code, data));
  }

  warning(code: string, data?: any) {
    this.toastr.warning(this.getTrans(code, data));
  }

  info(code: string, data?: any) {
    this.toastr.info(this.getTrans(code, data));
  }

  errorWithTimeout(code: string, data?: any, timeOut?: number) {
    this.toastr.error(this.getTrans(code, data), null, {timeOut: timeOut});
  }

  private getTrans(code: string, data?: any) {
    return this.translate.translate(code, data);
  }
}
