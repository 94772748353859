import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { SaveResult, TimesheetInfoModel, TimesheetModel, TimesheetRequest, TimesheetResourceService, TimesheetType } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxTimesheetService {

  constructor(
    private timesheetResourceService: TimesheetResourceService,
  ) {
  }

  getTimesheetList(params: {
    storeId: number,
    fromDate?: string,
    toDate?: string,
    employee?: boolean,
    limit?: number,
    page?: number,
    type?: TimesheetType,
    userId?: number,
  }): Promise<TimesheetInfoModel[]> {
    return firstValueFrom(this.timesheetResourceService.getTimesheets(params.employee, params.fromDate, params.limit, params.page, params.storeId, params.toDate, params.type, params.userId));
  }

  getTimesheetById(timesheetId: number): Promise<TimesheetModel> {
    return firstValueFrom(this.timesheetResourceService.getTimesheetById(timesheetId));
  }

  saveTimesheet(request: TimesheetRequest): Promise<SaveResult> {
    return firstValueFrom(this.timesheetResourceService.saveTimesheet(request));
  }
}
