import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';
import { BalanceService } from '@manager-app/service/balance.service';
import { InvoiceBalanceService } from '@manager-app/service/invoice-blalance.service';
import { DiscardBalanceRequest, FileModel, Folder, HxAuthService, HxInvoiceService, InvoiceType, PermissionKey, StoreProductModel } from 'hx-services';

@Component({
  selector: 'app-balance-changer-modal',
  templateUrl: './balance-changer.modal.html',
  styleUrls: ['./balance-changer.modal.css']
})
export class BalanceChangerModalComponent implements OnInit {
  cancelReason: string;
  isLoading = {
    list: false,
    submit: false,
  };
  discardProducts: StoreProductModel[] = [];
  hasDiscardProduct = false;
  fileList: FileModel[] = [];
  deletedFileList: FileModel[] = [];
  folderDiscard = Folder.DISCARD;
  receiveUserId: number;
  invoiceType: InvoiceType;
  invoiceTypeList: { id: InvoiceType, label: string }[] = [
    {id: InvoiceType.DECOMMISSION, label: 'invoiceType.' + InvoiceType.DECOMMISSION},
    {id: InvoiceType.INVENTORY, label: 'invoiceType.' + InvoiceType.INVENTORY},
    {id: InvoiceType.CONSUMPTION, label: 'invoiceType.' + InvoiceType.CONSUMPTION}
  ];
  storeId?: number;

  constructor(
    public modal: NgbActiveModal,
    private balanceService: BalanceService,
    private invoiceService: HxInvoiceService,
    private errorHandlerService: ErrorHandlerService,
    private invoiceBalanceService: InvoiceBalanceService,
    private auth: HxAuthService,
  ) {
  }

  ngOnInit() {
    this.auth.getPermissions({ permissions: [PermissionKey.discard_product_input] }).subscribe(result => {
      this.hasDiscardProduct = result && result.length > 0;
    });
    this.discardProducts = this.invoiceBalanceService.getDiscardProducts();
  }

  submitDiscardBalance() {
    if (!this.discardProducts.length) {
      return;
    }
    const fileIds: number[] = this.fileList.map(r => r.id);

    this.isLoading.submit = true;
    const request: DiscardBalanceRequest = {
      storeId: this.storeId,
      products: [],
      fileIds: fileIds,
      reason: this.cancelReason,
      receiveUserId: this.receiveUserId,
      invoiceType: this.invoiceType
    };
    request.products = this.invoiceBalanceService.getDiscardProducts()
      .map(product => product.productInfo.id)
      .map(productInfoId => ({
        productInfoId: productInfoId,
        amount: this.invoiceBalanceService.getDiscardAmount(productInfoId)
      }));

    this.invoiceService.discardBalance(request).subscribe(() => {
      this.isLoading.submit = false;
      this.modal.close();
    }, err => {
      this.isLoading.submit = false;
      this.errorHandlerService.handler(err.error);
    });
  }

  onFileRemoved(event: { file: FileModel, index: number }) {
    this.fileList.splice(event.index, 1);
    this.deletedFileList.push(event.file);
  }

  onFilesUpdated(event: FileModel) {
    this.fileList.push(event);
  }

  updateUser(event: { id: number, text: string }) {
    this.receiveUserId = event.id;
  }
}
