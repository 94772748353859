import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { InvoiceBalanceService } from '@manager-app/service/invoice-blalance.service';
import { HxAuthService, InvoiceType, PermissionKey, StoreProductModel, toFixed } from 'hx-services';
import { HxToastrService } from 'hx-component';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-store-product',
  templateUrl: './store-product.component.html',
  styleUrls: ['./store-product.component.css']
})
export class StoreProductComponent implements OnInit, OnDestroy, OnChanges {
  @Input() product: StoreProductModel;
  @Input() showDiscardValueInput = false;
  @Input() showDiscardBtn = false;
  @Input() invoiceType: InvoiceType;
  hasDiscardProduct: boolean;
  discardValue: number;
  remainderAmount: number;
  currentAmount: number;
  Inventory = InvoiceType.INVENTORY;
  Decommission = InvoiceType.DECOMMISSION;
  Consumption = InvoiceType.CONSUMPTION;
  positiveValueTypes = [InvoiceType.DECOMMISSION, InvoiceType.CONSUMPTION];
  private sub: Subscription;

  constructor(
    private invoiceBalanceService: InvoiceBalanceService,
    private auth: HxAuthService,
    private toastr: HxToastrService,
    private tr: TranslocoService,
  ) {
  }

  ngOnInit() {
    this.auth.getPermissions({ permissions: [PermissionKey.discard_product_input] }).subscribe(result => {
      this.hasDiscardProduct = result && result.length > 0;
      this.discardValue = this.hasDiscardProduct ? 1 : 0;
    });
    this.sub = this.invoiceBalanceService.balanceObs
      .pipe(map(discardMap => discardMap.get(this.product.productInfo.id)))
      .subscribe(value => {
        this.discardValue = value;
        this.remainderAmount = toFixed(this.product.amount + this.discardValue);
      });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['invoiceType']) {
      if (this.hasDiscardProduct) {
        this.discardValue = 1;
        this.currentAmount = this.product.amount + 1;
      } else if (!this.hasDiscardProduct) {
        this.discardValue = 0;
        this.currentAmount = this.product.amount;
      }
      this.calculate();
    }
  }

  addDiscardAmount() {
    this.invoiceBalanceService.updateDiscardAmount(this.product, toFixed(this.discardValue + 0.25));
  }

  subtractDiscardAmount() {
    const value = toFixed(this.discardValue - 0.25);
    if (this.product.amount - Math.abs(value) >= 0) {
      this.invoiceBalanceService.updateDiscardAmount(this.product, value);
    }
  }

  discard() {
    this.invoiceBalanceService.updateDiscardAmount(this.product, this.discardValue);
  }

  onDiscardValueChanged() {
    this.calculate();
  }

  private calculate() {
    if (!isNaN(Number(String(this.discardValue)))) {
      if (this.hasDiscardProduct) {
        if (this.invoiceType !== InvoiceType.INVENTORY) {
          if (!isNaN(Number(this.discardValue))) {
            this.currentAmount = toFixed(this.product.amount - this.discardValue);
          }
          if (this.discardValue < 0) {
            this.toastr.error(this.tr.translate('store-product.greaterThenZero'));
            this.currentAmount = this.product.amount - 1;
            this.discardValue = 1;
          }
          if (this.currentAmount < 0) {
            this.toastr.error(this.tr.translate('store-product.balanceGreaterThenzero'));
            this.currentAmount = this.product.amount - 1;
            this.discardValue = 1;
          }
        } else {
          this.currentAmount = toFixed(this.product.amount + this.discardValue);
          // if (this.discardValue === 0) {
          //   this.toastr.error(this.tr.translate('store-product.greaterOrLessThenZero'));
          //   this.currentAmount = this.product.amount + 1;
          //   this.discardValue = 1;
          // }
          if (this.currentAmount < 0) {
            this.toastr.error(this.tr.translate('store-product.balanceGreaterThenzero'));
            this.currentAmount = this.product.amount + 1;
            this.discardValue = 1;
          }
        }
      }
      this.invoiceBalanceService.updateDiscardAmount(this.product, this.discardValue);
    }
  }
}

