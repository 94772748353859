import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  AccountType,
  ComponentType,
  EncashmentOperationType,
  extractDefaultStoreId,
  extractNumber,
  HxStoreEncashmentService,
  StoreEncashmentModel,
  stringDateToMilli
} from 'hx-services';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';
import { HcToastrService } from '@manager-app/service/toastr.service';
import { addHours } from 'date-fns';

@Component({
  selector: 'app-store-encashment-list',
  templateUrl: './store-encashment-list.component.html',
  styleUrls: ['./store-encashment-list.component.css']
})
export class StoreEncashmentListComponent implements OnInit, OnDestroy {
  @ViewChild('removeTemplateRef') removeTemplateRef: TemplateRef<any>;
  list: StoreEncashmentModel[] = [];
  pagination = {
    currentPage: 1,
    allItemCount: 0,
    limit: 20,
  };
  isLoading = {
    list: false,
  };
  params: {
    page: number,
    limit: number,
    accountType?: AccountType,
    date?: string,
    userId?: number,
    storeId?: number,
    operationType?: EncashmentOperationType,
    includeDisabled?: boolean,
  } = {page: 1, limit: 20};
  storeId: number;
  storeEncashmentId: number;
  selectedId: number;
  AccountType = AccountType;
  OperationType = EncashmentOperationType;
  ComponentType = ComponentType;
  private sub: Subscription;

  constructor(
    private router: Router,
    private aRoute: ActivatedRoute,
    private modal: NgbModal,
    private tr: TranslocoService,
    private toastr: HcToastrService,
    private storeEncashmentService: HxStoreEncashmentService,
    private errorService: ErrorHandlerService,
  ) {
  }

  ngOnInit(): void {
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.params.userId = extractNumber('userId', paramMap);
      this.params.storeId = extractNumber('storeId', paramMap) ?? extractDefaultStoreId();
      this.params.accountType = paramMap.get('accountType') ? paramMap.get('accountType') as AccountType : undefined;
      this.params.operationType = paramMap.get('operationType') ? paramMap.get('operationType') as EncashmentOperationType : undefined;
      this.params.date = paramMap.get('date');

      this.loadList();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  pageChanged(page: number) {
    this.router.navigate(['./'], {
      queryParams: this.params,
      relativeTo: this.aRoute
    });
  }

  applyFilters() {
    this.pageChanged(1);
  }

  cancel(item: StoreEncashmentModel) {
    const checkDate = addHours(stringDateToMilli(item.date), 1);
    if (checkDate > new Date()) {
      this.selectedId = item.id;
      this.modal.open(this.removeTemplateRef, { size: 'sm', centered: true });
    } else {
      this.toastr.error('store-encashment.cancelDateExpired');
    }
  }

  cancelConfirm(modal) {
    this.storeEncashmentService.removeStoreEncashmentById(this.selectedId).then(() => {
      this.toastr.success('store-encashment.form.remove');
      this.loadList();
      modal.dismiss();
    }).catch(err => {
      this.errorService.handler(err.error);
      modal.dismiss();
    });
  }

  onStoreEncashmentSaved(saved) {
    this.storeEncashmentId = null;
    if (saved) {
      this.loadList();
    }
  }

  updateItem(pa: StoreEncashmentModel) {
    this.storeEncashmentId = pa.id;
  }

  private loadList() {
    this.isLoading.list = true;
    this.list = [];
    this.storeEncashmentService.getStoreEncashmentList(this.params).then(result => {
      this.isLoading.list = false;
      this.list = result.list;
      this.pagination.allItemCount = result.count;
    }).catch(err => {
      this.isLoading.list = false;
      this.errorService.handler(err.error);
    });
  }
}
