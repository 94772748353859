import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, Subscription } from 'rxjs';
import { endOfMonth, format, parseISO, startOfDay, startOfMonth } from 'date-fns';
import {
  DateRange,
  extractDateRange,
  extractDefaultStoreId,
  HxOrderService,
  isoDate,
  PaymentType,
  RemainderProductModel,
  StoreBasicModel,
  toRouterQueryParams,
  uiLabel
} from 'hx-services';
import { CalendarOptions, EventApi, ViewApi } from '@fullcalendar/core';
import { TranslocoService } from '@ngneat/transloco';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import listGridPlugin from '@fullcalendar/list';
import { FullCalendarComponent } from '@fullcalendar/angular';

/**
 * Component Stats of product's balance and reserved
 */
@Component({
  selector: 'app-order-stats.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './order-stats.component.html',
  styleUrls: ['./order-stats.component.css']
})
export class OrderStatsComponent implements OnInit, OnDestroy {
  @ViewChild('templateRef') public templateRef: TemplateRef<any>;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  list: RemainderProductModel[] = [];
  isLoading = {
    calendarEvents: false,
    list: false,
  };
  calendarOptions?: CalendarOptions;
  selectedDate: Date;
  categoryTitle: string;
  params: { date: DateRange, storeId: number } = {
    date: { from: isoDate(startOfMonth(Date.now())), to: isoDate(endOfMonth(Date.now())) },
    storeId: null
  };
  private sub: Subscription;

  constructor(
    private orderService: HxOrderService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private modal: NgbModal,
    private tr: TranslocoService,
  ) {
  }

  ngOnInit() {
    firstValueFrom(this.tr.selectTranslation(this.tr.getActiveLang())).then(() => {
      this.initCalendar();
      this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
        this.params.date = extractDateRange('date', paramMap) ?? {from: isoDate(startOfMonth(Date.now())), to: isoDate(endOfMonth(Date.now()))};
        this.params.storeId = paramMap.get('storeId') ? Number(paramMap.get('storeId')) : extractDefaultStoreId();
        if (this.params.storeId) {
          this.loadList();
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onDateClick(event: {start?: Date}) {
    this.categoryTitle = '';
    if (!this.isPast(event.start)) {
      this.selectedDate = event.start;
      this.showModal();
      this.loadProducts();
    }
  }

  onEventClick(ev: {event: EventApi}) {
    // console.log('onEventClick', ev);
    if (!this.isPast(ev.event.start)) {
      this.categoryTitle = ev.event.title || '';
      this.selectedDate = ev.event.start;
      this.showModal();
      this.loadProducts(ev.event.extendedProps.categoryId);
    } else {
      this.categoryTitle = '';
    }
  }

  onDatesSet(event: {view: ViewApi}) {
    this.params.date.from = isoDate(event.view.currentStart);
    this.params.date.to = isoDate(endOfMonth(event.view.currentStart));
    this.navigateUrl();
  }

  isToday(): boolean {
    const useDate = this.selectedDate.getTime() || Date.now();
    const date = new Date(+useDate);
    const today = new Date();

    return date.toDateString() === today.toDateString();
  }

  getCategoryTitle() {
    let date;
    if (this.selectedDate) {
      date = this.tr.translate('order-stats.ts.behind', {date: format(this.selectedDate, 'dd.MM')});
    }
    return this.tr.translate('order-stats.ts.categories', {categoryTitle: this.categoryTitle, date: date});
  }

  isoDate(date?: number) {
    return date ? isoDate(date) : undefined;
  }

  onStoreChanged(store?: StoreBasicModel | StoreBasicModel[]) {
    this.params.storeId = !!store && !Array.isArray(store) ? store.id : undefined;
    this.navigateUrl();
  }

  onDateChanged() {
    this.navigateUrl();
  }

  private isPast(day: Date) {
    const today = startOfDay(Date.now());
    return day.getTime() < today.getTime();
  }

  private async loadList() {
    // keep colors in 3 column format :) intellijIdea shows color on left side!
    const colors = [
      '#467fcf', '#6574cd', '#a55eea',
      '#f66d9b', '#cd201f', '#fd9644',
      '#f1c40f', '#5eba00', '#2bcbba',
      '#17a2b8', '#868e96', '#343a40',
      '#45aaf2', '#7bd235', '#467fcf',
      '#868e96', '#5eba00', '#45aaf2',
      '#f1c40f', '#cd201f', '#343a40'
    ];
    this.isLoading.calendarEvents = true;

    try {
      const result = await this.orderService.getProductCountsByCategoryAndDate({ fromDate: this.params.date.from, toDate: this.params.date.to, storeIds: [this.params.storeId]});
      const calendarApi = this.calendarComponent.getApi();
      calendarApi.removeAllEvents();
      if (result && result.length) {
        const calendarEvents = result.map(item => {
          const isPastDay = this.isPast(parseISO(item.date)) ? 'is--past-day' : 'is--cursor-pointer';
          return {
            start: new Date(item.date),
            date: item.date,
            backgroundColor: colors[item.categoryId - 1],
            className: `${isPastDay}`,
            title: `${item.productCount} - ${uiLabel(this.tr.getActiveLang(), item.categoryTitle)}`,
            description: uiLabel(this.tr.getActiveLang(), item.categoryTitle),
            categoryId: item.categoryId,
            allDay: true,
          };
        });
        calendarApi.addEventSource(calendarEvents);
      }
    } catch (err: any) {
      console.error('err', err);
    } finally {
      this.isLoading.calendarEvents = false;
    }
  }

  private navigateUrl() {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }

  private loadProducts(categoryIdStr?: string) {
    this.list = [];
    this.isLoading.list = true;
    const categoryId = categoryIdStr ? Number(categoryIdStr) : undefined;
    this.orderService.getRemainders({date: isoDate(this.selectedDate), categoryIds: [categoryId], storeIds: [this.params.storeId]}).then(result => {
      this.list = result;
      this.isLoading.list = false;
    }, () => this.isLoading.list = false);
  }

  private showModal() {
    this.modal.open(this.templateRef, {size: 'lg'})
      .result.then(() => this.clearModalData(), () => this.clearModalData());
  }

  private clearModalData() {
    this.list = [];
    this.selectedDate = null;
  }

  private initCalendar() {
    this.calendarOptions = {
      plugins: [
        dayGridPlugin,
        interactionPlugin,
        listGridPlugin,
      ],
      editable: false,
      selectable: true,
      // eventLimit: false,
      height: 'auto',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,listMonth'
      },
      firstDay: 1,
      eventClick: this.onEventClick.bind(this),
      select: this.onDateClick.bind(this),
      datesSet: this.onDatesSet.bind(this),
      events: [],
      buttonText: {
        today: this.tr.translate('order-stats.ts.today'),
        month: this.tr.translate('order-stats.ts.month'),
        week: this.tr.translate('order-stats.ts.week'),
        list: this.tr.translate('order-stats.ts.list'),
      },
      locale: 'ru',
      allDayText: this.tr.translate('order-stats.ts.allDay'),
    };
  }

  protected readonly PaymentType = PaymentType;
}
