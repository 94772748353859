import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { extractDefaultStoreId, extractNumber, HxInvoiceService, InvoiceModel, InvoiceStatus, isoDate, toRouterQueryParams } from 'hx-services';
import { DataService } from '@manager-app/service/data.service';
import { Subject } from 'rxjs';
import { BalanceService } from '@manager-app/service/balance.service';
import { InvoiceBalanceService } from '@manager-app/service/invoice-blalance.service';
import { HxErrorHandlerService, HxToastrService } from 'hx-component';
import { BalanceNewComponent } from '@manager-app/modal/balance-new/balance-new.component';
import { InvoiceModalComponent } from '@manager-app/modal/invoice/invoice.modal';
import { map, takeUntil } from 'rxjs/operators';
import { BalanceChangerModalComponent } from '@manager-app/modal/balance-changer/balance-changer.modal';

/**
 * Component of invoice list and current balance by stores
 */
@Component({
  selector: 'app-invoice-list.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit, OnDestroy {
  InvoiceStatus = InvoiceStatus;
  list: InvoiceModel[] = [];
  pagination = {
    allItemCount: 0
  };
  isLoading = {
    list: false,
  };
  params: {
    storeId?: number,
    date?: string,
    invoiceStatus?: InvoiceStatus,
    page: number,
    limit: number
  } = {invoiceStatus: InvoiceStatus.SENT, page: 1, limit: 20};
  statusList = DataService.statusList;
  discardProductCount = 0;
  private $destroyed = new Subject<void>();

  constructor(
    private invoiceService: HxInvoiceService,
    private balanceService: BalanceService,
    private modalService: NgbModal,
    private aRoute: ActivatedRoute,
    private router: Router,
    private invoiceBalanceService: InvoiceBalanceService,
  ) {
  }

  ngOnInit() {
    this.aRoute.queryParamMap.pipe(takeUntil(this.$destroyed)).subscribe(paramMap => {
      this.params.date = paramMap.get('date') ?? isoDate(Date.now());
      this.params.invoiceStatus = (paramMap.get('invoiceStatus') as InvoiceStatus) ?? InvoiceStatus.SENT;
      this.params.page = extractNumber('page', paramMap);
      this.params.limit = extractNumber('limit', paramMap);
      this.params.storeId = extractNumber('storeId', paramMap) ?? extractDefaultStoreId();
      if (this.params.storeId) {
        this.loadList();
      }
    });
    this.invoiceBalanceService.balanceObs
      .pipe(takeUntil(this.$destroyed), map(discardMap => discardMap.size))
      .subscribe(discardProductCount => this.discardProductCount = discardProductCount);
  }

  ngOnDestroy() {
    this.invoiceBalanceService.emptyProducts();
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  showInvoiceModal(invoice: InvoiceModel) {
    const modalRef = this.modalService.open(InvoiceModalComponent, {size: 'lg'});
    modalRef.componentInstance.invoice = invoice;
    modalRef.result.then(() => {
      this.balanceService.balanceUpdated();
      this.loadList();
    }, () => {
    });
  }

  exportBalance(): void {
    const modalRef = this.modalService.open(BalanceNewComponent, {size: 'lg'});
    modalRef.componentInstance.storeId = this.params.storeId;
    modalRef.result.then(() => {
      this.balanceService.balanceUpdated();
      this.loadList();
    }, () => {});
  }

  resetFilter() {
    this.params = {page: 1, limit: 20, invoiceStatus: InvoiceStatus.SENT};
    this.navigate();
  }

  applyFilters() {
    this.params.page = 1;
    this.navigate();
  }

  pageChanged(page: number) {
    this.params.page = page;
    this.navigate();
  }

  showDiscardModal() {
    const modalRef = this.modalService.open(BalanceChangerModalComponent, {size: 'lg'});
    modalRef.componentInstance.storeId = this.params.storeId;
    modalRef.result.then(() => {
      this.invoiceBalanceService.emptyProducts();
      this.balanceService.balanceUpdated();
      this.params.page = 1;
      this.loadList();
    }, () => this.invoiceBalanceService.emptyProducts());
  }

  onStoreChanged(storeId: number) {
    this.params.page = 1;
    this.params.storeId = storeId;
    this.navigate();
  }

  private loadList(): void {
    this.isLoading.list = true;
    this.list = [];
    this.invoiceService.getInvoiceList({
      storeId: this.params.storeId,
      date: this.params.date,
      statuses: [this.params.invoiceStatus],
      // productInfoIds: this.params.productInfoIds,
      limit: this.params.limit,
      page: this.params.page,
    }).subscribe(result => {
      this.isLoading.list = false;
      this.list = result.list;
      this.pagination.allItemCount = result.count;
    }, () => this.isLoading.list = false);
  }

  private navigate() {
    this.router.navigate([], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }
}
