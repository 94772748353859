<div class="m-subheader">
  <div class="d-flex align-items-center width-1440">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'dashboard.limits' | transloco }}
      </h3>
      <hx-store-select [(ngModel)]="storeId" (selectChange)="onStoreChanged($event)"></hx-store-select>
    </div>

    <div *ngIf="currentStore">
      <app-dashboard-new [id]="productId" [store]="currentStore" (trigger)="updateData($event)"></app-dashboard-new>
    </div>
  </div>
</div>

<div class="m-content">
  <!--Begin::Main Portlet-->
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height is--bg_gray width-1440">
        <div class="m-portlet__body">
          <div class="calendar-wrap">
            <div class="calendar-header">
              <div class="calendar-header__month-toggle calendar-month">
                <button type="button" (click)="loadMonth('prev')" class="calendar-month__button">
                  <i class="la la-arrow-left"></i>
                </button>

                <span class="calendar-month__title">{{ currentDate }}</span>

                <button type="button" (click)="loadMonth('next')" class="calendar-month__button">
                  <i class="la la-arrow-right"></i>
                </button>
              </div>

              <div class="calendar-header__title">
                {{ currentStore?.title | uiLabel }}
              </div>
            </div>
            <div class="calendar" *ngIf="list.length && !isLoading.calendar">
              <div class="day-names">
                <div class="hc-calendar__weekname" >{{ 'daysWeek.Mon' | transloco }}</div>
                <div class="hc-calendar__weekname" >{{ 'daysWeek.Tue' | transloco }}</div>
                <div class="hc-calendar__weekname" >{{ 'daysWeek.Wed' | transloco }}</div>
                <div class="hc-calendar__weekname" >{{ 'daysWeek.Thu' | transloco }}</div>
                <div class="hc-calendar__weekname" >{{ 'daysWeek.Fri' | transloco }}</div>
                <div class="hc-calendar__weekname" >{{ 'daysWeek.Sat' | transloco }}</div>
                <div class="hc-calendar__weekname" >{{ 'daysWeek.Sun' | transloco }}</div>
              </div>

              <div class="days">
                <ng-container *ngFor="let item of dayList">
                  <div class="day day--has" [ngClass]="{'color-whitesmoke': item.info?.storeWorkDate?.closed}">
                    <a class="day__worktime" [ngbTooltip]="'store.work-time' | transloco" (click)="editStoreWorkDate(item.info?.storeWorkDate?.id, item.date)">
                      <span *ngIf="!item.info?.storeWorkDate?.closed">{{ item.info?.storeWorkDate?.openTime | time }} - {{ item.info?.storeWorkDate?.closeTime | time }}</span>
                      <span *ngIf="item.info?.storeWorkDate?.closed">{{ 'dashboard.store.closed' | transloco }}</span>
                    </a>

                    <span class="day__date">
                      {{ item.date | date: 'dd' }}
                    </span>

                    <ng-container *ngIf="item.info?.data?.plans">
                      <div class="event event--products is--cursor-pointer" (click)="editProducts(item)">
                        <img src="/assets/images/shop.png" class="event__icon" alt="">

                        <span class="event__numb">
                          {{ item.info.data?.plans?.length }}
                        </span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="item.info?.data?.decor">
                      <div class="event event--decor is--cursor-pointer" [ngClass]="{'event--disabled': !item.info?.data?.decor?.enabled}" (click)="updateDecor(item)">
                        <ng-container *ngIf="!isLoading.decor; else loading">
                          <img src="/assets/images/{{ item.info?.data?.decor?.enabled ? 'checked' : 'unchecked' }}.png" class="event__icon" alt="">

                          <span class="event__numb">
                            {{ 'dashboard.designer' | transloco }}
                          </span>
                        </ng-container>
                        <ng-template #loading>
                          <img src="/assets/images/loading.svg" alt="loading...">
                        </ng-template>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="item.info?.data?.inscription">
                      <div class="event event--decor is--cursor-pointer" [ngClass]="{'event--disabled': !item.info?.data?.inscription?.enabled}" (click)="updateInscription(item)">
                        <ng-container *ngIf="!isLoading.inscription; else loading">
                          <img src="/assets/images/{{ item.info?.data?.inscription?.enabled ? 'checked' : 'unchecked' }}.png" class="event__icon" alt="">

                          <span class="event__numb">
                            {{ 'dashboard.inscription' | transloco }}
                          </span>
                        </ng-container>

                        <div class="decor-time" *ngIf="item.info?.data?.inscription.from && item.info?.data?.inscription.to">
                          {{ item.info?.data?.inscription.from | time }} - {{ item.info?.data?.inscription.to | time }}
                        </div>

                        <ng-template #loading>
                          <img src="/assets/images/loading.svg" alt="loading...">
                        </ng-template>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="item.info?.data?.onlineSellable">
                      <div class="event event--decor is--cursor-pointer" [ngClass]="{'event--disabled': !item.info?.data?.onlineSellable?.enabled}" (click)="updateOnlineSellable(item)">
                        <ng-container *ngIf="!isLoading.onlineSellable; else loading">
                          <img src="/assets/images/{{ item.info?.data?.onlineSellable?.enabled ? 'checked' : 'unchecked' }}.png" class="event__icon" alt="">

                          <span class="event__numb">
                            {{ 'dashboard.onlineSale' | transloco }}
                          </span>
                        </ng-container>

                        <ng-template #loading>
                          <img src="/assets/images/loading.svg" alt="loading...">
                        </ng-template>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>

            <hx-loader [isLoading]="isLoading.calendar" [length]="list.length"></hx-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End::Main Portlet-->
</div>
