<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title m-subheader__title--separator">
        {{ 'user-order.title' | transloco }}
      </h3>
    </div>
    <div>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <a routerLink="../" [queryParams]="{dateFrom: params.date?.from, dateTo: params.date?.to, storeIds: params.storeIds.join(',') }">
            {{ 'user-order.back' | transloco }}
          </a>
          <div class="m_datatable m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-20 m--margin-bottom-30">
            <table class="m-datatable__table" *ngIf="list.length && !isLoading.list">
              <thead class="m-datatable__head">
                <tr class="m-datatable__row m-datatable__row--head">
                  <th class="m-datatable__cell m-datatable__cell--sort text-nowrap" (click)="sortTable(menuItem)" *ngFor="let menuItem of menu">
                    <span>
                      {{ menuItem.title | transloco }}
                      <i class="la" [ngClass]="{'la-sort-amount-desc': menuItem.sort === 'desc', 'la-sort-amount-asc': menuItem.sort === 'asc', 'd-none': !menuItem.sort}"></i>
                      <i class="la la-sort" *ngIf="!menuItem.sort"></i>
                    </span>
                  </th>
                  <th class="m-datatable__cell">
                    <span>{{ 'user-order.table.head.status' | transloco }}</span>
                  </th>
                  <th class="m-datatable__cell">
                    <span>{{ 'user-order.table.head.note' | transloco }}</span>
                  </th>
                  <th class="m-datatable__cell text-right">
                    <span>{{ 'user-order.table.head.sum' | transloco }}</span>
                  </th>
                  <th class="m-datatable__cell">
                    <span>{{ 'user-order.table.head.client' | transloco }}</span>
                  </th>
                </tr>
              </thead>
              <tbody class="m-datatable__body">
                <tr *ngFor="let even = even; let i = index; let item of list" class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even }">
                  <td class="m-datatable__cell">
                    <a [routerLink]="'/orders/' + item.order.id">
                      {{ item.order.id }}
                    </a>
                  </td>
                  <td class="m-datatable__cell">
                    <a [routerLink]="'/orders/' + item.order.id">
                      {{ item.order.uniqueNumber }}
                    </a>
                  </td>
                  <td class="m-datatable__cell">{{ item.order.createDate | date:'dd.MM HH:mm' }}</td>
                  <td class="m-datatable__cell">{{ item.order.date | date:'dd.MM HH:mm' }}</td>
                  <td class="m-datatable__cell text-nowrap">
                    <span class="delivery">
                      <i class="la" [ngClass]="{'la-truck': item.order.deliveryType === 'DELIVERY', 'la-building': item.order.deliveryType === 'PICKUP'}" *ngIf="item.order.deliveryType"></i>
                      <i class="la la-truck invisible" *ngIf="!item.order.deliveryType"></i>
                      <i class="la la-paperclip" [ngClass]="{invisible: item.files.length === 0}"></i>
                      <i class="la la-birthday-cake" [ngClass]="{invisible: !item.order.decorExist}"></i>
                    </span>
                    <span class="m-badge  m-badge--{{ statusList[item.order.action]?.badge }} m-badge--wide m-badge--rounded">
                      {{ 'action.' + item.order.action | transloco }}
                    </span>
                  </td>
                  <td class="m-datatable__cell">
                    <div *ngFor="let cake of item.products">
                      <div *ngIf="(cake.status !== 'CANCELLED' || cake.paymentDate) && item.order.action !== 'CANCELLED'">
                        {{ cake.title | uiLabel }} <span *ngIf="cake?.price?.amount !== 1">{{cake?.price?.amount}}</span>
                        —
                        <span>
                          <span *ngIf="cake.type === 'PRODUCT'">{{ cake?.weight | weight }} ×</span> {{ cake.amount }} {{ 'user-order.num' | transloco }}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td class="m-datatable__cell text-right" [ngClass]="{'is--red': item.order.action === 'CANCELLED'}">
                    <i class="la la-bank" *ngIf="item.order.fiscalNumber" [title]="'user-order.fiscalReceipt' | transloco"></i>
                    <ng-container *ngIf="item.order.payment?.payments">
                      <div *ngFor="let pt of item.order.payment.payments">
                        <i [hxPaymentIcon]="pt.type" [ngbTooltip]="'paymentType.' + pt.type | transloco"></i>
                        {{'paymentType.' + pt.type | transloco}} {{ pt.value | uiCurrency:item.order.currency }}
                      </div>
                    </ng-container>
                  </td>
                  <td class="m-datatable__cell">
                    {{ item.client?.phone }}<br>
                    {{ item.client?.fullname }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <hx-loader [length]="list.length" [isLoading]="isLoading.list"></hx-loader>

          <div class="m--align-center" *ngIf="list.length && !isLoading.list && pagination.allItemCount > 0">
            <ngb-pagination [maxSize]="5" [rotate]="true" [collectionSize]="pagination.allItemCount"
                            [(page)]="pagination.currentPage" [pageSize]="pagination.limit" (pageChange)="pageChanged($event)" class="d-flex justify-content-center mt-4">
            </ngb-pagination>
          </div>

          <a *ngIf="list.length && params" routerLink="../" [queryParams]="{dateFrom: params.date?.from, dateTo: params.date?.to, storeIds: params.storeIds.join(',') }">
            {{ 'user-order.back' | transloco }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
