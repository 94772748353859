import { fromEvent as observableFromEvent, merge as observableMerge } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {

    constructor(
      private _http: HttpClient,
    ) {}

    public network() {
      return observableMerge(
        observableFromEvent(window, 'online').pipe(map((e: any) => e.type)),
        observableFromEvent(window, 'offline').pipe(map((e: any) => e.type))
      );
    }
}
