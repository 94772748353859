import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import { AccrualType, EmployeeType } from '../enum';
import { EmployeeContractFullModel, EmployeeContractModel, PagedList } from '../interface';

export interface EmployeeContractRequest {
  id: number;
  storeId: number;
  userId: number;
  type: EmployeeType;
  fromDate: string;
  toDate: string;
  accrualType: AccrualType;
  accrualValue: number;
  bonus: number;
  bonusSalesPercent: number;
  bonusDeliveryValue: number;
  amortizationValue: number;
}

@Injectable({
  providedIn: 'root'
})
export class HxEmployeeContractService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getEmployeeContracts(params?: {limit?: number; page?: number; storeId?: number; userId?: number, type?: EmployeeType, includeDisabled?: boolean}): Promise<PagedList<EmployeeContractFullModel>> {
    return firstValueFrom(this.httpClient.get<PagedList<EmployeeContractFullModel>>(`/api/vanilla/employee-contracts`, { params: toHttpParams(params, true) }));
  }

  getEmployeeContractById(id: number): Promise<EmployeeContractModel> {
    return firstValueFrom(this.httpClient.get<EmployeeContractModel>(`/api/vanilla/employee-contracts/${id}`));
  }

  saveEmployeeContract(body: Partial<EmployeeContractRequest>): Promise<EmployeeContractModel> {
    return firstValueFrom(this.httpClient.post<EmployeeContractModel>(`/api/vanilla/employee-contracts`, body));
  }

  deleteEmployeeContractById(id: number): Promise<EmployeeContractModel> {
    return firstValueFrom(this.httpClient.delete<EmployeeContractModel>(`/api/vanilla/employee-contracts/${id}`));
  }
}
