import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { differenceInMinutes } from 'date-fns';
import { HxErrorHandlerService, HxToastrService } from 'hx-component';
import { FileModel, HxAuthService, HxInvoiceService, InvoiceModel, InvoiceStatus, SourceSystem } from 'hx-services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-invoice-modal',
  templateUrl: './invoice.modal.html',
  styleUrls: ['./invoice.modal.css']
})
export class InvoiceModalComponent implements OnInit {

  @Input() invoice: InvoiceModel;
  isReject = false;
  isLoading = {
    accept: false,
    invoice: false,
    rejectOrCancel: false,
  };
  cancelReason: string;
  canCancel = false;
  image: { name: string, path: SafeResourceUrl, mimeType: string };
  baseLink = `/api/vanilla/files/download/`;
  canAccept = false;
  cancelButtonLabel: string;

  constructor(
    private toastr: HxToastrService,
    private invoiceService: HxInvoiceService,
    private auth: HxAuthService,
    public modal: NgbActiveModal,
    private errorService: HxErrorHandlerService,
    private domSanitizer: DomSanitizer,
    private tr: TranslocoService
  ) {
  }

  async ngOnInit() {
    this.canCancel = differenceInMinutes(Date.now(), new Date(this.invoice.sendDate)) <= 20;
    const hasStoreMembership = await this.auth.hasStoreMembership(this.invoice.toStore.id);
    this.canAccept = this.invoice && this.invoice.status === InvoiceStatus.SENT && hasStoreMembership;
    this.cancelButtonLabel = await this.getButtonLabel();
    this.isLoading.invoice = true;
    this.invoiceService.getInvoiceById(this.invoice.id).subscribe(result => {
      this.invoice = result;
      this.isLoading.invoice = false;
    }, () => this.isLoading.invoice = false);
  }

  accept() {
    this.isLoading.accept = true;
    this.invoiceService.acceptInvoice(this.invoice.id).subscribe(() => {
      this.toastr.success(this.tr.translate('invoice-modal.ts.invoiceSuccess'));
      this.isLoading.accept = false;
      this.modal.close();
    }, err => {
      this.isLoading.accept = false;
      this.errorService.check(err.error);
    });
  }

  async rejectOrCancelInvoice() {
    const hasStoreMembership = await this.auth.hasStoreMembership(this.invoice.fromStore.id);
    if (this.invoice.status === InvoiceStatus.SENT && hasStoreMembership && this.invoice.sourceSystem === SourceSystem.CASHBOX) {
      this.cancelInvoice();
    } else {
      this.rejectInvoice();
    }
  }

  rejectInvoice() {
    this.isLoading.rejectOrCancel = true;
    this.invoiceService.rejectBalance(this.invoice.id, this.cancelReason).subscribe(() => {
      this.toastr.success(this.tr.translate('invoice-modal.ts.invoiceFatal'));
      this.isLoading.rejectOrCancel = false;
      this.modal.close();
    }, err => {
      this.isLoading.rejectOrCancel = false;
      this.errorService.check(err.error);
    });
  }

  cancelInvoice() {
    this.isLoading.rejectOrCancel = true;
    this.invoiceService.cancelInvoice(this.invoice.id, this.cancelReason).subscribe(() => {
      this.toastr.success(this.tr.translate('invoice-modal.ts.invoiceFatal'));
      this.isLoading.rejectOrCancel = false;
      this.modal.close();
    }, err => {
      this.isLoading.rejectOrCancel = false;
      this.errorService.check(err.error);
    });
  }

  showImage(file: FileModel): void {
    this.image = {
      name: file.fileName,
      mimeType: file.mimeType,
      path: this.domSanitizer.bypassSecurityTrustResourceUrl(this.baseLink + file.guid)
    };
  }

  private async getButtonLabel() {
    const hasStoreMembership = await this.auth.hasStoreMembership(this.invoice.fromStore.id);
    if (this.invoice.status === InvoiceStatus.SENT && hasStoreMembership && this.invoice.sourceSystem === SourceSystem.CASHBOX) {
      return this.tr.translate('btn.cancel');
    } else {
      return this.tr.translate('btn.reject');
    }
  }
}
