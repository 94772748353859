import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { HcToastrService } from '@manager-app/service/toastr.service';
import { KeycloakService } from 'keycloak-angular';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(
    private keycloakService: KeycloakService,
    private toastr: HcToastrService,
    private tr: TranslocoService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedReq = req.clone({ headers: req.headers.set('Accept-Language', this.tr.getActiveLang()) });
    return next.handle(clonedReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse) {
        if (error.error && error.error.code) {
          if (error.error.status === 413) {
            this.toastr.warning(this.tr.translate('http-interceptor.fileSize'));
          }
        }

        if (error.status === 401) {
          this.keycloakService.logout();
        } else if (error.status === 502) {
          this.toastr.error(this.tr.translate('http-interceptor.errorService', {status: error.status}));
          this.toastr.info(this.tr.translate('http-interceptor.upDate'));
        } else if (error.status === 500) {
          this.toastr.error(this.tr.translate('http-interceptor.error', {status: error.status, statusText: error.statusText}));
        }
      }

      return observableThrowError(error);
    }));
  }

}
