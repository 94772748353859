import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountType, EncashmentOperationType, HxStoreEncashmentService, StoreEncashmentModel, StoreEncashmentRequest } from 'hx-services';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';
import { HcToastrService } from '@manager-app/service/toastr.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-store-encashment-edit',
  templateUrl: './store-encashment-edit.component.html',
  styleUrls: ['./store-encashment-edit.component.css']
})
export class StoreEncashmentEditComponent implements OnInit {
  @ViewChild('templateRef') templateRef: TemplateRef<any>;

  @Input()
  set id(id) {
    this._id = id || null;
    if (id) {
      this.getStoreEncashmentById();
    }
  }

  get id() {
    return this._id;
  }

  private _id: number;
  @Input() storeId!: number;
  @Output() storeEncashmentSave = new EventEmitter<boolean>();
  isLoading = {
    form: false,
  };
  isEdit = false;
  storeEncashmentId: number;
  storeEncashmentForm!: UntypedFormGroup;
  AccountType = AccountType;
  OperationType = EncashmentOperationType;
  excludedOperationTypes: string[] = [];

  constructor(
    private aRoute: ActivatedRoute,
    private storeEncashmentService: HxStoreEncashmentService,
    private errorService: ErrorHandlerService,
    private router: Router,
    private toastr: HcToastrService,
    private fb: UntypedFormBuilder,
    public modal: NgbModal,
    private tr: TranslocoService
  ) {
  }

  ngOnInit() {
    this.createForm();
  }

  onSubmitForm(modal) {
    const obj: StoreEncashmentRequest = this.storeEncashmentForm.getRawValue();
    obj.storeId = this.storeId;
    this.storeEncashmentService.saveStoreEncashment(obj).then(() => {
      this.toastr.success(this.tr.translate('store-encashment.form.save'));
      this.storeEncashmentSave.emit(true);
      modal.dismiss();
    }).catch(err => this.errorService.handler(err.error));
  }

  showModal() {
    this.isEdit = false;
    this.addStoreEncashment();
  }

  closeModal(modal) {
    this.storeEncashmentSave.emit(false);
    this.storeEncashmentForm.reset();
    if (modal) {
      modal.dismiss();
    }
  }

  onAccountTypeChanged(type?: string | string[]) {
    if (type && Array.isArray(type)) {
      return;
    }
    this.excludedOperationTypes = [];
    if (AccountType.CASH === type) {
      this.excludedOperationTypes = [EncashmentOperationType.ADJUSTMENT];
    } else if (AccountType.BANK_ACCOUNT === type) {
      this.excludedOperationTypes = [EncashmentOperationType.COLLECTION];
    } else {
      this.storeEncashmentForm.controls['operationType'].patchValue(null);
    }
  }

  private createForm(): void {
    if (!this.storeEncashmentForm) {
      this.storeEncashmentForm = this.fb.group({
        id: [''],
        value: [null, [Validators.required]],
        collectorFullname: [null],
        accountType: [null, [Validators.required]],
        operationType: [null, [Validators.required]],
        note: [''],
      });
    }
  }

  private getStoreEncashmentById() {
    this.isLoading.form = true;
    setTimeout(() => {
      this.addStoreEncashment();
    }, 0);
    this.storeEncashmentService.getStoreEncashmentById(this.id).then(result => {
      if (result) {
        this.storeEncashmentId = result.id;
        this.updateForm(result);
      }
      this.isLoading.form = false;
    }).catch(err => {
      this.errorService.handler(err.error);
      this.isLoading.form = false;
    });
  }

  private updateForm(item: StoreEncashmentModel) {
    this.isEdit = true;
    this.storeEncashmentForm.patchValue(item);
    this.storeEncashmentForm.controls['value'].patchValue(item.balance);
  }

  private addStoreEncashment() {
    const modalRef = this.modal.open(this.templateRef, {size: 'lg'});
    modalRef.result.then(result => {
      this.closeModal(result);
    }, err => {
      this.closeModal(err);
    });
  }


}
