<div class="product" *ngIf="product">
  <div class="product__title">
    {{ product.productInfo.title | uiLabel }}
  </div>

  <div class="product__footer">
    <span class="product__numbers">
      <span class="product__numb">
        {{ product.amount }} {{ product.unitOfMeasure?.globalShortTitle | uiLabel }}
      </span>

      <span *ngIf="hasDiscardProduct && showDiscardValueInput" class="product__numb--input">
        <input class="form-control m-input m-input--mini" type="number" [(ngModel)]="discardValue" (ngModelChange)="onDiscardValueChanged()">
      </span>

      <span *ngIf="hasDiscardProduct && showDiscardValueInput" class="product__numb">
        {{ currentAmount }} {{ product.unitOfMeasure?.globalShortTitle | uiLabel }}
      </span>

      <ng-container *ngIf="!hasDiscardProduct && invoiceType">
        <span class="product__numb">
          {{ discardValue }}
        </span>
        <span class="product__numb">
          {{ remainderAmount }}
        </span>
      </ng-container>
    </span>

    <span>
      <ng-container *ngIf="hasDiscardProduct || showDiscardBtn">
        <button *ngIf="!showDiscardValueInput && showDiscardBtn" class="hc-button hc-button--toggler" type="button" (click)="discard()">
          <span class="la la-pencil-square" [ngbTooltip]="'store-product.add' | transloco"></span>
        </button>
      </ng-container>
      <ng-container *ngIf="!hasDiscardProduct && !showDiscardBtn && invoiceType">
        <button class="hc-button hc-button--toggler" type="button" *ngIf="(positiveValueTypes.includes(invoiceType) && discardValue > 0)
          || !positiveValueTypes.includes(invoiceType)" (click)="subtractDiscardAmount()">−</button>
        <button class="hc-button hc-button--toggler" type="button" (click)="addDiscardAmount()">+</button>
      </ng-container>
    </span>
  </div>
</div>
