import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';
import { DeliveryService, WeekDaySelection } from '@manager-app/service/delivery.service';
import { HxToastrService } from 'hx-component';
import { DateRange, DeliveryRangeModel, isoDate } from 'hx-services';

@Component({
  selector: 'app-delivery-new',
  templateUrl: './delivery-new.component.html',
  styleUrls: ['./delivery-new.component.css']
})
export class DeliveryNewComponent {
  dateRange: DateRange;
  ranges: DeliveryRangeModel[] = [];
  range: Partial<DeliveryRangeModel> = {};
  isLoading = {
    save: false
  };
  days: WeekDaySelection = {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true
  };
  weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  storeId: number;

  constructor(
    private deliveryService: DeliveryService,
    private toastr: HxToastrService,
    private errorHandlerService: ErrorHandlerService,
    private modalInstance: NgbActiveModal,
  ) {
  }

  addNewRange(): void {
    this.ranges.push({} as DeliveryRangeModel);
  }

  deleteRange(ix: number): void {
    this.ranges.splice(ix, 1);
  }

  save() {
    if (this.isLoading.save) {
      return;
    }
    this.isLoading.save = true;
    this.deliveryService.generateRanges({
      from: isoDate(this.dateRange.from),
      to: isoDate(this.dateRange.to),
      ranges: this.ranges,
      weekDays: this.days,
      storeId: this.storeId,
    }).then(() => {
      this.isLoading.save = false;
      this.modalInstance.close();
    }).catch(err => {
      this.errorHandlerService.handler(err.error);
      this.isLoading.save = false;
    });
  }

  dismiss() {
    this.modalInstance.dismiss();
  }
}
