<header class="m-grid__item m-header">
  <div class="m-container m-container--fluid m-container--full-height">
    <div class="m-stack m-stack--ver m-stack--desktop">
      <!-- BEGIN: Brand -->
      <div class="m-stack__item m-brand  m-brand--skin-dark" [ngClass]="{'is--test': !isProd}">
        <div class="m-stack m-stack--ver m-stack--general">
          <div class="m-stack__item m-stack__item--middle m-brand__logo">
            <a routerLink="/index" class="m-brand__logo-wrapper">
              <img src="./assets/images/logo-big.png" alt="logo">
            </a>
          </div>

          <div class="m-stack__item m-stack__item--middle m-brand__tools">
            <a (click)="toggleSidebar()" class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block">
              <span></span>
            </a>

            <a (click)="toggleMobSidebar()" class="open-sidebar m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block">
              <span class="open-sidebar"></span>
            </a>

            <a (click)="toggleTopBar()" href="javascript:;" class="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block">
              <span></span>
            </a>
          </div>
        </div>
      </div>

      <div class="m-stack__item m-stack__item--fluid m-header-head" [ngClass]="{'is--test': !isProd}" [attr.data-test]="'site.is.test' | transloco">
        <div class="m-topbar  m-stack m-stack--ver m-stack--general">
          <hx-language-select></hx-language-select>
          <div class="m-stack__item m-topbar__nav-wrapper">
            <ul class="m-topbar__nav m-nav m-nav--inline">
             <hc-notifier class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center m-dropdown--mobile-full-width" [ngClass]="{'m-dropdown--open': showDropdown }" (update)="showDropdown = $event"></hc-notifier>

              <li
                class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                [ngClass]="{ 'm-dropdown--open': submenu }">
                <a (click)="toggleSubmenu()" class="m-nav__link m-dropdown__toggle">
                  <span class="m-topbar__userpic">
                    <img src="./assets/images/operator.jpg" class="m--img-rounded m--marginless m--img-centered" alt=""/>
                  </span>
                  <span class="m-topbar__username m--hide">
                    {{ fullname }}
                  </span>
                  <span class="m--online" [ngClass]="{'m--online-disable': !isOnline}" [title]="'header.status' | transloco"></span>
                </a>

                <div class="m-dropdown__wrapper">
                  <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                  <div class="m-dropdown__inner">
                    <div class="m-dropdown__header m--align-center" style="background: url(./assets/images/user_profile_bg.jpg); background-size: cover;">
                      <div class="m-card-user m-card-user--skin-dark">
                        <div class="m-card-user__pic">
                          <img src="./assets/images/operator.jpg" class="m--img-rounded m--marginless" alt=""/>
                        </div>
                        <div class="m-card-user__details">
                          <span class="m-card-user__name m--font-weight-500" routerLink="/profile">
                            {{ fullname }}
                          </span>
                          <span *ngIf="position" class="m-card-user__email m--font-weight-300 m-link">
                            {{ position }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="m-dropdown__body">
                      <div class="m-dropdown__content">
                        <ul class="m-nav m-nav--skin-light">
                          <li class="m-nav__item">
                            <a class="m-nav__link" target="_blank" [href]="profileUrl">
                              <span class="m-nav__link-text">
                                {{ 'header.profile' | transloco }}
                              </span>
                            </a>
                          </li>
                          <li class="m-nav__separator m-nav__separator--fit"></li>
                          <li class="m-nav__item">
                            <a class="btn btn-danger" (click)="logout()">
                              {{ 'header.logOff' | transloco }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- END: Topbar -->
      </div>
    </div>
  </div>
</header>
