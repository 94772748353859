import { Component, Input, OnInit } from '@angular/core';
import { HxTimesheetService, isoDate, TimesheetModel, TimesheetType, UserBasicModel } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HcToastrService } from '@manager-app/service/toastr.service';

@Component({
  selector: 'app-timesheet-edit',
  templateUrl: './timesheet-edit.component.html',
  styleUrls: ['./timesheet-edit.component.css']
})
export class TimesheetEditComponent implements OnInit {
  @Input() timesheet: TimesheetModel;
  @Input() user: UserBasicModel;
  @Input() timesheetType: TimesheetType;
  @Input() month: string;
  entranceTime: string;
  exitTime: string;
  type: TimesheetType;
  value: number;
  TimesheetType = TimesheetType;
  Attendance = TimesheetType.ATTENDANCE;
  isTimeDisplay = true;
  isLoading = {
    save: false,
  };
  Bonus = TimesheetType.BONUS;

  constructor(
    private modal: NgbActiveModal,
    private tr: TranslocoService,
    private toastr: HcToastrService,
    private timesheetService: HxTimesheetService
  ) {
  }

  ngOnInit(): void {
    if (this.timesheet) {
      this.type = this.timesheet.type;
      if (this.type) {
        this.onTypeChanged();
      }
      this.entranceTime = this.timesheet.entranceTime;
      this.exitTime = this.timesheet.exitTime;
      if (this.timesheetType === this.Bonus) {
        this.value = this.timesheet.value;
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), this.month ? Number(this.month) - 1 : date.getMonth(), 1);
        const lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
        this.timesheet.date = isoDate(lastDay);
      }
    } else {
      this.reset();
    }
  }

  onTypeChanged() {
    this.entranceTime = undefined;
    this.exitTime = undefined;
    if (this.type === this.Attendance) {
      this.isTimeDisplay = true;
    } else {
      this.isTimeDisplay = false;
    }
  }

  save() {
    if (this.timesheetType === this.Bonus) {
     this.type = TimesheetType.BONUS;
     if (!this.value) {
       this.toastr.error(this.tr.translate('timesheet-edit.valueEmpty'));
       return;
     }
    }
    this.isLoading.save = true;
    this.timesheetService.saveTimesheet( {
      id: this.timesheet.id,
      userId: this.timesheet.userId,
      storeId: this.timesheet.storeId,
      date: this.timesheet.date,
      type: this.type,
      entranceTime: this.entranceTime,
      exitTime: this.exitTime,
      value: this.value,
    }).then(() => {
      this.isLoading.save = false;
      this.toastr.success(this.tr.translate('user-edit.saveData'));
      this.modal.close();
    }).catch(() => {
      this.isLoading.save = false;
      this.modal.close();
    });
  }

  dismiss() {
    this.modal.dismiss();
  }

  numericOnly(evt): boolean {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  private reset() {
    this.type = undefined;
    this.entranceTime = undefined;
    this.exitTime = undefined;
  }
}
