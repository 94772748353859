import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Service to subscribe for update reminder
 *
 * @export
 * @class RemainderService
 */
@Injectable({
  providedIn: 'root'
})
export class RemainderService {
  trigger = new Subject<any>();
  triggerEvent = new Subject<any>();

  update(value?: any): void {
    const val = value;
    this.trigger.next(val);
  }

  updateEvent(value?: any): void {
    this.triggerEvent.next(value);
  }
}
