import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { HeaderService } from './header.service';
import { environment } from '@manager-env/environment';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HcToastrService } from '@manager-app/service/toastr.service';
import { KeycloakService } from 'keycloak-angular';
import { HxAuthService } from 'hx-services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isOnline = window.navigator.onLine ?? true;
  profileUrl = `${location.protocol}//profile.${location.hostname.split('.').slice(1).join('.')}`;
  fullname!: string;
  position!: string;
  open = false;
  submenu = false;
  isProd = environment.production;
  showDropdown = false;

  @HostListener('document:click', ['$event']) handleKeyboardEvent(event) {
    if (this.submenu) {
      if (!this._eref.nativeElement.contains(event.target)) {
        this.toggleSubmenu();
      }
    }
  }

  constructor(
    private _eref: ElementRef,
    private headerService: HeaderService,
    private toastr: HcToastrService,
    private keycloakService: KeycloakService,
    private router: Router,
    private modal: NgbModal,
    private auth: HxAuthService,
  ) {
    const ls = localStorage.getItem('sidebar');
    this.open = ls === 'minimize';
    if (this.open) {
      this.toggleSidebar();
    }
  }

  ngOnInit() {
    this.checkNetwork();

    const user = this.auth.user;
    this.fullname = user.shortname;
    this.position = user.position || 'Mr. Nobody';
  }

  private checkNetwork() {
    this.headerService.network().subscribe(result => {
      let code = 'warning';
      this.isOnline = result === 'online';
      const msg = `network.${result}`;
      if (result === 'online') {
        code = 'success';
      }
      this.toastr[code](msg);
    });
  }

  toggleSidebar() {
    const body = document.getElementsByTagName('body')[0];
    const hasClass = body.className.indexOf('--minimize');

    if (hasClass !== -1 && !this.open) {
      body.classList.remove('m-aside-left--minimize');
      body.classList.remove('m-brand--minimize');
      this.setLs('');
    } else {
      body.classList.add('m-aside-left--minimize');
      body.classList.add('m-brand--minimize');
      this.setLs('minimize');
    }

    this.open = !this.open;
  }

  toggleTopBar() {
    const body = document.getElementsByTagName('body')[0];
    const hasClass = body.className.indexOf('m-topbar--on');

    if (hasClass !== -1) {
      body.classList.remove('m-topbar--on');
    } else {
      body.classList.add('m-topbar--on');
    }
  }

  toggleMobSidebar() {
    const body = document.getElementsByTagName('body')[0];
    const hasClass = body.className.indexOf('--on');

    if (hasClass !== -1 && this.open) {
      body.classList.remove('m-aside-left--on');
    } else {
      body.classList.add('m-aside-left--on');
    }

    this.open = !this.open;
  }

  toggleSubmenu() {
    this.submenu = !this.submenu;
  }

  private setLs(str: string) {
    localStorage.setItem('sidebar', str);
  }

  logout() {
    this.keycloakService.logout();
  }

  navigateUrl(url: string) {
    this.toggleSubmenu();
    this.router.navigateByUrl(`/${url}`);
  }
}
