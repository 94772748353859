import { NgModule } from '@angular/core';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserSearchComponent } from './user-search/user-search.component';
import { HeaderComponent } from './header/header.component';
import { HcNotifierComponent } from './hc-notifier/hc-notifier.component';
import { HcAlertComponent } from './hc-alert/hc-alert.component';
import { AvatarUploadComponent } from './avatar-upload/avatar-upload.component';
import { HcSwitcherComponent } from './hc-switcher/hc-switcher.component';
import { HcIconComponent } from './hc-icon/hc-icon.component';
import { StoreProductsSearchComponent } from './store-products-search/store-products-search.component';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { HxPipeModule } from 'hx-services';
import { NgbAccordionModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreProductSelectComponent } from '@manager-app/components/store-product-select/store-product-select.component';
import { OrganizationSelectComponent } from '@manager-app/components/organization-select/organization-select.component';
import { SuppliersSearchComponent } from '@manager-app/components/suppliers-search/suppliers-search.component';
import { HxComponentModule } from 'hx-component';

const componentList = [
  FooterComponent,
  SidebarComponent,
  UserSearchComponent,
  HeaderComponent,
  HcAlertComponent,
  AvatarUploadComponent,
  HcSwitcherComponent,
  HcIconComponent,
  StoreProductsSearchComponent,
  HcNotifierComponent,
  StoreProductSelectComponent,
  OrganizationSelectComponent,
  SuppliersSearchComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    HxPipeModule,
    NgbDatepickerModule,
    HxComponentModule,
    NgbAccordionModule
  ],
  declarations: componentList,
  exports: componentList,
  providers: [],
})
export class ComponentModule {
}
