import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import { AccountType, EncashmentOperationType } from '../enum';
import { PagedList, StoreEncashmentModel } from '../interface';

@Injectable({
  providedIn: 'root'
})
export class HxStoreEncashmentService {

  constructor(private http: HttpClient) {
  }

  getStoreEncashmentList(params: {
    page?: number,
    limit?: number,
    accountType?: AccountType,
    date?: string,
    userId?: number,
    storeId?: number,
    operationType?: EncashmentOperationType,
    includeDisabled?: boolean,
  }): Promise<PagedList<StoreEncashmentModel>> {
    return firstValueFrom(this.http.get<PagedList<StoreEncashmentModel>>(`/api/vanilla/store-encashments`, {params: toHttpParams(params, true)}));
  }

  getStoreEncashmentById(storeEncashmentId: number): Promise<StoreEncashmentModel> {
    return firstValueFrom(this.http.get<StoreEncashmentModel>(`/api/vanilla/store-encashments/${storeEncashmentId}`));
  }

  saveStoreEncashment(request: StoreEncashmentRequest): Promise<StoreEncashmentModel> {
    return firstValueFrom(this.http.post<StoreEncashmentModel>(`/api/vanilla/store-encashments`, request));
  }

  removeStoreEncashmentById(id: number): Promise<void> {
    return firstValueFrom(this.http.delete<void>(`/api/vanilla/store-encashments/${id}`));
  }
}

export interface StoreEncashmentRequest {
  id?: number;
  collectorFullname?: string;
  value: number;
  note?: string;
  storeId: number;
  accountType: AccountType;
  operationType: EncashmentOperationType;
}
