import { Component, Input, OnInit } from '@angular/core';
import { AccrualType, EmployeeContractFullModel, EmployeeType, HxEmployeeContractService } from 'hx-services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HcToastrService } from '@manager-app/service/toastr.service';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-user-contract',
  templateUrl: './user-contract.component.html',
  styleUrls: ['./user-contract.component.css']
})
export class UserContractComponent implements OnInit {
  @Input() userId: number;
  @Input() storeId: number;
  @Input() contract: EmployeeContractFullModel;

  isEdit = false;
  EmployeeType = EmployeeType;
  AccrualType = AccrualType;

  employeeType: EmployeeType;
  accrualType: AccrualType;
  fromDate: string;
  toDate: string;
  accrualValue: number;
  bonus: number;
  bonusSalesPercent: number;
  bonusDeliveryValue: number;
  amortizationValue: number;

  isLoading = {
    save: false
  };

  constructor(
    private modal: NgbActiveModal,
    private employeeContractService: HxEmployeeContractService,
    private toastr: HcToastrService,
    private errorHandlerService: ErrorHandlerService,
    private tr: TranslocoService
  ) { }

  ngOnInit(): void {
    this.updateData();
  }

  modalDismiss() {
    this.modal.dismiss();
  }

  save() {
    this.isLoading.save = true;
    this.employeeContractService.saveEmployeeContract({
      id: this.contract?.id,
      storeId: this.storeId,
      userId: this.userId,
      type: this.employeeType,
      fromDate: this.fromDate,
      toDate: this.toDate,
      accrualType: this.accrualType,
      accrualValue: this.accrualValue,
      bonus: this.bonus,
      bonusSalesPercent: this.bonusSalesPercent,
      bonusDeliveryValue: this.bonusDeliveryValue,
      amortizationValue: this.amortizationValue,
    }).then(() => {
      this.toastr.success(this.tr.translate('user-contract.save.success'));
      this.isLoading.save = false;
      this.reset();
      this.modal.close(this.userId);
    }).catch(err => {
      this.isLoading.save = false;
      this.errorHandlerService.handler(err.error);
    });
  }

  private reset() {
    this.employeeType = undefined;
    this.fromDate = undefined;
    this.toDate = undefined;
  }

  private updateData() {
    if (this.contract) {
      this.isEdit = true;
      this.employeeType = this.contract.type;
      this.fromDate = this.contract.fromDate;
      this.toDate = this.contract.toDate;
      this.accrualType = this.contract.accrualType;
      this.accrualValue = this.contract.accrualValue;
      this.bonus = this.contract.bonus;
      this.bonusSalesPercent = this.contract.bonusSalesPercent;
      this.bonusDeliveryValue = this.contract.bonusDeliveryValue;
      this.amortizationValue = this.contract.amortizationValue;
    }
  }
}
