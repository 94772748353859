<div class="modal-header">
  <h3 class="modal-title">{{ 'delivery-edit-modal.title-1' | transloco }} {{range.date | date: 'dd.MM.yyyy'}}, {{ 'delivery-edit-modal.title-2' | transloco }}: {{range.reserved}}</h3>

  <button class="close" type="button" (click)="modal.dismiss()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <div class="m-form m-form--state">
    <div class="m-portlet__body">
      <div class="m-form__section m-form__section--first">
        <div class="row">
          <div class="col-md-4">
            <label class="col-form-label is--required">
              {{ 'delivery-edit-modal.time' | transloco }}:
            </label>

            <div>
              <input type="text" [disabled]="range.reserved > 0" [(ngModel)]="range.fromTime" class="time-range-input form-control m-input" [placeholder]="'delivery-edit-modal.timeOpen' | transloco" [dropSpecialCharacters]="false" [mask]="'00:00'">
              <input type="text" [disabled]="range.reserved > 0" [(ngModel)]="range.toTime" class="time-range-input form-control m-input" [placeholder]="'delivery-edit-modal.timeClose' | transloco" [dropSpecialCharacters]="false" [mask]="'00:00'">
            </div>
          </div>

          <div class="col-md-4">
            <label class="col-form-label is--required">
              {{ 'delivery-edit-modal.limit' | transloco }}:
            </label>

            <div>
              <input type="text" [(ngModel)]="range.limit" class="time-range-input form-control m-input">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer modal-footer--pad-right">
      <button (click)="save()" [disabled]="isLoading.save" class="btn btn-outline-primary"
              [ngClass]="{'m-loader m-loader--light m-loader--primary m-loader--left': isLoading.save}">
        {{(isLoading.save ? 'delivery-edit-modal.saving' : 'delivery-edit-modal.save') | transloco}}
      </button>

      <button type="button" (click)="modal.dismiss()" class="btn btn-secondary">
        {{ 'button.cancel' | transloco }}
      </button>
    </div>
  </div>
</div>
