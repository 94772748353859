import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import { DateRange, PagedList, PaymentApprovalModel } from '../interface';
import { PaymentApprovalStatus } from '../enum';

@Injectable({
  providedIn: 'root'
})
export class HxPaymentApprovalService {

  constructor(private http: HttpClient) {
  }

  getPaymentApprovalList(params?: {
    page?: number,
    limit?: number,
    supplierId?: number,
    query?: string,
    date?: DateRange,
    status?: PaymentApprovalStatus,
    includeDisabled?: boolean,
    storeId?: number,
    paid?: boolean;
  }): Observable<PagedList<PaymentApprovalModel>> {
    return this.http.get<PagedList<PaymentApprovalModel>>(`/api/vanilla/payment-approvals`, {params: toHttpParams(params, true)});
  }

  getPaymentApprovalById(paymentApprovalId: number): Observable<PaymentApprovalModel> {
    return this.http.get<PaymentApprovalModel>(`/api/vanilla/payment-approvals/${paymentApprovalId}`);
  }

  savePaymentApproval(request: PaymentApprovalRequest): Observable<{id: number}> {
    return this.http.post<{id: number}>(`/api/vanilla/payment-approvals`, request);
  }

  removePaymentApproval(paymentApprovalId: number): Observable<void> {
    return this.http.delete<void>(`/api/vanilla/payment-approvals/${paymentApprovalId}`);
  }

  cancelPaymentApproval(paymentApprovalId: number, reason: string): Observable<void> {
    return this.http.post<void>(`/api/vanilla/payment-approvals/${paymentApprovalId}/cancel`, {reason: reason});
  }

  paidPaymentApproval(body: {ids: number[]}): Observable<void> {
    return this.http.post<void>(`/api/vanilla/payment-approvals/paid`, body);
  }

  removePaymentApprovalInvoice(paymentApprovalId: number, invoicePaymentId: number): Observable<void> {
    return this.http.delete<void>(`/api/vanilla/payment-approvals/${paymentApprovalId}/invoice-payment/${invoicePaymentId}/cancel`);
  }
}

export interface PaymentApprovalRequest {
  id: number;
  storeId: number;
  supplierId: number;
  bankAccountId: number;
  date: string;
  total: number;
  note: string;
  paymentDestinationCode: string;
  invoicePayments: InvoicePaymentRequest[];
}

export interface InvoicePaymentRequest {
  invoiceId: number;
  value: number;
}
