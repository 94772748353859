<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'work-history.title' | transloco }}
      </h3>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="hx-filters">
            <div class="filter">
              <small>{{ 'work-history.shop' | transloco }}</small>
              <hx-store-select [(ngModel)]="params.storeId" (selectChange)="applyFilters()"></hx-store-select>
            </div>
            <div class="filter">
              <small>{{ 'work-history.dates' | transloco }}</small>
              <hx-date-range-select [(ngModel)]="params.date" (selectChange)="applyFilters()"></hx-date-range-select>
            </div>
            <div class="filter">
              <small>{{ 'work-history.confectioner' | transloco }} <ng-container *ngIf="!params.storeId">({{ 'work-history.chooseShop' | transloco }})</ng-container></small>
              <hx-user-select [(ngModel)]="params.userId" [disabled]="!params.storeId" [storeId]="params.storeId"
                              [preload]="true"
                              [components]="[ComponentType.cf]" (selectChange)="applyFilters()"></hx-user-select>
            </div>
            <div class="filter filter__query">
              <small>{{ 'work-history.products' | transloco }}</small>
              <input type="text" required [(ngModel)]="params.query" (ngModelChange)="applyFilters(800)" name="searchText" class="form-control m-input" [placeholder]="'work-history.searchProcess' | transloco">
            </div>
          </div>

          <button type="button" (click)="resetFilter()" class="btn btn-outline-primary m--margin-top-10">
            <span>{{ 'reset.list' | transloco }}</span>
          </button>

          <div class="m_datatable m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-20 m--margin-bottom-30" *ngIf="list.length && !isLoading.list">
            <table class="m-datatable__table">
              <thead class="m-datatable__head">
              <tr class="m-datatable__row m-datatable__row--head">
                <th class="m-datatable__cell">
                  <span>{{ 'work-history.date' | transloco }}</span>
                </th>
                <th class="m-datatable__cell">
                  <span>{{ 'work-history.user' | transloco }}</span>
                </th>
                <th class="m-datatable__cell">
                  <span>{{ 'work-history.process' | transloco }}</span>
                </th>
                <th class="m-datatable__cell">
                  <span>{{ 'work-history.step' | transloco }}</span>
                </th>
                <th class="m-datatable__cell">
                  <span>{{ 'work-history.product' | transloco }}</span>
                </th>
                <th class="m-datatable__cell">
                  <span>{{ 'work-history.weight' | transloco }}</span>
                </th>
                <th class="m-datatable__cell">
                  <span>{{ 'work-history.number' | transloco }}</span>
                </th>
              </tr>
              </thead>
              <tbody class="m-datatable__body">
              <tr *ngFor="let even = even; let i = index; let item of list"
                  class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even }">
                <td class="m-datatable__cell">
                  {{ item.createDate | date: 'dd.MM.yy HH:mm' }}
                </td>
                <td class="m-datatable__cell">
                  {{ item.userFullname }}
                </td>
                <td class="m-datatable__cell">
                  {{ item.workflowName }}
                </td>
                <td class="m-datatable__cell">
                  {{ item.workflowStepName }}
                </td>
                <td class="m-datatable__cell text-nowrap">
                  {{ item.productInfoTitle | uiLabel }}
                </td>
                <td class="m-datatable__cell">
                  {{ item.weight }}
                </td>
                <td class="m-datatable__cell">
                  {{ item.totalAmount }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <hx-loader [length]="list.length" [isLoading]="isLoading.list"></hx-loader>

          <div class="m--align-center" *ngIf="list.length && !isLoading.list && pagination.allItemCount > 0">
            <ngb-pagination [maxSize]="5" [rotate]="true" [collectionSize]="pagination.allItemCount"
                            [(page)]="params.page" [pageSize]="params.limit" (pageChange)="pageChanged($event)" class="d-flex justify-content-center mt-4">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
