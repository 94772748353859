import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import {
  extractDefaultStoreId,
  extractNumber,
  HxTimesheetService,
  isoDate,
  StoreBasicModel,
  TimesheetInfoModel,
  TimesheetModel,
  TimesheetType,
  toRouterQueryParams,
  UserBasicModel
} from 'hx-services';
import { Subscription } from 'rxjs';
import { getISODay } from 'date-fns';
import { TimesheetEditComponent } from '@manager-app/pages/timesheet/edit/timesheet-edit.component';

@Component({
  selector: 'app-timesheet-list',
  templateUrl: './timesheet-list.component.html',
  styleUrls: ['./timesheet-list.component.css']
})
export class TimesheetListComponent implements OnInit, OnDestroy {
  list: TimesheetInfoModel[] = [];
  isLoading = {
    list: false,
  };
  params: {
    storeId?: number;
    fromDate?: string;
    toDate?: string;
    month?: string;
  } = {};
  dateinfos: {date: string, dayOfWeek: number}[] = [];
  entranceTime: string;
  exitTime: string;
  type: TimesheetType;
  TimesheetType = TimesheetType;
  Attendance = TimesheetType.ATTENDANCE;
  months: string[] = ['1','2','3','4','5','6','7','8','9','10','11','12'];
  private sub: Subscription;

  constructor(
    private router: Router,
    private aRoute: ActivatedRoute,
    private modal: NgbModal,
    private tr: TranslocoService,
    private timesheetService: HxTimesheetService
  ) {
  }

  ngOnInit(): void {
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.params.storeId = extractNumber('storeId', paramMap) ?? extractDefaultStoreId();
      this.params.month = paramMap.get('month') ?? String(new Date().getMonth() + 1);
      if (this.params.storeId) {
        this.loadList(this.params.month);
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onStoreChanged(store?: StoreBasicModel | StoreBasicModel[]) {
    this.applyFilters();
  }

  applyFilters() {
    this.navigateUrl();
  }

  openModal(timesheet: TimesheetModel, user: UserBasicModel) {
    const modalInstance = this.modal.open(TimesheetEditComponent, {size: 'sm'});
    modalInstance.componentInstance.timesheet = timesheet;
    modalInstance.componentInstance.user = user;

    modalInstance.result
      .then(() => {
        this.loadList();
      })
      .catch(err => {
        console.error(err);
      });
  }

  openBonusModal(timesheet: TimesheetModel, user: UserBasicModel) {
    const modalInstance = this.modal.open(TimesheetEditComponent, {size: 'sm'});
    modalInstance.componentInstance.timesheet = timesheet;
    modalInstance.componentInstance.user = user;
    modalInstance.componentInstance.timesheetType = TimesheetType.BONUS;
    modalInstance.componentInstance.month = this.params.month;

    modalInstance.result
      .then(() => {
        this.loadList();
      })
      .catch(err => {
        console.error(err);
      });
  }

  onModelChanged(month: string) {
    this.params.month = month;
    this.navigateUrl();
  }

  private navigateUrl() {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }

  private loadList(month?: string) {
    this.isLoading.list = true;
    this.list = [];
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), month ? Number(month) - 1 : date.getMonth(), 1);
    this.params.fromDate = isoDate(firstDay);
    const lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
    this.params.toDate = isoDate(lastDay);
    this.dateinfos = this.getDatesBetweenDates(firstDay, lastDay);
    this.timesheetService.getTimesheetList({ storeId: this.params.storeId, fromDate: this.params.fromDate, toDate: this.params.toDate}).then(result => {
      this.list = result;
      this.isLoading.list = false;
    }).catch(() => this.isLoading.list = false);
  }

  private getDatesBetweenDates(startDate: Date, endDate: Date): {date: string, dayOfWeek: number}[] {
    let dates = [];
    const theDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 1);
    while (theDate < endDate) {
      dates = [...dates, new Date(theDate)];
      theDate.setDate(theDate.getDate() + 1);
    }
    let dateinfos: {date: string, dayOfWeek: number}[] = [];
    dates.forEach(date => {
      dateinfos.push({ date: date, dayOfWeek: getISODay(date) });
    });

    return dateinfos;
  }
}
