<div class="modal-header">
  <h3 class="modal-title">{{ 'balance-changer.title' | transloco }}</h3>

  <button class="close" type="button" (click)="modal.dismiss()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <div class="m--margin-top-20">
    <label class="is--required">
      {{ 'balance-changer.shop' | transloco }}:
    </label>

    <hx-store-select [(ngModel)]="storeId"></hx-store-select>
  </div>

  <div class="m--margin-top-20">
    <label class="is--required">
      {{ 'balance-changer.type' | transloco }}:
    </label>

    <select [(ngModel)]="invoiceType" name="invoiceType" class="form-control m-input m-input--square">
      <option *ngFor="let type of invoiceTypeList" [value]="type.id">{{ type.label | transloco }}</option>
    </select>
  </div>

  <div class="m--margin-top-20">
    <div class="warning-block-style" *ngIf="invoiceType && invoiceType === 'INVENTORY'">
      <span class="warning-text-style">{{ 'balance-changer.setPositiveOrNegativeNumbers' | transloco }}</span>
    </div>
    <div class="warning-block-style" *ngIf="invoiceType && invoiceType !== 'INVENTORY'">
      <span class="warning-text-style">{{ 'balance-changer.setPositiveNumbers' | transloco }}</span>
    </div>
  </div>

  <div class="product-wrap m--margin-bottom-20 m--margin-top-20">
    <ng-container *ngFor="let item of discardProducts">
      <app-store-product class="product-wrap__item" [showDiscardValueInput]="invoiceType !== undefined" [invoiceType]="invoiceType" [product]="item"></app-store-product>
    </ng-container>
  </div>

  <hx-loader [isLoading]="isLoading.list" [length]="discardProducts.length"></hx-loader>

  <div class="m--margin-top-20">
    <label class="is--required">
      {{ 'balance-changer.fileEntry' | transloco }}:
    </label>

    <hx-file-list [fileList]="fileList" (fileRemove)="onFileRemoved($event)"></hx-file-list>

    <hx-file-upload (updateFiles)="onFilesUpdated($event)" [size]="5" [folder]="folderDiscard"></hx-file-upload>
  </div>

  <div class="m--margin-top-20">
    <label>
      {{ 'balance-changer.withhold' | transloco }}:
    </label>

    <app-user-search (update)="updateUser($event)"></app-user-search>
  </div>

  <div class="m--margin-top-20">
    <label class="is--required" for="cancelReason">{{ 'balance-changer.cause' | transloco }}:</label>

    <textarea id="cancelReason" class="form-control m-input" [(ngModel)]="cancelReason"></textarea>
  </div>

  <div class="text-right m--margin-top-15">
    <button type="button" [disabled]="isLoading.submit" (click)="submitDiscardBalance()" class="hc-button hc-button--success">
      {{ 'btn.save' | transloco }}
    </button>

    <button type="button" (click)="modal.dismiss()" class="hc-button hc-button--secondary">
      {{ 'btn.cancel' | transloco }}
    </button>
  </div>
</div>
