import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moneyFormat'
})
export class HxMoneyFormatPipe implements PipeTransform {

  transform(value: number, fraction?: number): any {
    if (!value) {
       return 0;
    }
    const parts = (value ?? 0).toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
  }
}
