import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HxStoreWorkTimeService, StoreWorkTimeModel } from 'hx-services';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';

@Component({
  selector: 'app-store-work-time-modal',
  templateUrl: './store-work-time.modal.html',
  styleUrls: ['./store-work-time.modal.css']
})
export class StoreWorkTimeModal implements OnInit {
  @Input() id: number;
  @Input() date: string;
  @Input() storeId: number;
  isLoading = {
    save: false
  };
  result: StoreWorkTimeModel;
  openTime: string;
  closeTime: string;
  closed: boolean;

  constructor(
    private modalInstance: NgbActiveModal,
    private storeWorkTimeService: HxStoreWorkTimeService,
    private toastr: ToastrService,
    private tr: TranslocoService,
    private errorService: ErrorHandlerService,
  ) {
  }

  ngOnInit(): void {
    this.storeWorkTimeService.getStoreWorkTimeById(this.id).then(result => {
      this.result = result;
      this.openTime = this.result.openTime;
      this.closeTime = this.result.closeTime;
      this.closed = this.result.closed;
    });
  }

  dismissModal() {
    this.modalInstance.dismiss();
  }

  save() {
    this.isLoading.save = true;
    this.storeWorkTimeService.saveStoreWorkTime({
      storeId: this.storeId,
      openTime: this.openTime,
      closeTime: this.closeTime,
      date: this.date,
      closed: this.closed,
    }).then(() => {
      this.isLoading.save = false;
      this.toastr.success(this.tr.translate('work-time-modal.success'));
      this.modalInstance.close();
    }).catch(err => this.errorService.handler(err.error))
      .finally(() => this.isLoading.save = false);
  }
}
