<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'settings.title' | transloco }}
      </h3>
    </div>
  </div>
</div>

<div class="m-content">
  <!--Begin::Main Portlet-->
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">

          <div class="row">
            <div class="col-md-12">
              <hx-store-select [(ngModel)]="storeId" (selectChange)="onStoreChanged($event)"></hx-store-select>
            </div>
          </div>

          <hr>

          <ng-container *ngIf="settings">
            <div class="row align-items-center m--margin-top-15">
              <div class="col-md-4">
                {{ 'settings.applyLimit' | transloco }}:
                <br>
                <small>{{ 'settings.test-1' | transloco }}</small>
              </div>

              <div class="col-md-3">
                <input type="number" [(ngModel)]="settings.onlineBeforeCloseLimit" class="form-control m-input">
              </div>
            </div>

            <div class="row align-items-center m--margin-top-10">
              <div class="col-md-4">
                {{ 'settings.signatory' | transloco }}:
              </div>

              <div class="col-md-6">
                <hx-user-search [userId]="settings.signUserId" (userChange)="onUserChanged($event)"></hx-user-search>
              </div>
            </div>

            <div class="row align-items-center m--margin-top-10">
              <div class="col-md-4">
                {{ 'settings.saleSite' | transloco }}
              </div>

              <div class="col-md-6">
                <span class="m-switch m-switch--icon m-switch--outline  m-switch--success">
                  <label class="m--margin-bottom-0">
                    <input [disabled]="isBalanceSell" type="checkbox" [(ngModel)]="isBalanceSell" (change)="onBalanceSellChanged()">
                    <span></span>
                  </label>
                </span>
              </div>
            </div>

            <div class="row align-items-center m--margin-top-10">
              <div class="col-md-4">
                {{ 'settings.sources' | transloco }}
              </div>

              <div class="col-md-8">
                <ng-select [(ngModel)]="settings.referrers" [multiple]="true" bindValue="code">
                  <ng-option *ngFor="let ref of referrers" [disabled]="!ref.enabled" [value]="ref.code">{{ref.title | uiLabel}}</ng-option>
                </ng-select>
              </div>
            </div>

            <button (click)="saveSettings()" [disabled]="isLoading.save" class="btn btn-primary m--margin-top-5"
                    [ngClass]="{'m-loader m-loader--light m-loader--primary m-loader--left': isLoading.save}">
              {{(isLoading.save ? 'settings.saving' : 'settings.save') | transloco}}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <!--End::Main Portlet-->
</div>
