import { Injectable } from '@angular/core';
import { DocsType, Gender, MaritalStatus } from 'hx-services';

// Interfaces
interface Item {
  badge: string;
}

interface Status {
  [key: string]: Item;
}

interface Event {
  [key: string]: Item;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  static pagination = {
    currentPage: 1,
    allItemCount: 0,
    limit: 20,
    maxPage: 1
  };
  static acceptFileImages = `image/*`;
  static defaultAvatarUrl = `/assets/images/profile/default-avatar.svg`;
  static eventList: Event = {
    'ORDER_CREATED': {
      badge: 'info'
    },
    'ORDER_MODIFIED': {
      badge: 'brand'
    },
    'ORDER_PACKED': {
      badge: 'warning'
    },
    'ORDER_DECORED': {
      badge: 'focus'
    },
    'ORDER_SHIPPED': {
      badge: 'warning'
    },
    'ORDER_RECEIVED': {
      badge: 'success'
    },
    'ORDER_CANCELLED': {
      badge: 'danger'
    },
    'ORDER_NEW': {
      badge: 'info'
    },
    'ORDER_REJECTED': {
      badge: 'danger'
    },
    'ORDER_SHIPMENT': {
      badge: 'warning'
    },
    'ORDER_DELIVERED': {
      badge: 'focus'
    },
    'ORDER_PAID': {
      badge: 'success'
    },
    'ORDER_TAKEN': {
      badge: 'warning'
    },
    'ORDER_RETURNED': {
      badge: 'metal'
    },
    'ORDER_REFUNDED': {
      badge: 'danger'
    },
    'ORDER_UNARCHIVED': {
      badge: 'light'
    },
    'INVOICE_DISCARDED': {
      badge: 'danger'
    },
    'INVOICE_NEW': {
      badge: 'info'
    },
    'INVOICE_ACCEPTED': {
      badge: 'success'
    },
    'INVOICE_REJECTED': {
      badge: 'danger'
    },
    'INVOICE_SENT': {
      badge: 'warning'
    },
    'INVOICE_CANCELLED': {
      badge: 'danger'
    },
    'INVOICE_PAID': {
      badge: 'warning'
    },
  };
  static statusList: Status = {
    'CREATED': {
      badge: 'info'
    },
    'PACKED': {
      badge: 'warning'
    },
    'DECORED': {
      badge: 'focus'
    },
    'SHIPPED': {
      badge: 'warning'
    },
    'RECEIVED': {
      badge: 'success'
    },
    'CANCELLED': {
      badge: 'danger'
    },
    'NEW': {
      badge: 'info'
    },
    'REJECTED': {
      badge: 'danger'
    },
    'SHIPMENT': {
      badge: 'warning'
    },
    'DISCARDED': {
      badge: 'focus'
    },
    'DELIVERED': {
      badge: 'focus'
    },
    'PAID': {
      badge: 'success'
    },
    'COMPLETED': {
      badge: 'success'
    },
    'TAKEN': {
      badge: 'warning'
    },
    'RETURNED': {
      badge: 'metal'
    },
    'REFUNDED': {
      badge: 'metal'
    },
    'SENT': {
      badge: 'info'
    },
    'ACCEPTED': {
      badge: 'success'
    },
  };

  static limits = [
    {id: 20},
    {id: 100},
    {id: 1000},
    {id: 10000},
  ];

  static createArray(obj): string[] {
    return Object.keys(obj).map(key => obj[key]);
  }

  static getGenderList(): string[] {
    return this.createArray(Gender);
  }

  static getMaritalStatusList(): string[] {
    return this.createArray(MaritalStatus);
  }

  static getDocTypeList(): DocsType[] {
    return Object.keys(DocsType).map(key => DocsType[key]);
  }

  static getImgUrl(fileId: number): string {
    return `${location.origin}/api/vanilla/files/download/${fileId}`;
  }

  constructor() {
  }
}
