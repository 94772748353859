import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HcToastrService } from '@manager-app/service/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ComponentType,
  ConfectionaryProductModel,
  DateRange,
  extractDateRange,
  extractDefaultStoreId,
  extractNumber,
  HxProductService,
  toRouterQueryParams
} from 'hx-services';

@Component({
  selector: 'app-work-history.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './work-history.component.html',
  styleUrls: ['./work-history.component.css']
})
export class WorkHistoryComponent implements OnInit, OnDestroy {
  ComponentType = ComponentType;
  list: ConfectionaryProductModel[] = [];
  pagination = {
    allItemCount: 0
  };
  isLoading = {
    list: false,
  };
  params: {
    storeId?: number;
    date?: DateRange;
    query?: string;
    userId?: number;
    page: number;
    limit: number;
  } = {page: 1, limit: 20};
  private sub: Subscription;
  private timeout: NodeJS.Timeout;

  constructor(
    private productService: HxProductService,
    private toastr: HcToastrService,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.params.storeId = extractNumber('storeId', paramMap) ?? extractDefaultStoreId();
      this.params.date = extractDateRange('date', paramMap);
      this.params.query = paramMap.get('query');
      this.params.userId = extractNumber('userId', paramMap);
      this.params.page = extractNumber('page', paramMap);
      this.params.limit = extractNumber('limit', paramMap);
      if (this.params.storeId) {
        this.loadList();
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  pageChanged(event: number) {
    this.params.page = event;
    this.navigate();
  }

  resetFilter() {
    this.params = {page: 1, limit: 20};
    this.navigate();
  }

  applyFilters(delay?: number) {
    const applyFn = () => this.pageChanged(1);
    if (delay) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => applyFn(), delay);
    } else {
      applyFn();
    }
  }

  private loadList() {
    this.list = [];

    this.isLoading.list = true;
    this.productService.getCfProductList(this.params).subscribe(result => {
      this.list = result.list || [];
      this.pagination.allItemCount = result.count;
      this.isLoading.list = false;
    }, () => {
      this.isLoading.list = false;
    });
  }

  private navigate() {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }
}
