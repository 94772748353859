import { Component, Input, OnInit } from '@angular/core';
import { HcToastrService } from '@manager-app/service/toastr.service';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HxAuthService, HxInvoiceService, HxStoreService, isoDate, ProductInfoType, StoreBasicModel } from 'hx-services';
import { StoreProductItem } from '@manager-app/components/store-products-search/store-products-search.component';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-balance-new.m-grid__item.m-grid__item--fluid',
  templateUrl: './balance-new.component.html',
  styleUrls: ['./balance-new.component.css']
})
export class BalanceNewComponent implements OnInit {
  @Input() storeId?: number;
  invoiceFormKeys = {
    fromStoreId: 'fromStoreId',
    toStoreId: 'toStoreId',
    description: 'description',
    products: 'products'
  };
  productFormKeys = {
    productInfoId: 'productInfoId',
    amount: 'amount'
  };
  isLoading = {
    submit: false,
  };
  invoiceForm!: UntypedFormGroup;
  productInfoTypes: ProductInfoType[] = [ProductInfoType.INGREDIENT, ProductInfoType.PROPERTY, ProductInfoType.PRODUCT, ProductInfoType.MATERIAL_ASSETS, ProductInfoType.NON_MATERIAL_ASSETS];
  stores: StoreBasicModel[] = [];

  constructor(
    private toastr: HcToastrService,
    private errorService: ErrorHandlerService,
    private modal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private auth: HxAuthService,
    private storeService: HxStoreService,
    private invoiceService: HxInvoiceService,
    private tr: TranslocoService,
  ) {
  }

  ngOnInit(): void {
    this.createForm();
    if (this.storeId) {
      this.storeService.getFullStore(this.storeId).then(store => this.onFromStoreChanged(store));
    }
  }

  dismissModal() {
    this.modal.dismiss();
  }

  onSubmitForm() {
    // eslint-disable-next-line guard-for-in
    for (const i in this.invoiceForm.controls) {
      this.invoiceForm.controls[i].markAsDirty();
      this.invoiceForm.controls[i].updateValueAndValidity();
    }
    if (this.invoiceForm.valid) {
      this.invoiceForm.disable();

      this.isLoading.submit = true;
      const obj = this.invoiceForm.getRawValue();
      this.invoiceService.createInvoice(obj).subscribe(() => {
        this.invoiceForm.enable();
        this.toastr.success(this.tr.translate('balance-new.ts.invoiceSuccess'));
        this.isLoading.submit = false;
        this.modal.close();
      }, err => {
        this.invoiceForm.enable();
        this.isLoading.submit = false;
        this.errorService.handler(err.error);
      });
    }
  }

  addItem() {
    this.asFormArray(this.invoiceFormKeys.products).push(this.createItem());
  }

  removeItem(index: number) {
    this.asFormArray(this.invoiceFormKeys.products).removeAt(index);
  }

  asFormArray(path: string): UntypedFormArray {
    return this.invoiceForm.get(path) as UntypedFormArray;
  }

  onStoreProductSelected(item: StoreProductItem, index: number): void {
    this.asFormArray(this.invoiceFormKeys.products).at(index).patchValue({productInfoId: item.productInfoId});
  }

  onFromStoreChanged(store?: StoreBasicModel | StoreBasicModel[]) {
    if (store && !Array.isArray(store)) {
      this.storeService.getOwnerAllStores(store.id).subscribe(stores => this.stores = stores.filter(r => r.id !== store.id && r.endWorkDate > isoDate(Date.now())));
    } else {
      this.stores = [];
    }
  }

  private createForm(): void {
    this.invoiceForm = this.fb.group({
      fromStoreId: [this.storeId, [Validators.required]],
      toStoreId: [null, [Validators.required]],
      description: [null],
      products: this.fb.array([], [Validators.required]),
    });
  }

  private createItem() {
    return this.fb.group({
      productInfoId: [null, [Validators.required]],
      amount: [null, [Validators.required, Validators.min(0)]],
    });
  }
}
