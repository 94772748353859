/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RoyaltyStatus = 'CREATED' | 'PAID' | 'EXEMPT' | 'NOT_LOADED';

export const RoyaltyStatus = {
    CREATED: 'CREATED' as RoyaltyStatus,
    PAID: 'PAID' as RoyaltyStatus,
    EXEMPT: 'EXEMPT' as RoyaltyStatus,
    NOT_LOADED: 'NOT_LOADED' as RoyaltyStatus
};

