import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ComponentType,
  CountryModel,
  DiscountResultType,
  DocsType,
  DscPromoModel,
  EmployeeContractFullModel,
  EmployeeType,
  extractNumber,
  Gender,
  getFormErrors,
  HxEmployeeContractService,
  HxFileService,
  HxStoreService,
  HxUserService,
  MaritalStatus,
  StoreBasicModel,
  UserMembershipRequest,
  UserProfileModel,
  UserSaveRequest
} from 'hx-services';
import { HcToastrService } from '@manager-app/service/toastr.service';
import { takeUntil } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { UserContractComponent } from '@manager-app/pages/user/user-contract/user-contract.component';

interface FormUserInfoModel {
  id: number;
  phone: string;
  firstname: string;
  lastname: string;
  patronymic: string;
  email: string;
  birthdate: string;
  gender: Gender;
  address: string;
  maritalStatus: MaritalStatus;
  iin: string;
  position: string;
}

interface StoreInfoModel extends StoreBasicModel {
  checked?: boolean;
}

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit, OnDestroy {
  @ViewChild('templateRef') public templateRef: TemplateRef<any>;
  @ViewChild('elBlock') el: ElementRef;
  id: number;
  MaritalStatus = MaritalStatus;
  DocsType = DocsType;
  Gender = Gender;
  EmployeeType = EmployeeType;

  formUserInfo!: UntypedFormGroup;
  isLoading = {
    user: false,
    save: false,
    contract: false,
    membership: false,
    discount: false,
    removeDiscount: false,
    store: false
  };
  isEdit = false;
  country?: CountryModel;
  store?: StoreBasicModel;
  userProfile!: UserProfileModel;
  contracts: EmployeeContractFullModel[] = [];
  discounts: DscPromoModel[] = [];
  discountId: number;
  customPatterns = {
    'X': {
      pattern: new RegExp('/|0|4|5|6|7|/'),
    }, '9': {
      pattern: new RegExp('/|9|/'),
    }, 'N': {
      pattern: new RegExp('^(?!3)'),
    }, '0': {
      pattern: new RegExp('\\d'),
    }
  };
  components = Object.keys(ComponentType).map(key => ComponentType[key])
    .filter(cmp => ![ComponentType.profile, ComponentType.adm, ComponentType.cc].includes(cmp));
  componentMap = new Map<string, boolean>();
  employee = true;
  activeNav?: string;
  selectedContract?: EmployeeContractFullModel;
  discountTypeMap = new Map<DiscountResultType, { symbol: string }>([
    [DiscountResultType.PERCENT_DISCOUNT, {symbol: '%'}]
  ]);
  isEmployeeDiscountExist = false;
  stores: StoreInfoModel[] = [];
  private $destroyed = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: HxUserService,
    private toastr: HcToastrService,
    private router: Router,
    private errorHandler: ErrorHandlerService,
    private aRoute: ActivatedRoute,
    private modal: NgbModal,
    private fileService: HxFileService,
    private storeService: HxStoreService,
    private tr: TranslocoService,
    private employeeContractService: HxEmployeeContractService
  ) {
  }

  ngOnInit() {
    this.activeNav = 'info';
    this.components.forEach(c => this.componentMap.set(c, false));
    const routeData: Data = this.aRoute.snapshot.data;
    this.userProfile = routeData['user'] as UserProfileModel;
    if (this.userProfile) {
      this.id = this.userProfile.id;
      this.isEdit = true;
    } else {
      this.isEdit = false;
      this.userProfile = {} as UserProfileModel;
    }

    this.formUserInfo = this.formBuilder.group({
      id: [null],
      phone: [null, [Validators.required]],
      firstname: [null, [Validators.required]],
      lastname: [null, [Validators.required]],
      patronymic: [null],
      email: [null],
      birthdate: [null, [Validators.required]],
      gender: [null, [Validators.required]],
      address: [null],
      maritalStatus: [null],
      iin: [null],
      position: [null],
    });

    this.aRoute.queryParamMap.pipe(takeUntil(this.$destroyed)).subscribe(async params => {
      const storeId = extractNumber('storeId', params);
      const [country, store] = await Promise.all([this.storeService.getCountryByStoreId(storeId), this.storeService.getFullStore(storeId)]);
      this.country = country;
      this.store = store;

      this.updateForm();

      this.formUserInfo.controls.phone.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(phone => {
        if (this.country && phone) {
          const prefix = this.country.phonePrefix.replace(/\D+/g, '');
          const fullPhone = prefix + phone.replace(/\D+/g, '');
          if (fullPhone.length === (prefix.length + this.country.phoneLength)) {
            if (!this.isEdit) {
              this.isLoading.user = true;
              this.userService.getUserByPhone(fullPhone).subscribe(userProfile => {
                if (userProfile) {
                  this.userProfile = userProfile;
                  this.formUserInfo.patchValue({
                    id: userProfile.id,
                    firstname: userProfile.firstname,
                    lastname: userProfile.lastname,
                    patronymic: userProfile.patronymic,
                    email: userProfile.email,
                    birthdate: userProfile.birthdate,
                    gender: userProfile.gender,
                    address: userProfile.address,
                    maritalStatus: userProfile.maritalStatus,
                    iin: userProfile.iin,
                    position: userProfile.position,
                  });
                }
                this.isLoading.user = false;
              }, () => {
                this.isLoading.user = false;
                this.userProfile = {} as UserProfileModel;
              });
            }
          } else {
            this.userProfile = {} as UserProfileModel;
          }
        }
      });
    });
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  resetUserPassword() {
    this.userService.resetPassword(this.id).then(
      () => this.toastr.success(this.tr.translate('user-edit.messageSuccess')),
      () => this.toastr.error(this.tr.translate('user-edit.messageError')));
  }

  onSubmitUserInfoForm() {
    // eslint-disable-next-line guard-for-in
    for (const i in this.formUserInfo.controls) {
      this.formUserInfo.controls[i].markAsDirty();
      this.formUserInfo.controls[i].updateValueAndValidity();
    }
    if (this.formUserInfo.valid) {
      this.formUserInfo.disable();
      const formModel = this.formUserInfo.getRawValue() as FormUserInfoModel;
      const model: UserSaveRequest = {
        id: formModel.id,
        storeId: this.store.id,
        phone: `${this.country.phonePrefix}${formModel.phone}`.replace(/\D+/g, ''),
        email: formModel.email,
        firstname: formModel.firstname,
        lastname: formModel.lastname,
        patronymic: formModel.patronymic,
        birthdate: formModel.birthdate,
        iin: formModel.iin,
        gender: formModel.gender,
        address: formModel.address,
        maritalStatus: formModel.maritalStatus,
        position: formModel.position,
      };
      this.isLoading.save = true;
      this.userService.saveUser(model).subscribe(result => {
        this.formUserInfo.markAsPristine();
        this.toastr.success(this.tr.translate('user-edit.saveData'));
        if (!this.isEdit) {
          this.router.navigate([`/users/${result.id}/edit`], {queryParams: {storeId: this.store?.id}});
        } else {
          this.isLoading.user = true;
          this.getUser(result.id);
        }
        this.formUserInfo.enable();
        this.isLoading.save = false;
      }, err => {
        this.toastr.error(err.error.message);
        this.formUserInfo.enable();
        this.isLoading.save = false;
      });
    } else {
      console.log('formErrors', getFormErrors(this.formUserInfo));
    }
  }

  onSubmitUserAccessesForm() {
    const components = [];
    for (let component of this.componentMap.keys()) {
      if (this.componentMap.get(component)) {
        components.push(component);
      }
    }

    const model: UserMembershipRequest = {
      storeId: this.store.id,
      components: components,
      employee: this.employee,
    };
    this.isLoading.membership = true;
    this.userService.saveMemberships(this.userProfile.id, model).then(() => {
      this.toastr.success(this.tr.translate('user-contract.save.success'));
      this.isLoading.membership = false;
      this.router.navigate([`/users/${this.userProfile.id}/edit`], {queryParams: {storeId: this.store?.id}});
      this.activeNav === 'access';
    }).catch(() => {
      this.isLoading.membership = false;
      this.activeNav === 'access';
    });
  }

  async onSubmitDiscount() {
    this.isLoading.discount = true;
    try {
      await this.userService.saveEmployeeDiscount(this.userProfile.id, { storeIds: this.stores.filter(r => r.checked).map(r => r.id), promoId: this.discountId });
      this.loadDiscounts();
      this.loadFranchiseStores();
      this.toastr.success(this.tr.translate('user-edit.discount.save.success'));
    } finally {
      this.isLoading.discount = false;
    }
  }

  toggleComponent(component: string) {
    const val = this.componentMap.get(component);
    this.componentMap.set(component, !val);
  }

  toggleEmployee() {
    this.employee = !this.employee;
  }

  onTabChanged() {
    if (this.activeNav === 'info') {
    } else if (this.activeNav === 'contract') {
      this.loadContract();
    } else if (this.activeNav === 'access') {
    } else if (this.activeNav === 'discount') {
      this.loadDiscounts();
      this.loadFranchiseStores();
    }
  }

  removeContractConfirmed(modal) {
    this.employeeContractService.deleteEmployeeContractById(this.selectedContract.id).then(() => {
      this.getUser(this.userProfile.id);
      modal.dismiss();
    }).catch(() => modal.dismiss());
  }

  editContract(contract: EmployeeContractFullModel) {
    this.openModal(contract);
  }

  addContract() {
    this.openModal();
  }

  removeContract(contract: EmployeeContractFullModel) {
    this.selectedContract = {...contract};
    this.modal.open(this.templateRef, { size: 'sm', centered: true });
  }

  async removeDiscount() {
    this.isLoading.removeDiscount = true;
    try {
      await this.userService.removeEmployeeDiscount(this.userProfile.id, { storeIds: this.stores.filter(r => r.checked).map(r => r.id), promoId: this.discountId });
      this.loadDiscounts();
      this.loadFranchiseStores();
      this.toastr.success(this.tr.translate('user-edit.discount.remove.success'));
    } finally {
      this.isLoading.removeDiscount = false;
    }
  }

  private updateForm() {
    this.employee = (this.userProfile.memberships ?? []).find(m => m.groupId === this.store.id)?.employee ?? true;
    const formUserInfoModel: Partial<FormUserInfoModel> = {
      id: this.userProfile.id,
      phone: this.userProfile.phone?.substr(-1 * this.country?.phoneLength ?? 0),
      firstname: this.userProfile.firstname,
      lastname: this.userProfile.lastname,
      patronymic: this.userProfile.patronymic,
      email: this.userProfile.email,
      birthdate: this.userProfile.birthdate,
      gender: this.userProfile.gender,
      address: this.userProfile.address,
      maritalStatus: this.userProfile.maritalStatus,
      iin: this.userProfile.iin,
      position: this.userProfile.position,
    };
    this.formUserInfo.patchValue(formUserInfoModel);

    (this.userProfile.memberships ?? []).find(m => m.groupId === this.store.id && m.enabled)?.components
      .forEach(m => this.componentMap.set(m, true));

    this.employee = (this.userProfile.memberships ?? []).find(m => m.groupId === this.store.id && m.enabled)?.employee;
  }

  private getUser(id: number) {
    this.userService.getUser(id).subscribe(userProfile => {
      this.userProfile = userProfile;
      this.updateForm();
      this.isLoading.user = false;
    }, () => this.isLoading.user = false);
  }

  private openModal(contract?: EmployeeContractFullModel) {
    const modalInstance = this.modal.open(UserContractComponent, {size: 'lg'});
    modalInstance.componentInstance.userId = this.userProfile.id;
    modalInstance.componentInstance.storeId = this.store.id;
    modalInstance.componentInstance.contract = contract;

    modalInstance.result
      .then(() => {
        this.loadContract();
        this.activeNav === 'contract';
      })
      .catch(err => {
        this.activeNav === 'contract';
        console.error('err', err);
      });
  }

  private loadContract() {
    this.isLoading.contract = true;
    this.employeeContractService.getEmployeeContracts({
      limit: 100,
      userId: this.userProfile.id,
      storeId: this.store.id
    }).then(result => {
      this.contracts = result.list;
      this.isLoading.contract = true;
    }).catch(() => this.isLoading.contract = false);
  }

  private async loadDiscounts() {
    this.discountId = undefined;
    this.isEmployeeDiscountExist = false;
    this.isLoading.discount = true;
    try {
      this.discounts = await this.storeService.getStoreEmployeePromos(this.store.id);
      if (this.discounts && this.discounts.length) {
        const employeeDiscount = await this.userService.getEmployeeDiscount(this.userProfile.id, this.store.id);
        this.discountId = employeeDiscount.promo?.id;
        const discountStoreIds = employeeDiscount.stores.map(r => r.id);
        this.stores.forEach(r => {
          if (discountStoreIds.includes(r.id)) {
            r.checked = true;
          }
        });
        if (employeeDiscount.promo?.id) {
          this.isEmployeeDiscountExist = true;
        }
      }
    } finally {
      this.isLoading.discount = false;
    }
  }

  private async loadFranchiseStores() {
    this.isLoading.store = true;
    try {
      this.stores = await this.storeService.getAllFranchiseStores(this.store.id);
    } finally {
      this.isLoading.store = false;
    }
  }
}
