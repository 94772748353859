import { Component, OnInit, TrackByFunction } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HxToastrService } from 'hx-component';
import { DeliveryRangeModel, extractDefaultStoreId, isoDate } from 'hx-services';
import { addMonths, format, getDaysInMonth, getISODay, setDate, setMonth, subMonths } from 'date-fns';
import { DeliveryService } from '@manager-app/service/delivery.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeliveryEditModalComponent } from '@manager-app/modal/delivery-edit-modal/delivery-edit-modal.component';
import { TranslocoService } from '@ngneat/transloco';
import { DeliveryNewComponent } from '@manager-app/pages/delivery/delivery-new/delivery-new.component';
import { ru } from 'date-fns/locale';

interface DayCell {
  date?: Date;
  isSelected?: boolean;
  reservedCount?: number;
  ranges: DeliveryRangeModel[];
}

@Component({
  selector: 'app-delivery-list.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './delivery-list.component.html',
  styleUrls: ['./delivery-list.component.css']
})
export class DeliveryListComponent implements OnInit {
  ranges: DeliveryRangeModel[] = [];
  dateRanges: DeliveryRangeModel[] = [];
  selectedMonth: number;
  selectedDate: Date = new Date();
  isLoading = {
    ranges: false,
    dateRanges: false,
  };
  currentDate: string;
  dayList: DayCell[] = [];
  storeId: number;

  constructor(
    private deliveryService: DeliveryService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private toastr: HxToastrService,
    private modal: NgbModal,
    private tr: TranslocoService
  ) {
  }

  ngOnInit() {
    this.aRoute.queryParams.subscribe(params => {
      this.selectedMonth = params['month'] ? Number(params['month']) : new Date().getMonth();
      this.storeId = extractDefaultStoreId();
      this.getList();
    });
  }

  afterCreateMonth(event: boolean) {
    if (event) {
      this.getList();
    }
  }

  loadRangesByDate(cell: DayCell): void {
    if (!cell.date || !this.storeId) {
      return;
    }
    this.dayList.filter(el => el.isSelected).forEach(el => el.isSelected = false);
    cell.isSelected = true;
    this.isLoading.dateRanges = true;
    this.dateRanges = [];
    this.deliveryService.getRangesForDate({date: isoDate(cell.date), storeId: this.storeId}).subscribe(ranges => {
      this.isLoading.dateRanges = false;
      this.dateRanges = ranges;
    }, () => {
      this.isLoading.dateRanges = false;
    });
  }

  editRange(range: DeliveryRangeModel) {
    const modalRef = this.modal.open(DeliveryEditModalComponent, {size: 'lg'});
    modalRef.componentInstance.range = Object.assign({}, range);
    modalRef.result.then(() => {
      const dayCell = this.dayList.filter(cell => cell.date).find(cell => isoDate(cell.date) === range.date);
      this.loadRangesByDate(dayCell);
    }, () => {});
  }

  removeRange(range: DeliveryRangeModel) {
    if (range.reserved > 0) {
      this.toastr.error(this.tr.translate('delivery-list.deliveryOrder'));
    }

    this.deliveryService.removeRange(range.id).subscribe(() => {
      const dayCell = this.dayList.filter(cell => cell.date).find(cell => isoDate(cell.date) === range.date);
      this.loadRangesByDate(dayCell);
    });
  }

  getMonth(value: string) {
    if (value === 'next') {
      this.selectedDate = addMonths(setMonth(this.selectedDate, this.selectedMonth), 1);
      this.selectedMonth = this.selectedDate.getMonth();
    } else {
      this.selectedDate = subMonths(setMonth(this.selectedDate, this.selectedMonth), 1);
      this.selectedMonth = this.selectedDate.getMonth();
    }
    this.navigate();
  }


  applyFilters() {
    this.getList();
  }

  trackByTime: TrackByFunction<{ time: any }> = (index, obj) => obj.time;

  trackByCell: TrackByFunction<DeliveryRangeModel> = (index, obj) => obj.id;

  deliveryNew() {
    const modalRef = this.modal.open(DeliveryNewComponent, {size: 'lg'});
    modalRef.componentInstance.storeId = this.storeId;
    modalRef.result.then(() => {
      this.afterCreateMonth(true);
    }, () => {});
  }

  private getList() {
    this.isLoading.ranges = true;
    this.ranges = [];
    this.dayList = [];
    this.dateRanges = [];
    this.currentDate = format(setMonth(this.selectedDate, this.selectedMonth), 'LLLL, yyyy', {locale: ru});
    if (this.storeId) {
      this.deliveryService.getRangesForMonth({month: this.selectedMonth + 1}, {storeId: this.storeId}).subscribe(ranges => {
        this.generateCalendar(ranges);
        this.isLoading.ranges = false;
      }, () => this.isLoading.ranges = false);
    } else {
      this.isLoading.ranges = false;
    }
  }

  private getCurrentMonthDate(): Date {
    return setMonth(this.selectedDate, this.selectedMonth);
  }

  private generateCalendar(ranges: DeliveryRangeModel[]): void {
    const date = this.getCurrentMonthDate();
    const daysInMonth = getDaysInMonth(date);

    for (let index = 1; index <= daysInMonth; index++) {
      this.dayList.push({date: setDate(date, index), ranges: []});
    }

    let days = getISODay(this.dayList[0].date) - 1;

    if (days > 0) {
      while (days !== 0) {
        this.dayList.unshift({date: undefined, ranges: []});
        days--;
      }
    }

    let count = Math.ceil(this.dayList.length / 7) * 7 - this.dayList.length;
    while (count !== 0) {
      this.dayList.push({ranges: []});
      count--;
    }

    if (ranges.length) {
      ranges.forEach(item => {
        const dayCell = this.dayList.filter(cell => cell.date).find(cell => isoDate(cell.date) === item.date);
        if (dayCell) {
          dayCell.ranges.push(item);
        }
      });
      this.dayList.forEach(dayCell => dayCell.reservedCount = dayCell.ranges.map(range => range.reserved).reduce((a, b) => a + b, 0));
    }
    this.ranges = ranges;
  }

  private navigate() {
    this.router.navigate(['./'], {
      queryParams: {
        month: this.selectedMonth
      },
      relativeTo: this.aRoute
    });
  }
}
