<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'order-stats.title' | transloco }}
        <i *ngIf="isLoading.calendarEvents" class="la la-refresh la-spin"></i>
      </h3>
    </div>
    <div>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body" *ngIf="calendarOptions">
          <div class="hx-filters">
            <div class="filter">
              <small>{{ 'revenue-report.shop' | transloco }}</small>
              <hx-store-select *ngIf="calendarOptions" [(ngModel)]="params.storeId" (selectChange)="onStoreChanged($event)"></hx-store-select>
            </div>
            <div class="filter">
              <small>{{ 'revenue-report.data' | transloco }}</small>
              <hx-date-range-select [(ngModel)]="params.date" (selectChange)="onDateChanged()"></hx-date-range-select>
            </div>
          </div>
          <br>
          <br>
          <div class="row">
            <full-calendar #calendar [options]="calendarOptions"></full-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #templateRef let-modal>
  <div class="modal-header">
    <h3 class="modal-title">{{ 'order-stats.products' | transloco }} {{ getCategoryTitle() }}</h3>

    <button class="close" type="button" (click)="modal.dismiss()">
      <span>×</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="m_datatable m-datatable m-datatable--default m-datatable--loaded" *ngIf="list.length && !isLoading.list" >
      <table class="m-datatable__table">
        <thead class="m-datatable__head">
          <tr class="m-datatable__row m-datatable__row--head">
            <th class="m-datatable__cell">
              <span>{{ 'order-stats.product' | transloco }}</span>
            </th>
            <th class="m-datatable__cell text-right">
              <span>{{ 'order-stats.plan' | transloco }}</span>
            </th>
            <th class="m-datatable__cell text-right">
              <span>{{ 'order-stats.reserve' | transloco }} <br> {{ 'order-stats.delivery' | transloco }}</span>
            </th>
            <th class="m-datatable__cell text-right">
              <span *ngIf="isToday()">{{ 'order-stats.balance' | transloco }}</span>
            </th>
          </tr>
        </thead>
        <tbody class="m-datatable__body">
          <ng-container *ngFor="let even = even;let i = index; let productItem of list">
            <ng-container *ngIf="(productItem?.reservedDelivery + productItem?.reservedPickUp) > 0  || (productItem?.balance || productItem?.limit)">
              <tr class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even }">
                <td class="m-datatable__cell">
                  <a routerLink="detail" [queryParams]="{id: productItem?.productInfo?.id, date: isoDate(selectedDate?.getTime()), storeId: params.storeId }" (click)="modal.dismiss()">
                    {{ productItem?.productInfo?.title | uiLabel }}
                  </a>
                </td>
                <td class="m-datatable__cell text-right">
                  {{ productItem?.plan }}
                </td>
                <td class="m-datatable__cell text-right">
                  {{ productItem?.reservedDelivery + productItem?.reservedPickUp }} ({{ productItem?.reservedDelivery }} + {{ productItem?.reservedPickUp }})
                </td>
                <td class="m-datatable__cell text-right">
                  <span *ngIf="isToday()">
                    {{ productItem?.balance }}
                  </span>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>

      <hx-loader [length]="list.length" [isLoading]="isLoading.list"></hx-loader>
    </div>
  </div>
</ng-template>
