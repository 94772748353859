<a class="btn btn-success m-btn m-btn--custom m-btn--icon" (click)="showModal()">
  <span>
    <i class="la la-plus"></i>
    {{ 'store-encashment.add' | transloco }}
  </span>
</a>

<ng-template #templateRef let-modal>
  <div class="modal-header">
    <h3 class="modal-title" *ngIf="!id">{{ 'store-encashment.add' | transloco }}</h3>
    <h3 class="modal-title" *ngIf="id">{{ 'store-encashment.edit' | transloco }} # {{ id }}</h3>
    <button class="close" type="button" (click)="modal.dismiss()">
      <span>×</span>
    </button>
  </div>

  <div class="modal-body" *ngIf="isLoading.form">
    <div class="m-page-loader m-page-loader--non-block m-page-loader--relative">
      <div class="m-loader m-loader--brand"></div>
    </div>
  </div>

  <div class="modal-body" *ngIf="!isLoading.form">
    <form class="m-form m-form--state m-form--label-align-right" [formGroup]="storeEncashmentForm"
          (ngSubmit)="onSubmitForm(modal)">
      <div class="m-portlet__body" *ngIf="storeEncashmentForm">
        <div class="m-form__section m-form__section--first m-form--label-align-right">
          <div class="form-group m-form__group row">
            <label class="col-form-label col-lg-3 col-sm-12 is--required">
              {{ 'store-encashment.form.accountType' | transloco }}:
            </label>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <hx-enum-select enumName="AccountType" [enumType]="AccountType" formControlName="accountType" (selectChange)="onAccountTypeChanged($event)"></hx-enum-select>
            </div>
          </div>

          <div class="form-group m-form__group row">
            <label class="col-form-label col-lg-3 col-sm-12 is--required">
              {{ 'store-encashment.form.operationType' | transloco }}:
            </label>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <hx-enum-select [isDisabled]="!storeEncashmentForm.controls.accountType.value" enumName="OperationType" [enumType]="OperationType" [exclude]="excludedOperationTypes"
                              formControlName="operationType"></hx-enum-select>
            </div>
          </div>

          <div class="form-group m-form__group row">
            <label class="col-form-label col-lg-3 col-sm-12 is--required">
              {{ 'store-encashment.form.value' | transloco }}:
            </label>

            <div class="col-lg-8 col-md-8 col-sm-12">
              <input type="number" formControlName="value" class="form-control m-input">
            </div>
          </div>

          <div class="form-group m-form__group row">
            <label class="col-form-label col-lg-3 col-sm-12">
              {{ 'store-encashment.form.collectorFullname' | transloco }}:
            </label>

            <div class="col-lg-8 col-md-8 col-sm-12">
              <input type="text" formControlName="collectorFullname" class="form-control m-input">
            </div>
          </div>

          <div class="form-group m-form__group row">
            <label class="col-form-label col-lg-3 col-sm-12">
              {{ 'store-encashment.form.note' | transloco }}:
            </label>

            <div class="col-lg-2 col-md-2 col-sm-12">
              <textarea type="text" formControlName="note" class="form-control m-input"></textarea>
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer modal-footer--pad-right">
        <button type="submit" class="btn btn-success">
          {{ 'btn.save' | transloco }}
        </button>

        <button type="button" (click)="closeModal(modal)" class="btn btn-secondary">
          {{ 'btn.cancel' | transloco }}
        </button>
      </div>
    </form>
  </div>

</ng-template>
