import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BalanceService } from '@manager-app/service/balance.service';
import { HxErrorHandlerService } from 'hx-component';
import { Subject } from 'rxjs';
import { HxAuthService, HxCategoryService, HxStoreProductService, ProductCategoryModel, ProductInfoType, StoreBasicModel, StoreProductModel } from 'hx-services';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

type tab = 'storage' | 'service' | 'materialAssets' | 'nonMaterialAssets';

@Component({
  selector: 'app-balance-view',
  templateUrl: './balance-view.component.html',
  styleUrls: ['./balance-view.component.css']
})
export class BalanceViewComponent implements OnInit, OnDestroy {
  @Input() storeId?: number;
  @Output() storeChange = new EventEmitter<number | undefined>();
  products: StoreProductModel[] = [];
  isLoading = {
    storeBalance: false,
    category: false,
  };
  categoryIds: number[] = [];
  productInfoTitle?: string;
  productInfoTypes: { id: tab, label: string, types: ProductInfoType[] }[] = [
    {id: 'storage', label: 'label.storage', types: [ProductInfoType.PRODUCT, ProductInfoType.INGREDIENT, ProductInfoType.PROPERTY]},
    {id: 'service', label: 'label.service', types: [ProductInfoType.SERVICE]},
    {id: 'materialAssets', label: 'label.materialAssets', types: [ProductInfoType.MATERIAL_ASSETS]},
    {id: 'nonMaterialAssets', label: 'label.nonMaterialAssets', types: [ProductInfoType.NON_MATERIAL_ASSETS]}
  ];
  selectedProductInfoTypes?: ProductInfoType[] = [ProductInfoType.PRODUCT, ProductInfoType.INGREDIENT, ProductInfoType.PROPERTY];
  productInfoTypeId: tab = 'storage';
  brandId?: number;
  private $destroyed = new Subject<void>();

  constructor(
    private balanceService: BalanceService,
    private categoryService: HxCategoryService,
    private storeProductService: HxStoreProductService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private auth: HxAuthService,
    private errorService: HxErrorHandlerService,
  ) {
  }

  ngOnInit() {
    this.brandId = localStorage.getItem('defaultBrandId') ? Number(localStorage.getItem('defaultBrandId')) : undefined;
    this.balanceService.updateBalanceObs.pipe(takeUntil(this.$destroyed)).subscribe(() => this.loadBalance());
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  searchProduct() {
    if (!this.productInfoTitle) {
      return;
    }
    this.loadBalance();
  }

  onStoreChanged(store?: StoreBasicModel | StoreBasicModel[]) {
    console.log('balance-view: onStoreChanged', store);
    if (Array.isArray(store)) {
      return;
    }
    this.storeId = store?.id;
    this.brandId = store?.brandId;
    this.storeChange.emit(store?.id);
    this.loadBalance();
  }

  selectProductInfoType(id: tab, types: ProductInfoType[]) {
    this.selectedProductInfoTypes = types;
    this.productInfoTypeId = id;
    this.productInfoTitle = undefined;
    if (id !== 'storage') {
      this.categoryIds = [];
    }
    this.loadBalance();
  }

  resetFilters() {
    this.categoryIds = [];
    this.productInfoTitle = undefined;
    this.loadBalance();
  }

  onProductCategoryChanged(event: ProductCategoryModel | ProductCategoryModel[] | undefined) {
    console.log('event', event);
    if (event) {
      if (Array.isArray(event)) {
        if (event.length === 0) {
          this.categoryIds = [];
        } else {
          this.categoryIds.push(event[0].id);
        }
      } else if (!Array.isArray(event)) {
        this.categoryIds.push(event.id);
      }
    }
    this.loadBalance();
  }

  private loadBalance() {
    if (!this.storeId) {
      return;
    }
    this.isLoading.storeBalance = true;
    this.products = [];
    if (this.productInfoTypeId !== 'storage' || (this.categoryIds && this.categoryIds.length)) {
      this.storeProductService.getBalance({
        storeId: this.storeId,
        categoryIds: this.categoryIds,
        productInfoTitle: this.productInfoTitle,
        productInfoTypes: this.selectedProductInfoTypes
      }).subscribe(result => {
        this.products = result;
        this.isLoading.storeBalance = false;
      }, err => {
        this.isLoading.storeBalance = false;
        this.errorService.check(err.error);
      });
    } else {
      this.isLoading.storeBalance = false;
    }
  }
}
