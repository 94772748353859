import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { extractNumber, HxRoyaltyService, isoDate, RoyaltyPaymentModel, RoyaltyStatus, toRouterQueryParams } from 'hx-services';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
type ViewType = 'standard' | 'table';

@Component({
  selector: 'app-royalty',
  templateUrl: './royalty.component.html',
  styleUrls: ['./royalty.component.css']
})
export class RoyaltyComponent implements OnInit {
  @ViewChild('templateRef') public templateRef: TemplateRef<any>;
  params: {
    year?: number,
    countryId?: number,
    cityId?: number,
  } = {year: new Date().getFullYear()};
  isLoading = {
    list: false,
    pay: false,
  };
  list: RoyaltyPaymentModel[] = [];
  selectedPay: {organizationId?: number, ownerId?: number, date?: string, royalty?: number} = {};

  yearList: number[] = [2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033,2034,2035];
  created = RoyaltyStatus.CREATED;
  paid = RoyaltyStatus.PAID;
  notLoaded = RoyaltyStatus.NOT_LOADED;
  exempt = RoyaltyStatus.EXEMPT;
  now = isoDate(new Date().setMonth(new Date().getMonth(), 1));
  value: ViewType = 'standard';
  private sub!: Subscription;

    constructor(
      private aRoute: ActivatedRoute,
      private router: Router,
      private toastr: ToastrService,
      private royaltyService: HxRoyaltyService,
      private modal: NgbModal,
      private tr: TranslocoService,
  ) {
  }

  ngOnInit() {
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.params.year = extractNumber('year', paramMap) ?? new Date().getFullYear();
      this.params.countryId = extractNumber('countryId', paramMap);
      this.params.cityId = extractNumber('cityId', paramMap);
      if (this.params.year || this.params.cityId) {
        this.load();
      }
    });
  }

  private navigateUrl() {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }

  applyFilters() {
    this.navigateUrl();
  }

  resetFilter(): void {
    this.params.cityId = null;
    this.params.countryId = null;
    this.params.year = new Date().getFullYear();
    this.navigateUrl();
  }

  async confirmPay(organizationId: number, ownerId: number, date: string, royalty: number) {
    this.selectedPay = {organizationId: organizationId, ownerId: ownerId, date: date, royalty: royalty};
    this.modal.open(this.templateRef, { size: 'sm', centered: true });
  }

  async pay(modal) {
    if (!this.selectedPay) {
      return;
    }
    this.isLoading.pay = true;
    try {
      await this.royaltyService.payRoyalty({ organizationId: this.selectedPay.organizationId, ownerId: this.selectedPay.ownerId, date: this.selectedPay.date });
      this.toastr.success(this.tr.translate('royalty.pay.success'));
      this.load();
    } finally {
      this.isLoading.pay = false;
      modal.dismiss();
    }
  }

  async generateRoyalty(organizationId: number, ownerId: number, date: string) {
      this.isLoading.list = true;
      try {
        await this.royaltyService.generateRoyalty({ organizationId: organizationId, ownerId: ownerId, date: date });
        this.toastr.success(this.tr.translate('royalty.generate.success'));
        this.load();
      } finally {
        this.isLoading.list = false;
      }
  }

  setViewType(val: ViewType) {
    this.value = val;
  }

  private async load() {
      this.isLoading.list = true;
      try {
        this.list = await this.royaltyService.getRoyalties(this.params.year, this.params.cityId);
      } finally {
        this.isLoading.list = false;
      }
  }
}
