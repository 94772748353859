<div class="modal-header">
  <h3 class="modal-title">
    {{ 'pay-invoice-modal.title' | transloco }} <strong>{{ order.uniqueNumber }}</strong>
  </h3>
  <button class="close" type="button" (click)="dismissModal()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <p>
    {{ 'pay-invoice-modal.info' | transloco }}
  </p>

  <div class="pay-invoice">
    <input [(ngModel)]="clientPhone" [mask]="'(000) 000-00-00'" [prefix]="'+7 '" class="form-control" type="tel"/>
    <button class="btn btn-outline-primary" [disabled]="isLoading.sendPayInvoice" (click)="sendPayInvoice()">
      <i *ngIf="isLoading.sendPayInvoice" class="la la-spinner la-spin"></i>
      {{ 'btn.send' | transloco }}
    </button>
  </div>
  <br/>
  <p>
    {{ 'pay-invoice-modal.text-1' | transloco }}: <br/>
    {{ 'pay-invoice-modal.text-2' | transloco }} <strong>{{order?.uniqueNumber}}</strong>
  </p>
</div>

<div class="modal-footer">
  <button type="button" [disabled]="isLoading.checkPaid" class="btn btn-success" (click)="checkPaid()">
    <i *ngIf="isLoading.checkPaid" class="la la-spinner la-spin"></i>
      {{ 'pay-invoice-modal.chooseStatus' | transloco }}
  </button>
</div>
