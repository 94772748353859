import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { HxUserService, UserBasicModel } from 'hx-services';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserSearchComponent implements OnInit, OnDestroy {
  @Output() update = new EventEmitter<any>();
  _user;
  @Input()
  set user(val) {
    if (val) {
      this.setCurrentUser(val);
    }
  }
  isLoading = false;
  items = [];
  phone = '';
  focused = false;
  selectedItem = false;
  users: UserBasicModel[] = [];
  private $typed = new Subject<string>();
  private $destroyed = new Subject<void>();

  constructor(
    private userService: HxUserService,
  ) { }

  ngOnInit(): void {
    this.$typed
      .pipe(takeUntil(this.$destroyed), debounceTime(400))
      .subscribe(item => this.getClient(this.phone));
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  private setCurrentUser(val) {
    this.phone = `${val.phone} ${val.fullname}`;
    this.selectedItem = true;
  }

  public clear() {
    setTimeout(() => {
      this.focused = false;
    }, 300);
  }

  public showOpen(): void {
    this.focused = true;
  }

  public selectPhone(item) {
    this.phone = item.text;
    this.selectedItem = true;
    this.update.emit(item);
  }

  public typed(): void {
    this.focused = true;
    if (this.phone && this.phone.length > 1) {
      this.$typed.next(this.phone);
    } else {
      this.items = [];
    }
  }

  public removed(): void {
    this.selectedItem = false;
    this.phone = '';
    this.update.emit(null);
  }

  private getClient(searchText: string) {
    this.isLoading = true;
    this.selectedItem = false;

    if (searchText) {
      this.userService.getUsers({query: searchText}).subscribe(result => {
        this.users = result.list;
        const arr = [];
        this.users.forEach(item => {
          const name = item.fullname ? '- ' + item.fullname : '';
          arr.push({
            id: item.id,
            text: `${item.phone} ${name}`
          });
        });

        this.items = arr;
        this.isLoading = false;
      });
    } else {
      this.items = [];
      this.isLoading = false;
    }
  }

}
