<div class="m-subheader ht-container ht-container--m">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <a [routerLink]="['/users']" class="m-nav__link">
        <span class="m-nav__link-text">
          <i class="la la-arrow-circle-left"></i> {{ 'user-edit.list' | transloco }}
        </span>
      </a>
      <h3 class="m-subheader__title m--margin-left-15">
        {{ 'profile.title.create' | transloco }}: {{store?.title | uiLabel}}
      </h3>
    </div>

    <div *ngIf="isEdit">
      <button *ngIf="userProfile.active" class="btn btn-primary" (click)="resetUserPassword()">
        {{ 'profile.action.reset.password' | transloco }}
      </button>
    </div>
  </div>
</div>

<div class="m-content-tab">
  <div class="row">
    <ul ngbNav [(activeId)]="activeNav" (activeIdChange)="onTabChanged()" #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
      <li [ngbNavItem]="'info'">
        <a ngbNavLink>{{ 'user.info' | transloco }}</a>
        <ng-template ngbNavContent>
          <div class="m-portlet m-portlet--full-height m--portlet--page">
            <div class="m-portlet__body">
              <form [formGroup]="formUserInfo" (ngSubmit)="onSubmitUserInfoForm()" class="m-form m-form--fit m-form--label-align-right">
                <div class="form-group m-form__group row">
                  <div class="col-lg-4">
                    <label class="is--required">{{ 'profile.form.telnumber' | transloco }}</label>

                    <ng-container *ngIf="country">
                      <input [patterns]="customPatterns" [showMaskTyped]="true" type="text" id="phone" formControlName="phone"
                             class="form-control m-input" [prefix]="country.phonePrefix" [mask]="country.phoneMask">
                      <small class="is--danger">{{ 'user-edit.phoneNum' | transloco }}</small>
                    </ng-container>
                  </div>
                  <div class="col-lg-4">
                    <label>{{ 'user-edit.iin' | transloco }}:</label>
                    <input formControlName="iin" type="text" class="form-control m-input">
                  </div>
                  <div class="col-lg-4">
                    <label class="is--required">{{ 'user-edit.date' | transloco }}:</label>
                    <hx-date-select formControlName="birthdate"></hx-date-select>
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <div class="col-lg-4">
                    <label class="is--required">{{ 'user-edit.name' | transloco }}:</label>
                    <input type="text" formControlName="firstname" class="form-control m-input">
                  </div>
                  <div class="col-lg-4">
                    <label class="is--required">{{ 'user-edit.surname' | transloco }}:</label>
                    <input type="text" formControlName="lastname" class="form-control m-input">
                  </div>
                  <div class="col-lg-4">
                    <label>{{ 'user-edit.surnames' | transloco }}:</label>
                    <input type="text" formControlName="patronymic" class="form-control m-input">
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <div class="col-lg-4">
                    <label class="is--required">{{ 'user-edit.gender' | transloco }}</label>
                    <hx-enum-select enumName="Gender" [enumType]="Gender" formControlName="gender"></hx-enum-select>
                  </div>
                  <div class="col-lg-4">
                    <label>{{ 'user-edit.status' | transloco }}:</label>
                    <hx-enum-select enumName="MaritalStatus" [enumType]="MaritalStatus" formControlName="maritalStatus"></hx-enum-select>
                  </div>
                  <div class="col-lg-4">
                    <label>e-mail:</label>
                    <input formControlName="email" type="email" class="form-control m-input">
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <div class="col-lg-12">
                    <label>{{ 'user-edit.address' | transloco }}</label>
                    <input formControlName="address" type="text" class="form-control m-input">
                  </div>
                </div>

                <div class="form-group m-form__group row">
                  <div class="col-lg-4">
                    <label>{{ 'user-edit.position' | transloco }}</label>
                    <input formControlName="position" type="text" class="form-control m-input">
                  </div>
                </div>
                <div class="alert alert-danger" *ngIf="formUserInfo.invalid">{{ 'user-edit.requiredFields' | transloco }}</div>

                <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                  <div class="m-form__actions">
                    <div class="row">
                      <div class="col-lg-8">
                        <button [disabled]="formUserInfo.invalid || formUserInfo.pristine || isLoading.save" type="submit" class="btn btn-primary">
                          {{ 'btn.save' | transloco }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div *ngIf="isLoading.user" class="m-page-loader m-page-loader--non-block m-page-loader--relative">
                <div class="m-loader m-loader--brand"></div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>

      <li [ngbNavItem]="'contract'" *ngIf="userProfile.id">
        <a ngbNavLink>{{ 'user.contracts' | transloco }}</a>
        <ng-template ngbNavContent>
          <div class="m-portlet m-portlet--full-height m--portlet--page">
            <div class="m-portlet__body">
              <div class="text-align-last">
                <button class="btn btn-outline-success" (click)="addContract()">
                  {{ 'user-edit.btn.add' | transloco }}
                </button>
              </div>

              <div *ngIf="contracts.length" class="m_datatable m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-30 m--margin-bottom-30">
                <table class="m-datatable__table">
                  <thead class="m-datatable__head">
                  <tr class="m-datatable__row m-datatable__row--head">
                    <th class="text-center m-datatable__cell" rowspan="2">{{ 'user-edit.employeeType.title' | transloco }}</th>
                    <th class="text-center m-datatable__cell" rowspan="2">{{ 'user-edit.employeeStartDate' | transloco }}</th>
                    <th class="text-center m-datatable__cell" rowspan="2">{{ 'user-edit.employeeEndDate' | transloco }}</th>
                    <th class="text-center m-datatable__cell" rowspan="2">{{ 'user-edit.accrualType.title' | transloco }}</th>
                    <th class="text-center m-datatable__cell" rowspan="2"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-container *ngFor="let even = even;let i = index; let p of contracts">
                    <tr>
                      <td class="text-center m-datatable__cell">{{ 'user-edit.employeeType.' + p.type | transloco }}</td>
                      <td class="text-center m-datatable__cell">{{ p.fromDate | date:'dd.MM.yyyy' }}</td>
                      <td class="text-center m-datatable__cell">{{ p.toDate | date:'dd.MM.yyyy' }}</td>
                      <td class="text-center m-datatable__cell">{{ 'user-edit.accrualType.' + p.accrualType | transloco }}</td>
                      <td class="text-center m-datatable__cell text-nowrap text-right" style="min-width: 102px;">
                        <div>
                          <a (click)="editContract(p)" class="btn btn-outline-primary btn-sm m-btn m-btn--icon" [ngbTooltip]="'user-contract.contractEditing' | transloco">
                            <i class="la la-edit"></i>
                          </a>
                          <a (click)="removeContract(p)" class="btn btn-outline-danger m-btn btn-sm m-btn--icon" [ngbTooltip]="'user-contract.delete' | transloco">
                            <i class="la la-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
                <hx-loader [length]="contracts.length" [isLoading]="isLoading.contract"></hx-loader>
              </div>
            </div>
          </div>
        </ng-template>
      </li>

      <li [ngbNavItem]="'access'" *ngIf="userProfile.id">
        <a ngbNavLink>{{ 'user.accesses' | transloco }}</a>
        <ng-template ngbNavContent>
          <div class="m-portlet m-portlet--full-height m--portlet--page">
            <div class="m-portlet__body">
                <div class="form-group m-form__group row">
                  <div class="col-lg-4">
                    <label class="m-checkbox m-checkbox--text" (change)="toggleEmployee()">
                      <input type="checkbox" [checked]="employee">
                      {{ 'user-edit.employee' | transloco }}
                      <span></span>
                    </label>
                  </div>
                </div>

                <div class="form-group m-form__group row">
                  <div class="col-lg-12">
                    <label>{{ 'user-edit.componentAccess' | transloco }}</label>
                    <div class="m-checkbox-list">
                      <label *ngFor="let cmp of components" class="m-checkbox">
                        <input type="checkbox" [checked]="componentMap.get(cmp)" (change)="toggleComponent(cmp)">
                        {{ 'component.' + cmp | transloco }}
                        <span></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                  <div class="m-form__actions">
                    <div class="row">
                      <div class="col-lg-8">
                        <button (click)="onSubmitUserAccessesForm()" [disabled]="isLoading.membership" type="submit" class="btn btn-primary">
                          {{ 'btn.save' | transloco }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              <div *ngIf="isLoading.user" class="m-page-loader m-page-loader--non-block m-page-loader--relative">
                <div class="m-loader m-loader--brand"></div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>

      <li [ngbNavItem]="'discount'" *ngIf="userProfile.id">
        <a ngbNavLink>{{ 'user.discount' | transloco }}</a>
        <ng-template ngbNavContent>
          <div *ngIf="discounts.length" class="m-portlet m-portlet--full-height m--portlet--page">
            <div class="m-portlet__body">
              <div class="form-group m-form__group row">
                <div class="col-lg-4">
                  <ng-select [(ngModel)]="discountId" [placeholder]="'user-edit.selectDiscount' | transloco" [clearable]="true">
                    <ng-option *ngFor="let op of discounts" [value]="op.id">{{ op.title | uiLabel }} ({{ op.value }} {{ discountTypeMap.get(op.resultType).symbol }})  {{ 'hc.amount' | transloco }} - {{ op.limit }}</ng-option>
                  </ng-select>
                </div>
              </div>

              <div class="form-group m-form__group row">
                <div class="col-lg-12">
                  <div class="m-checkbox-list">
                    <label *ngFor="let store of stores" class="m-checkbox">
                      <input type="checkbox" [(ngModel)]="store.checked" [checked]="store.checked">
                      {{ store.title | uiLabel }}
                      <span></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
                <div class="m-form__actions">
                  <div class="row">
                    <div class="col-lg-8">
                      <button (click)="onSubmitDiscount()" [disabled]="isLoading.membership" type="submit" class="btn btn-primary">
                        {{ 'btn.save' | transloco }}
                      </button>

                      <button *ngIf="isEmployeeDiscountExist" (click)="removeDiscount()" [disabled]="isLoading.removeDiscount" type="submit" class="btn btn-danger m--margin-left-10">
                        {{ 'btn.delete' | transloco }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="isLoading.discount" class="m-page-loader m-page-loader--non-block m-page-loader--relative">
                <div class="m-loader m-loader--brand"></div>
              </div>
            </div>
          </div>

          <h4 class="m--margin-top-40 text-center" *ngIf="!discounts.length">
            {{ 'user-edit.noData' | transloco }}
          </h4>
        </ng-template>
      </li>
    </ul>

  </div>
</div>

<div [ngbNavOutlet]="nav"></div>

<ng-template #templateRef let-modal>
  <div class="modal-body">
    <p>{{ 'user-edit.question.delete' | transloco }}?</p>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger m--margin-right-10" (click)="removeContractConfirmed(modal)">{{ 'user-edit.btn.yes' | transloco }}</button>

    <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">{{ 'user-edit.btn.no' | transloco }}</button>
  </div>
</ng-template>
