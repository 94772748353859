import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterProductCategory',
  pure: false
})
export class ProductCategoryPipe implements PipeTransform {
  transform(items: any[], filter: number): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter(item => item.categories.map(r => r.id).includes(filter));
  }
}
