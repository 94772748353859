import { Component, Input } from '@angular/core';
import { DataService } from '@manager-app/service/data.service';
import { AppEventModel, HxEventService } from 'hx-services';

/**
 * Component of invoice event list
 *
 * @example  <app-invoice-events [id]="someId"></app-invoice-events>
 */
@Component({
  selector: 'app-invoice-events',
  templateUrl: './invoice-events.component.html',
})
export class InvoiceEventsComponent {
  events: AppEventModel[] = [];
  statusList = DataService.statusList;
  eventList = DataService.eventList;
  @Input()
  set id(val: number) {
    if (val) {
      this.loadEvents(val);
    }
  }

  constructor(
    private eventService: HxEventService,
  ) {
  }

  private loadEvents(id: number) {
    this.eventService.getInvoiceEventList(id).subscribe(events => {
      this.events = events;
    }, err => console.error(err));
  }
}
