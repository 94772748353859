<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title title">
        {{ 'store-info.title' | transloco }}
      </h3>
    </div>
  </div>
</div>

<div class="hc-content">
  <div class="row">
    <div class="col-md-12">
      <div class="hc-content__wrap">

        <div class="store-select">
          <hx-store-select [(ngModel)]="storeId" (selectChange)="onStoreChanged($event)"></hx-store-select>
        </div>

        <div class="store-info-wrap" *ngIf="storeMoneyInfo">
          <ng-container class="m-nav__item">
              <div class="store-info store-info__item" routerLink="store-encashments" [queryParams]="{accountType: Cash, storeId: storeId}">
                <div class="store-info__title">
                  {{ 'store-info.cash' | transloco }}
                </div>
                <div class="store-info__footer">
                  <span class="store-info__numbers">
                    <span class="store-info__numb">
                      {{ storeMoneyInfo.cashBalance ?? 0 | uiCurrency : storeMoneyInfo.store.currency }}
                    </span>
                  </span>
                </div>
              </div>
          </ng-container>

          <ng-container class="m-nav__item">
              <div class="store-info store-info__item" routerLink="store-encashments" [queryParams]="{accountType: BankAccount, storeId: storeId}">
                <div class="store-info__title">
                  {{ 'store-info.bankAccount' | transloco }}
                </div>
                <div class="store-info__footer">
                  <span class="store-info__numbers">
                    <span class="store-info__numb">
                      {{ storeMoneyInfo.bankAccountBalance ?? 0 | uiCurrency : storeMoneyInfo.store.currency }}
                    </span>
                  </span>
                </div>
              </div>
          </ng-container>
        </div>

      </div>
    </div>
  </div>
</div>
