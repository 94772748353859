import { inject, NgModule } from '@angular/core';
import { PreloadAllModules, ResolveFn, RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { UserOrderComponent } from './order/user-order/user-order.component';
import { InvoiceListComponent } from './pages/invoice-list/invoice-list.component';
import { UserListComponent } from './pages/user/user-list/user-list.component';
import { DeliveryListComponent } from './pages/delivery/delivery-list/delivery-list.component';
import { OrderStatDetailComponent, OrderStatsComponent, OrderViewComponent } from './order';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProductDiscardListComponent } from '@manager-app/pages/product/discard/product-discard-list.component';
import { SettingsComponent } from '@manager-app/pages/settings/settings.component';
import { UserEditComponent } from '@manager-app/pages/user/user-edit/user-edit.component';
import { HxOrderService, HxUserService, OrderResponse, UserProfileModel } from 'hx-services';
import { WorkHistoryComponent } from '@manager-app/pages/work-history/work-history.component';
import { TimesheetListComponent } from '@manager-app/pages/timesheet/list/timesheet-list.component';
import { StoreInfoComponent } from '@manager-app/pages/store-info/store-info.component';
import { StoreEncashmentListComponent } from '@manager-app/pages/store-encashment/list/store-encashment-list.component';
import { RoyaltyComponent } from '@manager-app/pages/royalty/royalty.component';

const userResolveFn: ResolveFn<UserProfileModel> = route => inject(HxUserService)
  .getUser(Number(route.paramMap.get('id')));

const orderResolveFn: ResolveFn<OrderResponse> = route => inject(HxOrderService)
  .getFullOrderById(Number(route.paramMap.get('id')));

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        redirectTo: 'orders',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'clients',
        loadChildren: () => import('hx-component').then(m => m.HxClientListModule)
      },
      {
        path: 'order-stats',
        component: OrderStatsComponent
      },
      {
        path: 'order-stats/detail',
        component: OrderStatDetailComponent,
        pathMatch: 'full'
      },
      {
        path: 'limits',
        component: DashboardComponent
      },
      {
        path: 'orders',
        loadChildren: () => import('hx-order').then(m => m.HxOrderModule)
      },
      {
        path: 'orders/:id',
        component: OrderViewComponent,
        pathMatch: 'full',
        resolve: {
          order: orderResolveFn
        }
      },
      {
        path: 'reports/:url/full',
        component: UserOrderComponent,
        pathMatch: 'full'
      },
      {
        path: 'reports/:url/discards',
        component: ProductDiscardListComponent,
        pathMatch: 'full'
      },
      {
        path: 'invoices',
        component: InvoiceListComponent
      },
      {
        path: 'users',
        component: UserListComponent
      },
      {
        path: 'users/new',
        component: UserEditComponent
      },
      {
        path: 'users/:id/edit',
        component: UserEditComponent,
        resolve: {
          user: userResolveFn
        }
      },
      {
        path: 'delivery',
        component: DeliveryListComponent
      },
      {
        path: 'settings',
        component: SettingsComponent
      },
      {
        path: 'royalty',
        component: RoyaltyComponent
      },
      {
        path: 'faq',
        loadChildren: () => import('hx-component').then(m => m.HxFaqListModule)
      },
      {
        path: 'timesheet',
        component: TimesheetListComponent
      },
      {
        path: 'store-info',
        component: StoreInfoComponent
      },
      {
        path: 'cf/work-history',
        component: WorkHistoryComponent
      },
      {
        path: 'store-info/store-encashments',
        component: StoreEncashmentListComponent
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'storage',
        loadChildren: () => import('./storage/storage.module').then(m => m.StorageModule)
      },
      {
        path: 'rejections',
        loadChildren: () => import('hx-component').then(m => m.HxRejectionListModule)
      },
      {
        path: 'statistics',
        loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsModule)
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'orders'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {
}
