<div class="product" *ngIf="product">
  <div class="product__title">
    {{ product.productInfoTitle | uiLabel }}
  </div>

  <div class="product__footer">
    <span class="product__numbers">
      <span class="product__numb">
        {{ product.amount }}
      </span>
    </span>
  </div>
</div>
