<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
        <li class="m-nav__item">
          <a [routerLink]="['/store-info']" class="m-nav__link">
            <i class="m-nav__link-icon la la-home"></i>
            <span class="m-nav__link-text">{{ 'hc.store' | transloco }}</span>
          </a>
        </li>
      </ul>

      <h3 class="m-subheader__title">
        {{ 'store-encashment.title' | transloco }}
      </h3>
    </div>
    <div>
      <app-store-encashment-edit [id]="storeEncashmentId" [storeId]="params.storeId"
                                 (storeEncashmentSave)="onStoreEncashmentSaved($event)"></app-store-encashment-edit>
    </div>
  </div>
</div>


<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="hx-filters m--margin-bottom-10" style="align-items: center;">
            <div class="filter">
              <small>{{ 'store-encashment.filter.store' | transloco }}</small>
              <hx-store-select [(ngModel)]="params.storeId" (selectChange)="applyFilters()"></hx-store-select>
            </div>

            <div class="filter">
              <small>{{ 'store-encashment.filter.date' | transloco }}</small>
              <hx-date-select [(ngModel)]="params.date" (selectChange)="applyFilters()"></hx-date-select>
            </div>

            <div class="filter">
              <small>{{ 'store-encashment.filter.accountType' | transloco }}</small>
              <hx-enum-select enumName="AccountType" [enumType]="AccountType" [(ngModel)]="params.accountType" (selectChange)="applyFilters()"></hx-enum-select>
            </div>

            <div class="filter">
              <small>{{ 'store-encashment.filter.operationType' | transloco }}</small>
              <hx-enum-select enumName="OperationType" [enumType]="OperationType" [(ngModel)]="params.operationType" (selectChange)="applyFilters()"></hx-enum-select>
            </div>

            <div class="filter">
              <small>{{ 'store-encashment.filter.user' | transloco }}</small>
              <hx-user-select [(ngModel)]="params.userId" [disabled]="!params.storeId" [storeId]="params.storeId"
                              [preload]="true"
                              [components]="[ComponentType.manager]" (selectChange)="applyFilters()"></hx-user-select>
            </div>
          </div>


          <div class="m_datatable m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-30 m--margin-bottom-30">
            <table class="m-datatable__table" *ngIf="list.length && !isLoading.list">
              <thead class="m-datatable__head">
              <tr class="m-datatable__row m-datatable__row--head">

                <th class="m-datatable__cell">
                  <span>#</span>
                </th>

                <th class="m-datatable__cell">
                  <span>{{ 'store-encashment.table.user' | transloco }}</span>
                </th>

                <th class="m-datatable__cell">
                  <span>{{ 'store-encashment.table.store' | transloco }}</span>
                </th>

                <th class="m-datatable__cell">
                  <span>{{ 'store-encashment.table.date' | transloco }}</span>
                </th>

                <th class="m-datatable__cell">
                  <span>{{ 'store-encashment.table.account-type' | transloco }}</span>
                </th>

                <th class="m-datatable__cell">
                  <span>{{ 'store-encashment.table.operation-type' | transloco }}</span>
                </th>

                <th class="m-datatable__cell">
                  <span>{{ 'store-encashment.table.value' | transloco }}</span>
                </th>

                <th class="m-datatable__cell">
                  <span>{{ 'store-encashment.table.note' | transloco }}</span>
                </th>

                <th class="m-datatable__cell text-right">
                  <span>{{ 'store-encashment.table.events' | transloco }}</span>
                </th>
              </tr>
              </thead>
              <tbody class="m-datatable__body">
              <tr *ngFor="let even = even; let i = index; let item of list"
                  class="m-datatable__row" [ngClass]="{ 'm-datatable__row--even': even }">
                <td class="m-datatable__cell">
                  {{ item.id }}
                </td>

                <td class="m-datatable__cell">
                  {{ item.user.fullname }}
                </td>

                <td class="m-datatable__cell">
                  {{ item.store.title | uiLabel }}
                </td>

                <td class="m-datatable__cell">
                  {{ item.date | date:'dd.MM.YYYY hh:mm' }}
                </td>

                <td class="m-datatable__cell">
                  {{ 'store-encashment.account-type.' + item.accountType | transloco }}
                </td>

                <td class="m-datatable__cell">
                  {{ 'store-encashment.operation-type.' + item.operationType | transloco }}
                </td>

                <td class="m-datatable__cell">
                  {{ item.balance | number }}
                </td>

                <td class="m-datatable__cell">
                  {{ item.note }}
                </td>

                <td class="m-datatable__cell text-nowrap text-right" style="min-width: 102px;">
                  <div>
                    <a (click)="updateItem(item)" class="btn btn-outline-primary btn-sm m-btn m-btn--icon" [ngbTooltip]="">
                      <i class="la la-edit"></i>
                    </a>

                    <a (click)="cancel(item)" class="btn btn-outline-danger m-btn btn-sm m-btn--icon" [ngbTooltip]="'store-encashment.cancel' | transloco">
                      <i class="la la-times"></i>
                    </a>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <hx-loader [length]="list.length" [isLoading]="isLoading.list"></hx-loader>
          </div>

          <div class="m--align-center" *ngIf="list.length && !isLoading.list && pagination.allItemCount > 0">
            <ngb-pagination [maxSize]="5" [rotate]="true" [collectionSize]="pagination.allItemCount"
                            [(page)]="pagination.currentPage" [pageSize]="pagination.limit" (pageChange)="pageChanged($event)" class="d-flex justify-content-center mt-4">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #removeTemplateRef let-modal>
  <div class="modal-header">
    <p>{{ 'store-encashment.question.cancel' | transloco }}?</p>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-success m--margin-right-10" (click)="cancelConfirm(modal)">{{ 'btn.yes' | transloco }}</button>

    <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">{{ 'btn.no' | transloco }}</button>
  </div>
</ng-template>
