/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TimesheetType = 'ATTENDANCE' | 'VACATION' | 'ABSENCE' | 'HOLIDAY' | 'SICK' | 'BONUS';

export const TimesheetType = {
    ATTENDANCE: 'ATTENDANCE' as TimesheetType,
    VACATION: 'VACATION' as TimesheetType,
    ABSENCE: 'ABSENCE' as TimesheetType,
    HOLIDAY: 'HOLIDAY' as TimesheetType,
    SICK: 'SICK' as TimesheetType,
    BONUS: 'BONUS' as TimesheetType
};

