<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title m-subheader__title--separator">
        {{ 'order-stat-detail.title' | transloco }}
      </h3>
      <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
        <li class="m-nav__item m-nav__item--home">
          <a routerLink="/" class="m-nav__link m-nav__link--icon">
            <i class="m-nav__link-icon la la-home"></i>
          </a>
        </li>
        <li class="m-nav__separator">
          -
        </li>
        <li class="m-nav__item">
          <a routerLink="../" [queryParams]="{ date: date }" class="m-nav__link">
            <span class="m-nav__link-text">
              {{ 'order-stat-detail.orderStatistics' | transloco }}
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="m_datatable m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-30 m--margin-bottom-30" *ngIf="list.length && !isLoading.list">
            <table class="m-datatable__table">
              <thead class="m-datatable__head">
              <tr class="m-datatable__row m-datatable__row--head">
                <th *ngFor="let menuItem of menu" (click)="sortTable(menuItem)" class="m-datatable__cell m-datatable__cell--sort text-nowrap">
                    <span>
                      {{ menuItem.title | transloco}}
                      <i *ngIf="menuItem.field" class="la" [ngClass]="{'la-sort-amount-desc': menuItem.sort === 'desc', 'la-sort-amount-asc': menuItem.sort === 'asc', 'la-sort': !menuItem.sort}"></i>
                    </span>
                </th>
                <th class="m-datatable__cell">
                  <span>{{ 'order-stat-detail.table.head.status' | transloco }}</span>
                </th>
                <th class="m-datatable__cell">
                  <span>{{ 'order-stat-detail.table.head.note' | transloco }}</span>
                </th>
                <th class="m-datatable__cell">
                  <span>{{ 'order-stat-detail.table.head.sum' | transloco }}</span>
                </th>
                <th class="m-datatable__cell">
                  <span>{{ 'order-stat-detail.table.head.client' | transloco }}</span>
                </th>
              </tr>
              </thead>
              <tbody class="m-datatable__body">
              <tr *ngFor="let even = even; let i = index; let item of list"
                  class="m-datatable__row"
                  [ngClass]="{ 'm-datatable__row--even': even }">
                <td class="m-datatable__cell">
                  <a [routerLink]="'/orders/' + item.id">
                    {{ item.id }}
                  </a>
                </td>
                <td class="m-datatable__cell">
                  <a [routerLink]="'/orders/' + item.id">
                    {{ item.uniqueNumber }}
                  </a>
                </td>
                <td class="m-datatable__cell">{{ item.createDate | date:'dd.MM HH:mm' }}</td>
                <td class="m-datatable__cell">{{ item.date | date:'dd.MM HH:mm' }}</td>
                <td class="m-datatable__cell text-nowrap">
                  <span class="delivery">
                    <i class="la" [ngClass]="{'la-truck': item.deliveryRangeTime, 'la-building': !item.deliveryRangeTime}"></i>
                    <i class="la la-truck invisible" *ngIf="!item.deliveryRangeTime"></i>
                    <i class="la la-paperclip" [ngClass]="{invisible: !item.fileExists}"></i>
                    <i class="la la-birthday-cake" [ngClass]="{invisible: !item.decorExists}"></i>
                  </span>
                  <span class="m-badge  m-badge--{{ statusList[item.action]?.badge }} m-badge--wide m-badge--rounded">
                    {{ 'action.' + item.action | transloco }}
                  </span>
                </td>
                <td class="m-datatable__cell">
                  <div *ngFor="let cake of item.products">
                    <div>
                      {{ cake.title | uiLabel }} <span *ngIf="cake.priceAmount !== 1">{{cake.priceAmount}}</span>
                      —
                      <span>
                          <span *ngIf="cake.type === 'PRODUCT'">{{ cake.weight | weight }} ×</span> {{ cake.amount }} {{ 'order-stat-detail.num' | transloco }}
                        </span>
                    </div>
                  </div>
                </td>
                <td class="m-datatable__cell text-right" [ngClass]="{'is--red': item.action === 'CANCELLED'}">
                  <ng-container *ngIf="item.payment?.payments">
                    <div *ngFor="let pt of item.payment.payments">
                      <i [hxPaymentIcon]="pt.type" [ngbTooltip]="'paymentType.' + pt.type | transloco"></i>
                      {{'paymentType.' + pt.type | transloco}} {{ pt.value | uiCurrency: item.currency }}
                    </div>
                  </ng-container>

                  <span *ngIf="item.total" class="text-nowrap">
                      {{ item.total | uiCurrency: item.currency }}
                    </span>
                </td>
                <td class="m-datatable__cell">
                  {{ item.client?.phone }}<br>
                  {{ item.client?.fullname }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <hx-loader [length]="list.length" [isLoading]="isLoading.list"></hx-loader>

          <div class="m--align-center" *ngIf="list.length && !isLoading.list">
            <div class="btn-group btn-group-sm">
              <button (click)="seekPrev()" [disabled]="(!seek.hasNext && seek.dir === 'prev') || !seek.val" class="btn btn-sm btn-primary">
                <i class="las la-angle-double-left"></i>
              </button>
              <button (click)="seekNext()" [disabled]="!seek.hasNext && seek.dir === 'next'" class="btn btn-sm btn-primary">
                <i class="las la-angle-double-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
