<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'royalty.title' | transloco }}
      </h3>
    </div>
    -->
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="m-form row">
            <div class="col-md-2">
              <select [(ngModel)]="params.year" (ngModelChange)="applyFilters()" class="form-control m-input m-input--square">
                <option value="">{{ 'representatives-new.noChoose' | transloco }}</option>
                <option *ngFor="let year of yearList" [value]="year">{{ year }}</option>
              </select>
            </div>

            <div class="col-md-2">
              <hx-country-select [(ngModel)]="params.countryId" [all]="false" (ngModelChange)="applyFilters()"></hx-country-select>
            </div>

            <div class="col-md-2">
              <hx-city-select [(ngModel)]="params.cityId" [countryId]="params.countryId" [all]="false" (ngModelChange)="applyFilters()"></hx-city-select>
            </div>

            <div class="col-md-2">
              <div class="btn-group btn-group-sm">
                <button type="button" class="btn" (click)="setViewType('standard')" [ngClass]="value === 'standard' ? 'btn-metal': 'btn-light'">
                  {{ 'royalty.report.view.standard' | transloco }}
                </button>
                <button type="button" class="btn" (click)="setViewType('table')" [ngClass]="value === 'table' ? 'btn-metal': 'btn-light'">
                  {{ 'royalty.report.view.table' | transloco }}
                </button>
              </div>
            </div>

            <div class="col-md-2">
              <button type="button" (click)="resetFilter()" class="btn btn-outline-primary">
                {{ 'reset.list' | transloco }}
              </button>
            </div>
          </div>

          <div class="table-responsive m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-20 m--margin-bottom-20">
            <table class="m-datatable__table" *ngIf="value === 'standard'">
              <thead class="m-datatable__head">
              <tr class="m-datatable__row m-datatable__row--head">
                <th class="m-datatable__cell">#</th>
                <th class="m-datatable__cell">{{ 'royalty.table.organization' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.owner' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.january' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.february' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.march' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.april' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.may' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.june' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.july' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.august' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.september' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.october' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.november' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.december' | transloco }}</th>
              </tr>
              </thead>
              <tbody class="m-datatable__body">
              <tr *ngFor="let i = index; let rt of list">
                <td class="m-datatable__cell">{{ i + 1 }}</td>
                <td class="m-datatable__cell font-size-10">{{ rt.organization.title | uiLabel }}</td>
                <td class="m-datatable__cell font-size-10"><span [ngbTooltip]="rt.owner.fullname">{{ rt.owner.fullname | fio }}</span></td>
                <td class="m-datatable__cell" *ngFor="let dt of rt.details" [ngClass]="{'create': dt.status === created, 'paid': dt.status === paid, 'not-loaded': dt.status === notLoaded, 'exempt': dt.status === exempt}">
                  <div class="font-size-10" *ngIf="dt.status === created || dt.status === paid">
                    <div><span class="font-weight-bold text-wd">{{ 'royalty.revenue' | transloco }}:</span><div>{{ dt.revenue | moneyFormat }}</div></div>
                    <div><span class="font-weight-bold text-wd">{{ 'royalty.orderCount' | transloco }}:</span><div>{{ dt.orderCount ?? '-' }}</div></div>
                    <div><span class="font-weight-bold text-wd">{{ 'royalty.percentage' | transloco }}:</span><div>{{ dt.percentage | moneyFormat }}%</div></div>
                    <div><span class="font-weight-bold text-wd">{{ 'royalty.royalty' | transloco }}:</span><div>{{ dt.value | moneyFormat }}</div></div>
                  </div>
                  <span *ngIf="dt.status === created">
                    <button type="button" (click)="confirmPay(rt.organization.id, rt.owner.id, dt.date, dt.value)" class="btn-pay">
                      {{ 'royalty.pay' | transloco }}
                    </button>
                  </span>
                  <span *ngIf="dt.status === paid">
                    <button type="button" class="btn-pay btn-opacity">
                      {{ 'royalty.paid' | transloco }}
                    </button>
                  </span>
                  <span *ngIf="dt.status === notLoaded">
                    <a class="not-loaded-td" *ngIf="dt.date < now" (click)="generateRoyalty(rt.organization.id, rt.owner.id, dt.date)" [ngbTooltip]="'royalty.load' | transloco"><i class="la la-sync"></i></a>
                  </span>
                  <span *ngIf="dt.status === exempt">
                    <span class="font-size-10">{{ 'royalty.exempt' | transloco }}</span>
                  </span>
                </td>
              </tr>
              </tbody>
            </table>

            <table class="m-datatable__table" *ngIf="value === 'table'">
              <thead class="m-datatable__head">
              <tr class="m-datatable__row m-datatable__row--head">
                <th class="m-datatable__cell">#</th>
                <th class="m-datatable__cell">{{ 'royalty.table.organization' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.owner' | transloco }}</th>
                <th class="m-datatable__cell"></th>
                <th class="m-datatable__cell">{{ 'royalty.table.january' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.february' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.march' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.april' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.may' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.june' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.july' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.august' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.september' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.october' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.november' | transloco }}</th>
                <th class="m-datatable__cell">{{ 'royalty.table.december' | transloco }}</th>
              </tr>
              </thead>
              <tbody class="m-datatable__body">
              <ng-container  *ngFor="let i = index; let rt of list">
                <tr>
                  <td class="m-datatable__cell" rowspan="4">{{ i + 1 }}</td>
                  <td class="m-datatable__cell font-size-10" rowspan="4">{{ rt.organization.title | uiLabel }}</td>
                  <td class="m-datatable__cell font-size-10" rowspan="4"><span [ngbTooltip]="rt.owner.fullname">{{ rt.owner.fullname | fio }}</span></td>
                  <td class="m-datatable__cell font-size-10" >{{ 'royalty.revenue' | transloco }}</td>
                  <td class="m-datatable__cell font-size-10" [ngClass]="{'tb-bg': even}" *ngFor="let even = even; let dt of rt.details">
                    {{ dt.revenue | moneyFormat }}
                  </td>
                </tr>
                <tr>
                  <td class="m-datatable__cell font-size-10" >{{ 'royalty.orderCount' | transloco }}</td>
                  <td class="m-datatable__cell font-size-10" [ngClass]="{'tb-bg': even}" *ngFor="let even = even; let dt of rt.details">
                    {{ dt.orderCount ?? 0 }}
                  </td>
                </tr>
                <tr>
                  <td class="m-datatable__cell font-size-10" >{{ 'royalty.percentage' | transloco }} %</td>
                  <td class="m-datatable__cell font-size-10" [ngClass]="{'tb-bg': even}" *ngFor="let even = even; let dt of rt.details">
                    {{ dt.percentage | moneyFormat }}
                  </td>
                </tr>
                <tr>
                  <td class="m-datatable__cell font-size-10" >{{ 'royalty.royalty' | transloco }}</td>
                  <td class="m-datatable__cell font-size-10" [ngClass]="{'tb-bg': even}" *ngFor="let even = even; let dt of rt.details">
                    {{ dt.value | moneyFormat }}
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #templateRef let-modal>
  <div class="modal-body">
    <p>{{ 'royalty.question.pay' | transloco }} {{ selectedPay?.royalty }}?</p>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-success m--margin-right-10" [disabled]="isLoading.pay" (click)="pay(modal)">{{ 'royalty.btn.yes' | transloco }}</button>

    <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">{{ 'royalty.btn.no' | transloco }}</button>
  </div>
</ng-template>
