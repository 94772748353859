<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'delivery-list.title' | transloco }}
      </h3>
    </div>
    <div>
      <a class="btn btn-success m-btn m-btn--custom m-btn--icon" (click)="deliveryNew()" [title]="'delivery-new.courierSchedule' | transloco">
        <span>
          {{ 'delivery-new.title' | transloco }}
        </span>
      </a>
    </div>
  </div>
</div>
<!-- END: Subheader -->
<div class="m-content">
  <!--Begin::Main Portlet-->
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="fc fc-unthemed fc-ltr">
            <div class="fc-toolbar fc-header-toolbar">
              <div class="fc-left">
                <div class="fc-button-group">
                  <button type="button" (click)="getMonth('prev')"
                          class="fc-prev-button fc-button fc-state-default fc-corner-left">
                    <
                  </button>
                  <button type="button" (click)="getMonth('next')"
                          class="fc-next-button fc-button fc-state-default fc-corner-right">
                    >
                  </button>
                </div>
              </div>
              <div>
                <hx-store-select class="m--margin-left-15 m--margin-top-2" [(ngModel)]="storeId"
                                 (selectChange)="applyFilters()"></hx-store-select>
              </div>
              <div class="fc-right">
                <div class="fc-button-group invisible">
                  <button type="button"
                          class="fc-month-button fc-button fc-state-default fc-corner-left fc-state-active">{{ 'delivery-list.btn.month' | transloco }}
                  </button>
                  <button type="button" class="fc-listMonth-button fc-button fc-state-default fc-corner-right">{{ 'delivery-list.btn.list' | transloco }}
                  </button>
                </div>
              </div>
              <div class="fc-center">
                <h2>{{ currentDate }}</h2>
              </div>
              <div class="fc-clear"></div>
            </div>
          </div>

          <div id="calendar" class="calendar">
            <div class="day-names">
              <div>{{ 'daysWeek.Mon' | transloco }}</div>
              <div>{{ 'daysWeek.Tue' | transloco }}</div>
              <div>{{ 'daysWeek.Wed' | transloco }}</div>
              <div>{{ 'daysWeek.Thu' | transloco }}</div>
              <div>{{ 'daysWeek.Fri' | transloco }}</div>
              <div>{{ 'daysWeek.Sat' | transloco }}</div>
              <div>{{ 'daysWeek.Sun' | transloco }}</div>
            </div>
            <div class="days">
              <ng-container *ngFor="let day of dayList; trackBy: trackByTime">
                <div class="day is--cursor-pointer" (click)="loadRangesByDate(day)"
                     [ngClass]="{'day--active': day.isSelected}">
                  <span class="day__date">
                    {{ day.date | date: 'dd' }}
                  </span>
                  <span *ngIf="day.ranges.length > 0" [ngbTooltip]="'delivery-list.totalSegments' | transloco" class="hc-couriers__numb hc-couriers__numb--left_top m--font-warning">
                    {{ day.ranges.length }}
                  </span>
                  <span *ngIf="day.reservedCount > 0" [ngbTooltip]="'delivery-list.numOrder' | transloco" class="hc-couriers__numb hc-couriers__numb--right_top m--font-success">
                    {{day.reservedCount}}
                  </span>
                </div>
              </ng-container>
            </div>
          </div>

          <div *ngIf="isLoading.ranges" class="m-page-loader m-page-loader--non-block m-page-loader--relative">
            <div class="m-loader m-loader--brand"></div>
          </div>

          <h4 class="m--margin-top-40 text-center" *ngIf="!ranges.length && !isLoading.ranges && storeId">
            {{ 'delivery-list.noData' | transloco }}
          </h4>

          <h4 class="m--margin-top-40 text-center" *ngIf="!ranges.length && !isLoading.ranges && !storeId">
            {{ 'delivery-list.noShop' | transloco }}
          </h4>

          <div class="m--margin-top-30">
            <div *ngIf="dateRanges.length && !isLoading.dateRanges">
              <div *ngFor="let range of dateRanges; trackBy: trackByCell">
                <span>{{ range.fromTime | time }} - {{ range.toTime | time}}</span>

                <span style="margin-left: 1rem;">{{ 'delivery-list.limit' | transloco }} <b>{{range.limit}}</b> / {{ 'delivery-list.numberOrders' | transloco }}: <b>{{range.reserved}}</b></span>
                <a style="margin-left: 1rem;cursor: pointer" (click)="editRange(range)">{{ 'delivery-list.change' | transloco }}</a>
                <a style="margin-left: 1rem;cursor: pointer" (click)="removeRange(range)">{{ 'delivery-list.delete' | transloco }}</a>
              </div>
            </div>

            <div *ngIf="!dateRanges.length && isLoading.dateRanges"
                 class="m-page-loader m-page-loader--non-block m-page-loader--relative">
              <div class="m-loader m-loader--brand"></div>
            </div>

            <h4 class="m--margin-top-40 text-center" *ngIf="!dateRanges.length && !isLoading.dateRanges && storeId">
              {{ 'delivery-list.noDelivery' | transloco }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End::Main Portlet-->
</div>
