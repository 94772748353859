<div class="m-form">
  <div class="m-form__section m-form__section--first">
    <div class="form-group m-form__group m--margin-top-0">
        <div [ngClass]="{'is--loading': isLoading}">
          <div class="ui-select-container dropdown" [ngClass]="{'open': phone.length > 1 && focused}" tabindex="0">

            <input
              autocomplete="false"
              class="form-control ui-select-search"
              (keyup)="typed()"
              [(ngModel)]="phone"
              tabindex="-1"
              (focusin)="showOpen()"
              (focusout)="clear()"
              type="text"
              [placeholder]="'user-search.searchUser' | transloco">

            <a (click)="removed()" *ngIf="!isLoading && phone.length > 1" class="is--remove">
              <i class="la la-remove"></i>
            </a>

            <ul class="ui-select-choices dropdown-menu" role="menu" style="width: 100%;" *ngIf="!isLoading && !selectedItem">
              <ng-container *ngIf="items.length">
                <li role="menuitem" *ngFor="let item of items">
                  <div class="ui-select-choices-row">
                    <a class="dropdown-item" (click)="selectPhone(item)">
                      <div >{{ item.text }}</div>
                    </a>
                  </div>
                </li>
              </ng-container>
              <!-- not found or empty -->
              <ng-container *ngIf="!items.length">
                <li role="menuitem">
                  <div class="ui-select-choices-row">
                    <a class="dropdown-item">
                      <div>{{ 'client.search.not.found' | transloco }}</div>
                    </a>
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
    </div>
  </div>
</div>
