<ng-select [items]="options"
           bindValue="id"
           [placeholder]="'store-product-select.nameProduct' | transloco"
           [typeToSearchText]="'store-product-select.search' | transloco"
           [notFoundText]="'store-product-select.noFind' | transloco"
           [loadingText]="'store-product-select.loaderSearch' | transloco"
           [clearable]="true"
           [disabled]="disabled"
           [multiple]="multiple"
           [hideSelected]="true"
           [trackByFn]="trackByFn"
           [loading]="isLoading"
           [typeahead]="itemInput$"
           (change)="onSelectChanged($event)"
           [(ngModel)]="selected">
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <span class="ng-value-label">{{item.title | uiLabel}}</span>
    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
  </ng-template>
  <ng-template ng-option-tmp ng-label-tmp let-item="item">
    {{item.title | uiLabel}} <small class="pull-right">{{item.model.amount}} {{item.model.unitOfMeasure.localShortTitle | uiLabel}}</small>
  </ng-template>
</ng-select>
