import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { getBrowserLang, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomHttpInterceptor } from '@manager-app/service/http.interceptor';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HxComponentModule } from 'hx-component';
import { HX_KEYCLOAK, HxAuthService, HxPipeModule, initKeycloak } from 'hx-services';
import { HxOrderModule } from 'hx-order';
import { KeycloakService } from 'keycloak-angular';
import { ComponentModule } from '@manager-app/components/component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HxComponentModule,
    TranslocoModule,
    HxPipeModule,
    HxOrderModule,
    NgbModule,
    ComponentModule,
  ],
  declarations: [],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslocoModule,
    NgSelectModule,
    NgbModule,
    HxPipeModule,
    HxOrderModule,
    HxComponentModule,
    ComponentModule,
  ],
  providers: [
    ErrorHandlerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService, HxAuthService, HX_KEYCLOAK, TranslocoService],
    },
    { provide: LOCALE_ID, useValue: 'ru_KZ' },
  ]
})
export class SharedModule {
}
