import { Component, OnInit } from '@angular/core';
import {
  DictionaryEntryModel,
  DictionaryKey,
  extractDefaultStoreId,
  HxDictionaryService,
  HxStoreService,
  isoDate,
  StoreBasicModel,
  StoreSettings,
  UserBasicModel
} from 'hx-services';
import { ToastrService } from 'ngx-toastr';
import { getISODay, isAfter, isBefore, set } from 'date-fns';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  settings: StoreSettings;
  isBalanceSell = false;
  isLoading = {
    save: false,
  };
  storeId?: number;
  referrers: DictionaryEntryModel[] = [];

  constructor(
    private storeService: HxStoreService,
    private toastr: ToastrService,
    private dictionary: HxDictionaryService,
    private tr: TranslocoService
  ) {
  }

  async ngOnInit() {
    const {list, count} = await this.dictionary.getEntries({key: DictionaryKey.utmReferrer, limit: 1000});
    this.referrers = list;
    this.storeId = extractDefaultStoreId();
    await this.loadSettings(this.storeId);
  }

  saveSettings() {
    this.isLoading.save = true;
    this.storeService.saveSettings(this.storeId, this.settings).subscribe(() => {
      this.toastr.success(this.tr.translate('settings.settingsSave'));
      this.isLoading.save = false;
    }, () => this.isLoading.save = false);
  }

  onUserChanged(user: UserBasicModel | undefined) {
    this.settings.signUserId = user?.id;
  }

  onBalanceSellChanged() {
    if (this.isBalanceSell) {
      this.settings.switchDate = isoDate(new Date());
    }
  }

  private async loadSettings(storeId: number) {
    const [storeInfo, settings] = await Promise.all([this.storeService.getFullStore(storeId), this.storeService.getSettings(storeId)]);
    this.settings = settings;
    const now = new Date();
    const workTime = storeInfo.workDates.find(wd => wd.dayOfWeek === getISODay(now));
    const [openHours, openMinutes] = workTime.openTime.split(':').map(s => Number(s));
    const [closeHours, closeMinutes] = workTime.closeTime.split(':').map(s => Number(s));
    const openDateTime = set(now, {hours: openHours, minutes: openMinutes});
    const closeDateTime = set(now, {hours: closeHours, minutes: closeMinutes});
    if (isBefore(now, closeDateTime) && (isAfter(now, openDateTime) || isoDate(now) === isoDate(this.settings.switchDate))) {
      this.isBalanceSell = true;
    }
  }

  onStoreChanged($event?: StoreBasicModel | StoreBasicModel[]) {
    if (this.storeId) {
      this.loadSettings(this.storeId);
    } else {
      this.settings = undefined;
    }
  }
}
