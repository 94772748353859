import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@manager-app/service/data.service';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';
import { DeliveryOrderResponse, GetOrdersParams, HxReportService, PaymentType, SortType, toRouterQueryParams } from 'hx-services';
import { Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

interface ColumnItem {
  title: string;
  sort?: SortType;
  field: string;
}

/**
 * User's list of orderd by params
 */
@Component({
  selector: 'app-user-order.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './user-order.component.html',
  styleUrls: ['./user-order.component.css']
})
export class UserOrderComponent implements OnInit, OnDestroy {
  list: DeliveryOrderResponse[] = [];
  pagination = {
    currentPage: 1,
    allItemCount: 0,
    limit: 20,
    maxPage: 1
  };
  isLoading = {
    list: true
  };
  sortable = true;
  menu: ColumnItem[] = [
    {
      title: 'id',
      field: 'id'
    },
    {
      title: 'user-order.ts.num',
      field: 'unique_number'
    },
    {
      title: 'user-order.ts.dataCreate',
      field: 'create_date'
    },
    {
      title: 'user-order.ts.dateDelivery',
      field: 'date'
    }
  ];
  statusList = DataService.statusList;
  params: Partial<GetOrdersParams> = {};
  private sub: Subscription;

  constructor(
    private reportService: HxReportService,
    private errorService: ErrorHandlerService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private tr: TranslocoService
  ) { }

  ngOnInit() {
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      const asArrFn = (name: string, mapFn: (key: string) => any) => paramMap.get(name)?.split(',').map(mapFn) ?? [];
      const asNumberFn = (name: string) => paramMap.get(name) ? Number(paramMap.get(name)) : undefined;
      const asBooleanFn = (name: string) => paramMap.get(name) ? paramMap.get(name) === 'true' : undefined;
      this.params.sale = asNumberFn('sale');
      this.params.employee = asNumberFn('employee');
      this.params.date = {from: paramMap.get('dateFrom'), to: paramMap.get('dateTo')};
      this.params.limit = asNumberFn('limit');
      this.params.page = asNumberFn('page') ?? 1;
      this.params.orderBy = paramMap.get('orderBy') ?? 'id';
      this.params.sort = (paramMap.get('sort') as SortType) ?? SortType.desc;
      this.params.paymentType = paramMap.get('paymentType') as PaymentType;
      this.params.debt = asBooleanFn('debt');
      this.params.discount = asBooleanFn('discount');
      this.params.storeIds = asArrFn('storeIds', str => Number(str));
      if (this.params.orderBy) {
        this.menu.forEach(el => {
          if (el.field === this.params.orderBy) {
            el.sort = this.params.sort;
          }
        });
      }
      this.loadList();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  pageChanged(event: number) {
    this.params.page = event;
    this.navigate();
  }

  async loadList() {
    this.isLoading.list = true;
    this.list = [];
    try {
      const order = await this.reportService.getOrderReport(this.params as GetOrdersParams);
      this.list = order.list;
      this.pagination.allItemCount = order.count;
    } catch (err: any) {
      this.errorService.check(err.error);
    } finally {
      this.isLoading.list = false;
    }
  }


  sortTable(item: ColumnItem): void {
    this.params.orderBy = item.field;
    if (item.sort) {
      this.params.sort = item.sort === SortType.desc ? SortType.asc : SortType.desc;
    } else {
      this.params.sort = SortType.desc;
    }
    this.menu.forEach(el => {
      if (el.field !== item.field) {
        el.sort = undefined;
      }
    });
    item.sort = this.params.sort;
    this.pageChanged(1);
  }

  private navigate() {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }
}
