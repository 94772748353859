import { Injectable } from '@angular/core';
import { HcToastrService } from '@manager-app/service/toastr.service';
import { CommonError, ErrorMessage, ErrorOrder, ErrorResponse } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ErrorHandlerService {

  constructor(
    private toastr: HcToastrService,
    private translate: TranslocoService,
    private tr: TranslocoService
  ) { }

  /**
   * OLD API handler
   * Error code:
   * 105 -
   * 106 - validation error
   * 111 - transformation error
   * 112 - balance error
   * 113 -
   * 115, 116 - limit error
   * 117 - balance booked error
   */
  check(error: CommonError, from?: string) {
    if (error && error.code) {
      switch (error.code) {
        case 105:
        case 113:
        case 115:
          this.toastr.warning(`${from}.${error.message}`);
          break;
        case 106:
          this.showToastr(error.uiMessages, from);
          break;
        case 112:
        case 117:
          if (error.data) {
            this.showToastrEmptyProduct(error.data.order);
          }
          break;
        case 116:
          this.showToastrEmptyProduct(error.data.order, 'limit');
          break;
        case 403:
          this.toastr.error(error.message);
          break;
        default:
          this.toastr.error(error.message);
          break;
      }
    }
  }

  /**
   * new Api handler error
   * PAYLOAD_TOO_LARGE(413)
   * UNPROCESSABLE_ENTITY(422)
   * UNEXPECTED(577)
   * VALIDATION(576)
   * NOT_FOUND(575)
   * FORBIDDEN(574)
   * BUSINESS(567)
   * EXTERNAL(578)
   */
  handler(error: ErrorResponse) {
    switch (error.message) {
      case 'requiredFields':
        this.showToastrField(error.data.fields);
        break;
      case 'cb.store.balanceEmpty':
        this.showToastrEmptyProduct(error.data);
        break;
      case 'limitValidation.error':
        this.showToastrLimitValidation(error.data.limits);
        break;
      default:
        this.toastr.error(error.message, error.data);
        break;
    }
  }

  private showToastrField(msg: any[]) {
    for (let index = 0; index < msg.length; index++) {
      const element = msg[index];
      const data = { value: this.getTrans('hc.' + element.field) };
      this.toastr.error(element.message, data);
    }
  }

  private getTrans(code: string, data?) {
    return this.translate.translate(code, data);
  }

  private showToastr(msg: ErrorMessage[], from: string) {
    if (!msg.length) {
      return;
    }

    msg.forEach(el => {
      const prefix = from ? `${from}.` : '';
      this.toastr.error(`${prefix}${el.field}.${el.message}`);
    });
  }

  private showToastrEmptyProduct(msg: ErrorOrder, status?: string) {
    if (msg.product && msg.product.length) {
      let list = '';

      msg.product.forEach((element, index) => {
        list += index > 0 ? `, ${element}` : `${element}`;
      });

      let message = this.tr.translate('error-handler.balance', {msg: msg.store, list: list});

      if (status && status === 'limit') {
        message = this.tr.translate('error-handler.limit', {list: list});
      }

      this.toastr.info(message);
    }
  }

  private showToastrLimitValidation(limits: {limit: number, productTitle: string, reserve: number}[]) {
    for (let index = 0; index < limits.length; index++) {
      const element = limits[index];
      const msg = this.tr.translate('error-handler.text', {productTitle: element.productTitle, limit: element.limit, reserve: element.reserve});
      this.toastr.errorWithTimeout(msg, null, 10000);
    }
  }

}
