<div class="modal-header">
  <h3 class="modal-title">{{ 'delivery-new.createSchedule' | transloco }}</h3>
  <button class="close" type="button" (click)="dismiss()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <div class="m-form m-form--state">
    <div class="m-portlet__body">
      <div class="m-form__section m-form__section--first">
        <div class="row">
          <div class="col-md-4">
            <label class="col-form-label is--required">
              {{ 'delivery-new.shop' | transloco }}:
            </label>
            <hx-store-select [(ngModel)]="storeId"></hx-store-select>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label class="col-form-label is--required">
              {{ 'delivery-new.date' | transloco }}:
            </label>
            <hx-date-range-select [(ngModel)]="dateRange"></hx-date-range-select>
          </div>
          <div class="col-md-8">
            <label class="col-form-label is--required">
              {{ 'delivery-new.days' | transloco }}:
            </label>
            <div>
              <div class="btn-group btn-group-sm btn-group-toggle day-checkbox">
                <label *ngFor="let d of weekDays" [ngClass]="{'btn-outline-primary': days[d] === false, 'btn-primary': days[d] === true}" ngbButtonLabel>
                  <i class="la" [ngClass]="{'la-check-circle' : days[d] === true, 'la-circle': days[d] === false}"></i>
                  <input type="checkbox" ngbButton [(ngModel)]="days[d]"> {{'weekDay.short.' + d | transloco}}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngFor="let i = index; let range of ranges;">
          <div class="col-md-4">
            <label class="col-form-label is--required">
              {{ 'delivery-new.time' | transloco }}:
            </label>

            <div>
              <input type="text" [(ngModel)]="range.fromTime" class="time-range-input form-control m-input" placeholder="09:00" [dropSpecialCharacters]="false" [mask]="'00:00'">
              <input type="text" [(ngModel)]="range.toTime" class="time-range-input form-control m-input" placeholder="20:00" [dropSpecialCharacters]="false" [mask]="'00:00'">
            </div>
          </div>

          <div class="col-md-4">
            <label class="col-form-label is--required">
              {{ 'delivery-new.limit' | transloco }}:
            </label>

            <div>
              <input type="text" [(ngModel)]="range.limit" class="time-range-input form-control m-input">
            </div>
          </div>

          <button class="btn btn-outline-danger btn-sm m--margin-top-40" (click)="deleteRange(i)">{{ 'delivery-new.btn.delete' | transloco }}</button>
        </div>

        <button class="btn btn-outline-primary btn-sm m--margin-top-10" (click)="addNewRange()">{{ 'delivery-new.btn.add' | transloco }}</button>
      </div>
    </div>
    <div class="modal-footer modal-footer--pad-right">
      <button [disabled]="isLoading.save" class="btn btn-success" (click)="save()">
        {{ 'btn.save' | transloco }}
      </button>

      <button type="button" (click)="dismiss()" class="btn btn-secondary">
        {{ 'btn.cancel' | transloco }}
      </button>
    </div>
    <hx-loader [isLoading]="isLoading.save" [length]="ranges.length"></hx-loader>
  </div>
</div>
