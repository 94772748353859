import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { VanillaService } from '@manager-app/service/vanilla.service';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';
import { HcToastrService } from '@manager-app/service/toastr.service';
import { DatePropertyModel, DateRange, extractDefaultStoreId, HxDatePropertyService, InscriptionRequest, isoDate, StoreBasicModel, HxStoreService } from 'hx-services';
import { addMonths, format, getDaysInMonth, getISODay, parseISO, setDate, subMonths } from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreWorkTimeModal } from '@manager-app/modal/store-work-time/store-work-time.modal';

interface DayCell {
  date: string;
  info?: Partial<DatePropertyModel>;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild('templateDialog') templateDialog: TemplateRef<any>;
  list = [];
  dayList: DayCell[] = [];
  storeId?: number;
  date: DateRange = {
    from: isoDate(Date.now()),
    to: isoDate(Date.now()),
  };
  productId: number;
  isLoading = {
    calendar: false,
    decor: false,
    onlineSellable: false,
    inscription: false,
  };
  currentDate?: string;
  currentStore?: StoreBasicModel;

  constructor(
    private vanillaService: VanillaService,
    private errorService: ErrorHandlerService,
    private datePropertyService: HxDatePropertyService,
    private toastr: HcToastrService,
    private modalService: NgbModal,
    private storeService: HxStoreService,
  ) {
  }

  async ngOnInit() {
    this.storeId = extractDefaultStoreId();
    await this.loadCurrentStore();
    this.loadCalendar();
  }

  loadMonth(direction: string) {
    if (direction === 'next') {
      this.date.from = isoDate(addMonths(parseISO(this.date.from), 1));
    } else {
      this.date.from = isoDate(subMonths(parseISO(this.date.from), 1));
    }
    this.loadCalendar();
  }

  editProducts(item) {
    this.productId = item.info.id;
  }

  updateDecor(item) {
    if (!item.info) {
      return;
    }
    this.isLoading.decor = true;

    const decored = {
      enabled: !item.info.data.decor.enabled,
    };

    this.vanillaService.updateDecor(item.info.id, decored).subscribe(() => {
      item.info.data.decor.enabled = !item.info.data.decor.enabled;
      this.isLoading.decor = false;
      this.toastr.success(`decorCalendar.update.success`);
    }, err => {
      this.isLoading.decor = false;
      this.errorService.handler(err.error);
    });
  }

  async updateInscription(item) {
    if (!item.info) {
      return;
    }
    this.isLoading.inscription = true;

    const request: InscriptionRequest = {
      enabled: !item.info.data.inscription.enabled
    };

    try {
      await this.datePropertyService.updateInscription(item.info.id, request);
      item.info.data.inscription.enabled = !item.info.data.inscription.enabled;
      if (!item.info.data.inscription.enabled) {
        item.info.data.inscription.from = null;
        item.info.data.inscription.to = null;
      }
      this.toastr.success(`decorCalendar.update.success`);
    } catch (err: any) {
      this.errorService.handler(err.error);
    } finally {
      this.isLoading.inscription = false;
    }
  }

  updateOnlineSellable(item) {
    if (!item.info) {
      return;
    }
    this.isLoading.onlineSellable = true;
    const onlineSellable = {
      enabled: !item.info.data.onlineSellable.enabled,
    };

    this.vanillaService.updateOnlineSellable(item.info.id, onlineSellable).subscribe(() => {
      item.info.data.onlineSellable.enabled = !item.info.data.onlineSellable.enabled;
      this.isLoading.onlineSellable = false;
      this.toastr.success(`onlineSellableCalendar.update.success`);
    }, err => {
      this.isLoading.onlineSellable = false;
      this.errorService.handler(err.error);
    });
  }

  updateData(val: boolean) {
    if (val) {
      this.loadCalendar();
    }
    this.productId = undefined;
  }

  onStoreChanged(store?: StoreBasicModel | StoreBasicModel[]) {
    if (store && !Array.isArray(store)) {
      this.currentStore = store;
    } else {
      this.currentStore = undefined;
    }
    if (this.currentStore) {
      //this.loadMonth();
      this.loadCalendar();
    }
  }

  editStoreWorkDate(id: number, date: string) {
    const modalRef = this.modalService.open(StoreWorkTimeModal, {size: 'lg'});
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.storeId = this.storeId;
    modalRef.componentInstance.date = date;
    modalRef.result.then(() => {
      this.loadCalendar();
    });
  }

  private loadCalendar() {
    this.list = [];
    this.dayList = [];
    this.isLoading.calendar = true;
    this.currentDate = format(parseISO(this.date.from), 'LLLL');

    this.vanillaService.getProperties({
      month: true,
      date: this.date,
      storeId: this.storeId,
    }).subscribe(result => {
      if (result.length) {
        this.generateCalendar(result);
      }
      this.isLoading.calendar = false;
    }, err => {
      this.isLoading.calendar = false;
      this.errorService.check(err.error);
    });
  }

  private generateCalendar(list: DatePropertyModel[]) {
    this.dayList = [];
    const daysInMonth = getDaysInMonth(parseISO(this.date.from));
    if (list.length) {
      const date = this.date.from;
      for (let index = 1; index <= daysInMonth; index++) {
        this.dayList.push({date: isoDate(setDate(parseISO(date), index))});
      }
      this.dayList.forEach(item => {
        item.info = list.find(el => el.date === item.date) || {};
      });

      let days = getISODay(parseISO(this.dayList[0].date)) - 1;
      if (days > 0) {
        while (days !== 0) {
          this.dayList.unshift({date: undefined});
          days--;
        }
      }
    }

    if (this.dayList.length) {
      let count = Math.ceil(this.dayList.length / 7) * 7 - this.dayList.length;
      if (count > 0) {
        while (count !== 0) {
          this.dayList.push({date: undefined});
          count--;
        }
      }
    }

    this.list = list;
  }

  private async loadCurrentStore() {
    this.currentStore = await this.storeService.getStoreById(this.storeId);
  }
}
