import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '@manager-app/service/data.service';
import { extractNumber, HxOrderService, isoDate, OrderAction, OrderRowModel, SeekDirection, SortType, toRouterQueryParams } from 'hx-services';
import { Subscription } from 'rxjs';

/**
 * Component list of orders by productinfo
 *
 * @export
 */
interface MenuItem {
  title: string;
  field?: 'id' | 'number' | 'date';
  sort?: SortType;
}

@Component({
  selector: 'app-order-stat-detail.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './order-stat-detail.component.html',
  styleUrls: ['./order-stat-detail.component.css']
})
export class OrderStatDetailComponent implements OnInit, OnDestroy {
  list: OrderRowModel[] = [];
  isLoading = {
    list: true
  };
  date?: string;
  sort: SortType = SortType.desc;
  menu: MenuItem[] = [
    {
      title: 'id',
      sort: SortType.desc,
      field: 'id',
    },
    {
      title: 'order-stat-detail.ts.num',
      field: 'number',
    },
    {
      title: 'order-stat-detail.ts.dataCreate',
    },
    {
      title: 'order-stat-detail.ts.dateDelivery',
      field: 'date',
    }
  ];
  statusList = DataService.statusList;
  productActions: OrderAction[] = [OrderAction.CREATED, OrderAction.DECORED, OrderAction.PACKED, OrderAction.TAKEN, OrderAction.RECEIVED, OrderAction.SHIPPED];
  seek: {
    last?: string;
    first?: string;
    val?: string;
    hasNext?: boolean;
    dir?: SeekDirection;
  } = {};

  private orderBy: 'id' | 'number' | 'date' = 'id';
  private storeId?: number;
  private id?: number;
  private sub: Subscription;

  constructor(
    private orderService: HxOrderService,
    private aRoute: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.id = extractNumber('id', paramMap);
      this.storeId = extractNumber('storeId', paramMap);
      this.date = paramMap.get('date') ?? isoDate(Date.now());
      this.seek = {
        dir: paramMap.get('seekDir') as SeekDirection ?? SeekDirection.next,
        val: paramMap.get('seek') ?? undefined,
      };
      this.loadList();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  sortTable(item: MenuItem): void {
    if (!item.field) {
      return;
    }
    this.orderBy = item.field;
    if (item.sort) {
      this.sort = item.sort === SortType.desc ? SortType.asc : SortType.desc;
    } else {
      this.sort = SortType.desc;
      this.menu.forEach(el => {
        if (el.field !== item.field) {
          el.sort = undefined;
        }
      });
    }
    this.list = [];
    item.sort = this.sort;
    this.navigateUrl();
  }

  seekPrev() {
    this.navigateUrl({seek: this.seek.first, seekDir: SeekDirection.prev});
  }

  seekNext() {
    this.navigateUrl({seek: this.seek.last, seekDir: SeekDirection.next});
  }

  private async loadList() {
    if (!this.storeId) {
      return;
    }
    this.isLoading.list = true;
    this.list = [];
    try {
      const {list, first, last, hasNext} = await this.orderService.getOrderList({
        seek: this.seek.val,
        seekDir: this.seek.dir,
        date: this.date,
        sort: this.sort,
        storeIds: [this.storeId],
        orderBy: this.orderBy,
        actions: this.productActions,
        productInfoIds: [this.id],
      });
      this.seek.first = first;
      this.seek.last = last;
      this.seek.hasNext = hasNext;
      this.list = list;
    } finally {
      this.isLoading.list = false;
    }
  }

  private navigateUrl(seek: { seek?: string, seekDir?: SeekDirection } = {}) {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams({
        seek: seek.seek,
        seekDir: seek.seekDir,
        id: this.id,
        date: this.date,
        sort: this.sort,
        orderBy: this.orderBy,
        storeId: this.storeId,
      }),
      relativeTo: this.aRoute
    });
  }
}

