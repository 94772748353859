import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HxToastrService } from 'hx-component';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HxStoreProductService, ProductInfoModel, ProductInfoType, StoreProductModel, UiLabel } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';

interface Option {
  id: number;
  title: UiLabel;
  model: StoreProductModel;
}

@Component({
  selector: 'app-store-product-select',
  templateUrl: './store-product-select.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => StoreProductSelectComponent),
    multi: true
  }]
})
export class StoreProductSelectComponent implements OnInit, OnDestroy {
  @Input() onlyView = false;
  @Input() isPurchasable = false;
  @Input() storeId?: number;
  @Input() multiple = false;
  @Input() productInfoTypes: ProductInfoType[] = [];
  @Input() validateItemFn: (params: { productInfoId: number }) => boolean;
  @Output() selectChange = new EventEmitter<StoreProductModel | StoreProductModel[] | undefined>();

  isLoading = false;
  options: Option[] = [];
  itemInput$ = new Subject<string>();
  disabled = false;
  private $destroyed = new Subject<void>();
  private $selected?: number | number[];

  get selected(): number | number[] | undefined {
    return this.$selected;
  }

  set selected(val: number | number[] | undefined) {
    this.$selected = val;
    if (val) {
      let ids: number[];
      if (Array.isArray(val)) {
        ids = val;
      } else {
        ids = [val];
      }
      const optionIds = this.options.map(el => el.id);
      const load = ids.some(id => !optionIds.includes(id));
      if (load) {
        this.storeProductService.getStoreProductList({ids: ids})
          .subscribe(pagedList => {
            this.options = pagedList.list.map(el => ({id: el.id, title: el.productInfo.title, model: el}));
          });
      }
    }
    this.onChange(val);
  }

  constructor(
    private storeProductService: HxStoreProductService,
    private toastr: HxToastrService,
    private tr: TranslocoService,
  ) {
  }

  ngOnInit(): void {
    this.loadItems();
  }

  writeValue(val: number | number[] | undefined): void {
    this.selected = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange(val: any) {

  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  trackByFn(item: ProductInfoModel) {
    return item.id;
  }

  private loadItems() {
    this.itemInput$.pipe(
      takeUntil(this.$destroyed),
      debounceTime(500),
      distinctUntilChanged(),
      filter(term => {
        const isOk = (term ?? '').trim() !== '';
        this.isLoading = isOk;
        return isOk;
      }),
      switchMap(term => this.storeProductService.getStoreProductList({
        limit: 200,
        query: term,
        purchasable: this.isPurchasable,
        types: this.productInfoTypes,
        storeId: this.storeId,
      }).pipe(
        // map(pagedResult => pagedResult.list.filter(pd => !(this.viewModel ?? []).includes(pd.id))),
        map(pagedResult => pagedResult.list.map(el => ({id: el.id, title: el.productInfo.title, model: el}))),
        catchError(() => of([])), // empty list on error
        tap(() => this.isLoading = false)
      ))
    ).subscribe(options => this.options = options);
  }

  onSelectChanged(event?: Option | Option[]) {
    if (event) {
      if (Array.isArray(event)) {
        this.selectChange.emit(event.map(op => op.model));
        this.onChange(event.map(op => op.id));
      } else {
        this.selectChange.emit(event.model);
        this.onChange(event.id);
      }
    } else {
      this.onChange(undefined);
      this.selectChange.emit(undefined);
    }
  }
}
