import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { DeliveryRangeModel, toHttpParams } from 'hx-services';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getRangesForMonth(opts: { month: number }, params: { storeId: number }): Observable<DeliveryRangeModel[]> {
    return this.httpClient.get<DeliveryRangeModel[]>(`/api/vanilla/delivery/ranges/month/${opts.month}`, {params: toHttpParams(params, true)});
  }

  getRangesForDate(opts: { date: string, storeId?: number }): Observable<DeliveryRangeModel[]> {
    let qp = new HttpParams();
    if (opts.storeId) {
      qp = qp.append('store_id', opts.storeId + '');
    }
    return this.httpClient.get<DeliveryRangeModel[]>(`/api/vanilla/delivery/ranges/date/${opts.date}`, {params: qp});
  }

  generateRanges(params: { ranges: DeliveryRangeModel[]; from: string; to: string, weekDays: WeekDaySelection, storeId: number }): Promise<void> {
    return firstValueFrom(this.httpClient.post<void>(`/api/vanilla/delivery/ranges/generate`, params));
  }

  removeRange(id: number): Observable<void> {
    return this.httpClient.delete<void>(`/api/vanilla/delivery/ranges/${id}`);
  }

  saveDeliveryRange(range: DeliveryRangeModel): Promise<void> {
    return firstValueFrom(this.httpClient.post<void>(`/api/vanilla/delivery/ranges`, range));
  }
}

export interface WeekDaySelection {
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
}
