<div class="is--responsive-table">
  <table class="hc-table">
    <thead class="hc-table__head">
    <tr class="hc-table__row hc-table__row--head">
      <td class="hc-table__cell">{{ 'invoice-events.table.head.date' | transloco }}</td>
      <td class="hc-table__cell">{{ 'invoice-events.table.head.author' | transloco }}</td>
      <td class="hc-table__cell">{{ 'invoice-events.table.head.note' | transloco }}</td>
      <td class="hc-table__cell">{{ 'invoice-events.table.head.status' | transloco }}</td>
    </tr>
    </thead>

    <tbody class="hc-table__body" *ngFor="let event of events">
    <tr class="hc-table__tow">
      <td class="hc-table__cell">
        <hc-icon icon="clock"></hc-icon>

        <span>{{event.date | date: 'dd.MM HH:mm' }}</span>
      </td>

      <td class="hc-table__cell">
        <hc-icon icon="user"></hc-icon>

        <span [title]="event.actorFullName + ' - ' + event.actor">{{ event.actorFullName | fio }}</span>
      </td>

      <td class="hc-table__cell">
        {{ event.info }}
      </td>

      <td class="hc-table__cell">
          <span class="m-badge m-badge--{{eventList[event.type]?.badge }} m-badge--wide m-badge--rounded">
            {{ 'invoice.type.' + event.type | transloco }}
          </span>
      </td>
    </tr>
    </tbody>
  </table>
</div>
