<div class="modal-header">
  <h3 class="modal-title">
    {{ 'work-time-modal.title' | transloco }} <strong>{{ date | date: 'dd.MM.yyyy' }}</strong>
  </h3>
  <button class="close" type="button" (click)="dismissModal()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <div class="m--margin-top-20">
    <label class="is--required">
      {{ 'work-time-modal.openTime' | transloco }}:
    </label>

    <input type="text" [(ngModel)]="openTime" class="time-range-input form-control m-input" [placeholder]="'delivery-edit-modal.timeOpen' | transloco"
           [dropSpecialCharacters]="false" [mask]="'00:00'">
  </div>

  <div class="m--margin-top-20">
    <label class="is--required">
      {{ 'work-time-modal.closeTime' | transloco }}:
    </label>

    <input type="text" [(ngModel)]="closeTime" class="time-range-input form-control m-input" [placeholder]="'delivery-edit-modal.timeOpen' | transloco"
           [dropSpecialCharacters]="false" [mask]="'00:00'">
  </div>

  <div>
    <label for="closed" class="hc-checkbox">
      <input [(ngModel)]="closed" type="checkbox" name="closed" id="closed">
      <span>{{ 'dashboard.store.closed' | transloco }}</span>
    </label>
  </div>
</div>

<div class="modal-footer">
  <button type="button" [disabled]="isLoading.save" class="btn btn-success" (click)="save()">
    <i *ngIf="isLoading.save" class="la la-spinner la-spin"></i>
    {{ 'btn.save' | transloco }}
  </button>

  <button type="button" class="btn btn-secondary" (click)="dismissModal()">
    {{ 'btn.cancel' | transloco }}
  </button>
</div>
