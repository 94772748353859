<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="row">
            <div class="col-md-3">
              {{ params.date?.from | date: 'dd.MM.yyyy' }} - {{ params.date?.to | date: 'dd.MM.yyyy' }}
            </div>
          </div>

            <div class="m_datatable m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-30 m--margin-bottom-30" *ngIf="list.length && !isLoading.list">
              <table class="m-datatable__table">
                <thead class="m-datatable__head">
                <tr class="m-datatable__row m-datatable__row--head">
                  <th class="m-datatable__cell">
                    <span>{{ 'product-discard-list.name' | transloco }}</span>
                  </th>
                  <th class="m-datatable__cell text-right">
                    <span>{{ 'product-discard-list.quantity' | transloco }}</span>
                  </th>
                  <th class="m-datatable__cell text-right">
                    <span>{{ 'product-discard-list.price' | transloco }}</span>
                  </th>
                  <th class="m-datatable__cell text-right">
                    <span>{{ 'product-discard-list.sum' | transloco }}</span>
                  </th>
                </tr>
                </thead>
                <tbody class="m-datatable__body">
                <tr *ngFor="let even = even; let i = index; let item of list" class="m-datatable__row"
                    [ngClass]="{ 'm-datatable__row--even': even }">
                  <td class="m-datatable__cell text-nowrap text-left" style="min-width: 100px; width: 15%;">
                    {{ item?.productTitle | uiLabel }}
                  </td>
                  <td class="m-datatable__cell text-nowrap text-right" style="min-width: 100px; width: 15%;">
                    {{ item?.amount }}
                  </td>
                  <td class="m-datatable__cell text-nowrap text-right" style="min-width: 100px; width: 15%;">
                    {{ item?.totalCostPrice }}
                  </td>
                  <td class="m-datatable__cell text-nowrap text-right" style="min-width: 100px; width: 15%;">
                    {{ item?.totalPrice }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          <hx-loader [length]="list.length" [isLoading]="isLoading.list"></hx-loader>
        </div>
      </div>
    </div>
  </div>
</div>
