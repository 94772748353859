import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { StoreProductModel } from 'hx-services';

@Injectable({
  providedIn: 'root'
})
export class InvoiceBalanceService {
  private balanceSubject = new ReplaySubject<Map<number, number>>(1);

  readonly balanceObs = this.balanceSubject.asObservable();
  private discardMap = new Map<number, number>(); // key = productInfoId, value = discardAmount
  private discardProducts: StoreProductModel[] = [];

  updateDiscardAmount(product: StoreProductModel, discardAmount: number) {
    const key = product.productInfo.id;
    if (!this.discardMap.has(key)) {
      this.discardProducts.push(product);
    }
    this.discardMap.set(key, discardAmount);
    this.balanceSubject.next(this.discardMap);
  }

  getDiscardProducts(): StoreProductModel[] {
    return this.discardProducts;
  }

  getDiscardAmount(productInfoId: number): number {
    return this.discardMap.get(productInfoId) || 0;
  }

  emptyProducts() {
    this.discardProducts = [];
    this.discardMap.clear();
    this.balanceSubject.next(this.discardMap);
  }
}
