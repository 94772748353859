import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeliveryRangeModel } from 'hx-services';
import { DeliveryService } from '@manager-app/service/delivery.service';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';

@Component({
  selector: 'app-delivery-edit-modal',
  templateUrl: './delivery-edit-modal.component.html',
  styleUrls: ['./delivery-edit-modal.component.css']
})
export class DeliveryEditModalComponent {

  @Input() range: DeliveryRangeModel;
  isLoading = {
    save: false
  };

  constructor(
    public modal: NgbActiveModal,
    private deliveryService: DeliveryService,
    private errorHandlerService: ErrorHandlerService,
  ) {
  }

  async save() {
    if (this.isLoading.save) {
      console.warn('calm down, dude, stop clicking');
      return;
    }
    this.isLoading.save = true;
    try {
      await this.deliveryService.saveDeliveryRange(this.range);
      this.modal.close();
    } catch (err: any) {
      this.errorHandlerService.handler(err.error);
    } finally {
      this.isLoading.save = false;
    }
  }
}
