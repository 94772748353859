import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HxOrderService, OrderResponse, PaymentType } from 'hx-services';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-pay-invoice-modal',
  templateUrl: './pay-invoice.modal.html',
  styleUrls: ['./pay-invoice.modal.css']
})
export class PayInvoiceModal implements OnInit {

  @Input() order!: OrderResponse;
  clientPhone?: string;
  isLoading = {
    sendPayInvoice: false,
    checkPaid: false
  };

  constructor(
    private modalInstance: NgbActiveModal,
    private orderService: HxOrderService,
    private toastr: ToastrService,
    private tr: TranslocoService
  ) {
  }

  ngOnInit(): void {
    this.clientPhone = this.order.phone?.substr(1);
  }

  dismissModal() {
    this.modalInstance.dismiss();
  }

  async sendPayInvoice() {
    this.isLoading.sendPayInvoice = true;
    const phone = '7' + this.clientPhone;
    try {
      const {total} = await this.orderService.sendPayInvoice(this.order.id, {paymentType: PaymentType.KASPI, phone: phone});
      this.toastr.success(this.tr.translate('pay-invoice-modal.ts.invoicePayment', {phone: phone, total: total}));
      if (!this.order.phone) {
        this.orderService.orderUpdated();
      }
    } finally {
      this.isLoading.sendPayInvoice = false;
    }
  }

  checkPaid() {
    this.isLoading.checkPaid = true;
    this.orderService.checkPaymentStatus({orderId: this.order.id, type: PaymentType.KASPI})
      .pipe(finalize(() => this.isLoading.checkPaid = false))
      .subscribe(result => {
        if (result.paid) {
          this.toastr.success(this.tr.translate('pay-invoice-modal.ts.orderPaid'));
        } else {
          this.toastr.info(this.tr.translate('pay-invoice-modal.ts.orderNoPaid'));
        }
      }, err => console.error(err));
  }
}
