import { Injectable } from '@angular/core';
import { RoyaltyPaymentModel, RoyaltyRequest, RoyaltyResourceService } from '../vn-api';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HxRoyaltyService {

  constructor(
    private royaltyResourceService: RoyaltyResourceService
  ) {
  }

  getRoyalties(year: number, cityId?: number): Promise<RoyaltyPaymentModel[]> {
    return firstValueFrom(this.royaltyResourceService.getRoyalties(cityId, year));
  }

  generateRoyalty(request: RoyaltyRequest): Promise<void> {
    return firstValueFrom(this.royaltyResourceService.generateRoyalty(request));
  }

  payRoyalty(request: RoyaltyRequest): Promise<void> {
    return firstValueFrom(this.royaltyResourceService.payRoyalty(request));
  }
}
