<div class="modal-header">
  <h3 class="modal-title">{{ user.fullname | fio }} - {{ timesheet.date | date:'dd.MM.yyyy' }} </h3>
  <button class="close" type="button" (click)="dismiss()">
    <span>×</span>
  </button>
</div>

<div class="m-content m-content--page-fixed">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height m--portlet--page">
        <div class="m-portlet__body">
          <div class="form-group m-form__group row" *ngIf="timesheetType !== Bonus">
            <div class="col-lg-12">
              <label class="is--required">{{ 'timesheet-list.table.type' | transloco }}</label>
              <hx-enum-select enumName="TimesheetType" [exclude]="[TimesheetType.BONUS]" [enumType]="TimesheetType" [(ngModel)]="type" (selectChange)="onTypeChanged()"></hx-enum-select>
            </div>
          </div>

          <div class="form-group m-form__group row" *ngIf="isTimeDisplay && timesheetType !== Bonus">
            <div class="col-lg-8">
              <label>{{ 'timesheet-list.table.entranceTime' | transloco }}</label>
              <input type="text" [(ngModel)]="entranceTime" class="time-range-input form-control m-input" placeholder="09:00" [dropSpecialCharacters]="false" [mask]="'00:00'">
            </div>
          </div>

          <div class="form-group m-form__group row" *ngIf="isTimeDisplay && timesheetType !== Bonus">
            <div class="col-lg-8">
              <label>{{ 'timesheet-list.table.exitTime' | transloco }}</label>
              <input type="text" [(ngModel)]="exitTime" class="time-range-input form-control m-input" placeholder="20:00" [dropSpecialCharacters]="false" [mask]="'00:00'">
            </div>
          </div>

          <div class="form-group m-form__group row" *ngIf="timesheetType === Bonus">
            <div class="col-lg-8">
              <label class="is--required">{{ 'timesheet-list.table.bonus' | transloco }}</label>
              <input type="number" [(ngModel)]="value" (keydown)="numericOnly($event)" class="form-control m-input">
            </div>
          </div>

          <div class="m-portlet__foot m-portlet__no-border m-portlet__foot--fit">
            <div class="m-form__actions m-form__actions--solid">
              <div class="row">
                <div class="col-lg-12 text-center">
                  <button [disabled]="isLoading.save" (click)="save()" type="submit" class="btn btn-primary">
                    {{ 'btn.save' | transloco }}
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
