import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { Notification, NotificationType } from 'hx-services';

@Injectable({
  providedIn: 'root'
})
export class HcNotificationService {
  private readonly address = 'notifications';

  private eventListSubject = new ReplaySubject<any>(1);
  private eventCountSubject = new ReplaySubject<any>(1);
  private eventAlertSubject = new ReplaySubject<any>(1);

  readonly eventListObs = this.eventListSubject.asObservable();
  readonly eventCountObs = this.eventCountSubject.asObservable();
  readonly eventAlertObs = this.eventAlertSubject.asObservable();
  private closed = false;
  private phone: string;

  constructor(
    private keycloakService: KeycloakService,
  ) {}

  start(phone: string) {
    this.phone = phone;

    if (localStorage.getItem('enableNotifier') !== 'false') {
      this.keycloakService.getToken().then(token => {
        this.startEventbus(token);
      });
    } else {
      console.warn('[notifier event bus] localStorage.enableNotifier is "false"');
    }
  }

  startEventbus(token: string) {
    /*this.eb = new EventBus(`/api/notifier/eventbus`);
    this.eb.enableReconnect(true);
    const _self = this;

    this.eb.onopen = () => {
      this.eb.registerHandler(`ws-user-${this.phone}`, (error, message) => {
        if (error) {
          console.error('error: ', error);
        } else {
          _self.getAllMessages();
        }
      });

      _self.getAllMessages();
    };

    this.eb.close = () => {
      this.closed = true;
      console.warn('connection to eb is closed');
    };*/
  }

  getAllMessages(): void {
    if (closed) {
      throw new Error('Proxy is closed');
    }

    this.getMessageCount();

    /*this.eb.send(this.address, {}, { username: this.phone, action: 'getMessages' }, (err, res) => {
      if (err) {
        this.eventListSubject.thrownError(err);
      } else {
        if (res.body && res.body.list) {
          this.filterAlert(res.body.list);
        }

        this.eventListSubject.next(res.body);
      }
    });*/
  }

  markAsRead(messageIds: Array<number>) {
    if (closed) {
      throw new Error('Proxy is closed');
    }

    /*this.eb.send(this.address, { ids: messageIds }, { username: this.phone, action: 'markAsRead' }, (err, result) => {
      if (err) {
        console.error(err);
      } else {
        this.getAllMessages();
      }
    });*/
  }

  getMessageCount() {
    /*this.eb.send(this.address, {}, { username: this.phone, action: 'getMessageCount' }, (err, result) => {
      if (err) {
        this.eventCountSubject.thrownError(err);
      } else {
        this.eventCountSubject.next(result.body);
      }
    });*/
  }

  markAsSeen(messageIds: Array<number>) {
    /*if (this.closed) {
      throw new Error('Proxy is closed');
    }

    this.eb.send(this.address, { ids: messageIds }, { username: this.phone, action: 'markAsSeen' }, (err, result) => {
      if (err) {
        console.error(err);
      } else {
        this.getAllMessages();
      }
    });*/
  }

  filterAlert(arr: Array<Notification>) {
    const list = arr.filter(el => el.type === NotificationType.ALERT && !el.readDate);

    if (list.length) {
      this.eventAlertSubject.next(list);
    }
  }
}

