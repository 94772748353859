import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateRange, HxProductService, isoDate, ProductDiscardInfoModel } from 'hx-services';
import { HxErrorHandlerService } from 'hx-component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-product-discard-list.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './product-discard-list.component.html',
  styleUrls: ['./product-discard-list.component.css']
})
export class ProductDiscardListComponent implements OnInit, OnDestroy {
  list: ProductDiscardInfoModel[] = [];
  isLoading = {
    list: true
  };
  params: {date?: DateRange, userId?: number, storeId?: number, productInfoId?: number} = {};
  private sub: Subscription;

  constructor(
    private productService: HxProductService,
    private errorHandlerService: HxErrorHandlerService,
    private aRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.params.storeId = paramMap.has('storeId') ? Number(paramMap.get('storeId')) : undefined;
      this.params.userId = paramMap.has('userId') ? Number(paramMap.get('userId')) : undefined;
      this.params.productInfoId = paramMap.has('productInfoId') ? Number(paramMap.get('productInfoId')) : undefined;
      this.params.date = {from: paramMap.get('dateFrom') ?? isoDate(new Date()), to: paramMap.get('dateTo') ?? isoDate(new Date())};
      this.loadList();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  goBack(): void {
    this.router.navigateByUrl('/reports');
  }

  private loadList() {
    this.isLoading.list = true;
    this.list = [];

    this.productService.getProductDiscards(this.params).subscribe(
      result => {
        this.list = result;
        this.isLoading.list = false;
      }, err => {
        this.errorHandlerService.check(err);
        this.isLoading.list = false;
      }
    );
  }
}
