import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountType, extractDefaultStoreId, extractNumber, HxStoreService, StoreBasicModel, StoreMoneyInfoModel } from 'hx-services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { HxToastrService } from 'hx-component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-store-info',
  templateUrl: './store-info.component.html',
  styleUrls: ['./store-info.component.css']
})
export class StoreInfoComponent implements OnInit, OnDestroy {
  storeId: number;
  storeMoneyInfo: StoreMoneyInfoModel;
  isLoading = {
    model: false,
  };
  Cash = AccountType.CASH;
  BankAccount = AccountType.BANK_ACCOUNT;
  private sub: Subscription;

  constructor(
    private storeService: HxStoreService,
    private modalService: NgbModal,
    private aRoute: ActivatedRoute,
    private toastr: HxToastrService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.storeId = extractNumber('storeId', paramMap) ?? extractDefaultStoreId();
      if (this.storeId) {
        this.loadMoneyInfo();
      }
    });
    this.loadMoneyInfo();
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onStoreChanged(store?: StoreBasicModel | StoreBasicModel[]) {
    this.storeId = !Array.isArray(store) ? store?.id : undefined;
    this.navigate();
  }

  navigate() {
    this.router.navigate(['./'], {
      queryParams: {
        storeId: this.storeId
      },
      relativeTo: this.aRoute
    });
  }

  private loadMoneyInfo() {
    this.isLoading.model = true;
    this.storeService.getStoreMoneyInfo(this.storeId).then(result => {
      this.isLoading.model = true;
      this.storeMoneyInfo = result;
    }).catch(() => this.isLoading.model = true);
  }
}
