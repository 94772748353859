<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'timesheet-list.title' | transloco }}
      </h3>
    </div>
    <div>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="hx-filters">
            <div class="filter">
              <small>{{ 'timesheet-list.store' | transloco }}</small>
              <hx-store-select [(ngModel)]="params.storeId" (selectChange)="onStoreChanged($event)"></hx-store-select>
            </div>

            <div class="filter">
              <small>{{ 'timesheet-list.month.title' | transloco }}</small>
              <ng-select [(ngModel)]="params.month" (ngModelChange)="onModelChanged($event)">
                <ng-option *ngFor="let m of months" [value]="m">{{ 'timesheet-list.month.' + m | transloco}}</ng-option>
              </ng-select>
            </div>
          </div>
          <br>
          <div class="block-table-scroll">
            <table *ngIf="list.length && !isLoading.list">
              <thead>
              <tr class="fixed-head-oline">
                <th class="text-center fixed-head-one" rowspan="2">{{ 'timesheet-list.table.fio' | transloco }}</th>
                <th *ngFor="let d of dateinfos" class="text-center">
                  {{ d.date | date: 'dd.MM.yy' }}
                </th>
                <th class="text-center fixed-head-one" rowspan="2">{{ 'timesheet-list.table.bonus' | transloco }}</th>
              </tr>
              <tr class="fixed-head-tline text-center">
                <ng-container *ngFor="let d of dateinfos">
                  <th>{{ 'timesheet-list.table.dayOfWeek.' + d.dayOfWeek | transloco }}</th>
                </ng-container>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let even = even;let i = index; let p of list">
                <tr>
                  <td class="tbody-fixed-one">{{ p.user.fullname }}</td>
                  <ng-container *ngFor="let timesheet of p.timesheets">
                    <td class="is--cursor-pointer" (click)="openModal(timesheet, p.user)" [ngClass]="{'bg-green': timesheet.type && timesheet.type === Attendance, 'bg-red': timesheet.type && timesheet.type !== Attendance}">
                      <div *ngIf="timesheet.type && timesheet.type === Attendance" class="text-size--10">{{ timesheet.entranceTime | time }} <span *ngIf="timesheet.entranceTime && timesheet.exitTime"> - </span> {{ timesheet.exitTime | time }}</div>
                      <div *ngIf="timesheet.type && timesheet.type !== Attendance" class="text-size--10">{{ 'timesheet-list.timesheetType.' + timesheet.type | transloco }}</div>
                    </td>
                  </ng-container>
                  <td class="is--cursor-pointer" (click)="openBonusModal(p.bonus, p.user)">
                    {{ p.bonus?.value }}
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
          <hx-loader [length]="list.length" [isLoading]="isLoading.list"></hx-loader>
          <br/>
          <br/>

        </div>
      </div>
    </div>
  </div>
</div>
