<a (click)="showModal()" class="btn btn-outline-danger m-btn m-btn--icon" [title]="(order?.paid ? 'cancel-order.returnOrder' : 'cancel-order.cancelOrder') | transloco">
  <span>
    <i class="la la-remove"></i>
    <span *ngIf="order?.paid">{{ 'cancel-order.return' | transloco }}</span>
    <span *ngIf="!order?.paid">{{ 'cancel-order.cancel' | transloco }}</span>
  </span>
</a>

<ng-template #cancelOrder let-modal>
  <div class="modal-header">
    <h3 class="modal-title">{{ 'cancel-order.cancelOrder' | transloco }}

        </h3>
    <button class="close" type="button" (click)="modal.dismiss()">
      <span>×</span>
    </button>
  </div>

  <div class="modal-body">
    <label>
      {{ 'cancel-order.cause' | transloco }}:
    </label>
    <textarea class="form-control m-input" [(ngModel)]="cancelReason"></textarea>

    <div class="text-right m--margin-top-15">
      <button type="button" (click)="submitCancel(modal)" class="btn btn-success">
        {{ 'cancel-order.btn.success' | transloco }}
      </button>

      <button type="button" (click)="modal.dismiss()" class="btn btn-secondary">
        {{ 'cancel-order.btn.fatal' | transloco }}
      </button>
    </div>
  </div>
</ng-template>
