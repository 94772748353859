import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatePropertyModel, DateRange, toHttpParams } from 'hx-services';

@Injectable({
  providedIn: 'root'
})
export class VanillaService {

  constructor(
    private httpService: HttpClient,
  ) {
  }

  getProperties(params: {
    date?: DateRange,
    storeId?: number,
    month?: boolean
  }): Observable<DatePropertyModel[]> {
    return this.httpService.get<DatePropertyModel[]>(`/api/vanilla/property`, {params: toHttpParams(params, true)});
  }

  postProperty(body: any): Observable<any> {
    return this.httpService.post(`/api/vanilla/property/check`, body);
  }

  confirmProperties(body: any): Observable<any> {
    return this.httpService.post(`/api/vanilla/property/confirm`, body);
  }

  updateProperty(propertyId: number, body: any): Observable<any> {
    return this.httpService.post(`/api/vanilla/property/${propertyId}/update/check`, body);
  }

  confirmUpdateProperty(propertyId: number, body: any): Observable<any> {
    return this.httpService.put(`/api/vanilla/property/${propertyId}/confirm`, body);
  }

  getProperty(propertyId: number): Observable<DatePropertyModel> {
    return this.httpService.get<DatePropertyModel>(`/api/vanilla/property/${propertyId}`);
  }

  updateDecor(id: number, body: any): Observable<any> {
    return this.httpService.put(`/api/vanilla/property/decor/${id}`, body);
  }

  updateOnlineSellable(id: number, body: any): Observable<any> {
    return this.httpService.put(`/api/vanilla/property/online-sellable/${id}`, body);
  }
}
