<ng-container *ngIf="invoice">
  <div class="modal-header modal-header--brand">
    <h3 class="modal-title">{{ 'invoice-modal.invoice' | transloco }}</h3>
    <button class="close" type="button" (click)="modal.dismiss()">
      <span>×</span>
    </button>
  </div>

  <div class="modal-body m--padding-top-0" *ngIf="!isReject">
    <div class="product-wrap m--margin-bottom-20" *ngIf="invoice.products?.length && !isLoading.invoice">
      <app-invoice-product *ngFor="let product of invoice.products" class="product-wrap__item" [product]="product"></app-invoice-product>
    </div>

    <hx-loader [isLoading]="isLoading.invoice" [length]="invoice.products?.length"></hx-loader>

    <ng-container *ngIf="invoice.type">
      <h3 class="hc-title hc-title--small">{{ 'invoice-modal.type' | transloco }}</h3>
      <div class="text-left m--margin-top-15">
        <div class="product-wrap m--margin-bottom-20">
          {{ 'invoiceType.' + invoice.type  | transloco}}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="invoice.description">
      <h3 class="hc-title hc-title--small">{{ 'invoice-modal.note' | transloco }}</h3>
      <div class="text-left m--margin-top-15">
        <div class="product-wrap m--margin-bottom-20">
          {{ invoice.description }}
        </div>
      </div>
    </ng-container>

    <h3 class="hc-title hc-title--small" *ngIf="invoice.receiveUser && invoice.status === 'DISCARDED'">{{ 'invoice-modal.withhold' | transloco }}</h3>
    <div class="m-widget4 m-widget4--padding m--padding-bottom-20" *ngIf="invoice.receiveUser && invoice.status === 'DISCARDED'">
      {{ invoice.receiveUser.fullname }}
    </div>

    <h3 class="hc-title hc-title--small" *ngIf="invoice.files && invoice.files.length > 0">{{ 'invoice-modal.file' | transloco }}</h3>

    <div class="m-widget4 m-widget4--padding" *ngIf="invoice.files && invoice.files.length > 0">
      <div class="m-widget4__item" *ngFor="let file of invoice.files">
        <ng-container>
          <a *ngIf="file.mimeType !== 'application/pdf'" class="m-widget4__img m-widget4__img--icon"
             (click)="showImage(file)">
            <img [src]="baseLink + file.guid" [ngbTooltip]="file.fileName">
          </a>
          <a *ngIf="file.mimeType === 'application/pdf'" class="m-widget4__img m-widget4__img--icon"
             (click)="showImage(file)">
            <i class="la la-file-pdf-o la-2x"></i>
          </a>
          <div class="m-widget4__info" [title]="file.fileName">
            <span class="m-widget4__text">
              {{ file.fileName }}
            </span>
          </div>
        </ng-container>
      </div>

      <div class="image-container" *ngIf="image">
        <iframe *ngIf="image.mimeType === 'application/pdf'" class="iframe" width="100%" height="100%" [src]="image.path" frameborder="0"></iframe>
        <img *ngIf="image.mimeType !== 'application/pdf'" [src]="image.path" [ngbTooltip]="image.name" style="max-width: 100%;">
      </div>
    </div>

    <h3 class="hc-title hc-title--small">{{ 'invoice-modal.info' | transloco }}</h3>

    <app-invoice-events [id]="invoice.id"></app-invoice-events>

    <div class="text-right m--margin-top-15">
      <button type="button" [disabled]="isLoading.accept" (click)="accept()" *ngIf="canAccept"
              class="hc-button hc-button--success m--margin-right-10">
          {{ 'invoice-modal.btn.accept' | transloco }}
      </button>

      <button type="button" (click)="isReject = true;"
              *ngIf="invoice.status === 'SENT'"
              class="hc-button hc-button--danger">
        {{ cancelButtonLabel }}
      </button>
    </div>
  </div>

  <div class="modal-body" *ngIf="isReject">
    <label>
      {{ 'invoice-modal.cause' | transloco }}:
    </label>

    <textarea class="form-control m-input" [(ngModel)]="cancelReason"></textarea>

    <div class="text-right m--margin-top-15">
      <button type="button" [disabled]="isLoading.rejectOrCancel" (click)="rejectOrCancelInvoice()"
              class="hc-button hc-button-success m--margin-right-10">
        {{ 'invoice-modal.btn.fatal' | transloco }}
      </button>

      <button type="button" (click)="isReject = false;" class="hc-button hc-button--secondary">
        {{ 'invoice-modal.btn.success' | transloco }}
      </button>
    </div>
  </div>
</ng-container>>
