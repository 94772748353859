import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';
import { HcToastrService } from '@manager-app/service/toastr.service';
import { HxOrderService } from 'hx-services';

/**
 * Component of cancel order Button with modal and reason textarea
 *
 * @export
 * @class CancelOrderComponent
 */
@Component({
  selector: 'app-cancel-order',
  templateUrl: './cancel-order.component.html',
  styleUrls: ['./cancel-order.component.css']
})
export class CancelOrderComponent {
  @Output() update = new EventEmitter<void>();
  _id = null;
  @Input() order;
  @Input()
  set id(id) {
    this._id = id || null;
  }
  get id(): number {
    return this._id;
  }
  @ViewChild('cancelOrder') public cancelOrder: TemplateRef<any>;
  cancelReason: string;

  constructor(
    public modal: NgbModal,
    public toastr: HcToastrService,
    private orderService: HxOrderService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  public showModal(): void {
    const modal = this.modal.open(this.cancelOrder, { });

    modal.result
      .then(result => {
        this.cancelReason = '';
      })
      .catch(err => {
        this.cancelReason = '';
      });
  }

  public submitCancel(dialogRef): void {
    const obj = {
      reason: this.cancelReason
    };

    this.orderService.cancelOrder(this.id, obj).subscribe(() => {
        this.update.emit();
        dialogRef.dismiss();
      },
      err => this.errorHandlerService.check(err.error)
    );
  }
}
