<ng-select class="min-width-15" [items]="items"
           [placeholder]="'suppliers-search.nameSuppliers' | transloco"
           [typeToSearchText]="'suppliers-search.search' | transloco"
           [notFoundText]="'suppliers-search.noFind' | transloco"
           [loadingText]="'suppliers-search.loaderSearch' | transloco"
           [multiple]="false"
           [hideSelected]="true"
           [trackByFn]="trackByFn"
           [loading]="itemLoading"
           [typeahead]="itemInput$"
           (change)="onSelectChanged($event)"
           [(ngModel)]="model"
           [searchable]="true"
           [clearable]="true">
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <span class="ng-value-label">{{item.name}} <span *ngIf="item.uin">({{item.uin}})</span></span>
    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    {{item.name}} <span *ngIf="item.uin">({{item.uin}})</span>
  </ng-template>
</ng-select>
