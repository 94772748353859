<div class="modal-header">
  <h3 class="modal-title" *ngIf="!isEdit">{{ 'user-contract.addContract' | transloco }}</h3>
  <h3 class="modal-title" *ngIf="isEdit">{{ 'user-contract.contractEditing' | transloco }}</h3>
  <button class="close" type="button" (click)="modalDismiss()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <div class="m-portlet__body">
    <div class="m-form__section m-form__section--first m-form--label-align-right">
      <div class="form-group m-form__group row">
        <label class="col-form-label col-lg-4 col-md-4 col-sm-12 is--required">
          {{ 'user-contract.employeeType' | transloco }}:
        </label>
        <div class="col-lg-8 col-md-8 col-sm-12">
          <hx-enum-select [enumType]="EmployeeType" [enumName]="'EmployeeType'" [(ngModel)]="employeeType"></hx-enum-select>
        </div>
      </div>

      <div class="form-group m-form__group row">
        <label class="col-form-label col-lg-4 col-md-4 col-sm-12 is--required">
          {{ 'user-contract.fromDate' | transloco }}:
        </label>
        <div class="col-lg-8 col-md-8 col-sm-12">
          <hx-date-select [(ngModel)]="fromDate"></hx-date-select>
        </div>
      </div>

      <div class="form-group m-form__group row">
        <label class="col-form-label col-lg-4 col-md-4 col-sm-12">
          {{ 'user-contract.toDate' | transloco }}:
        </label>
        <div class="col-lg-8 col-md-8 col-sm-12">
          <hx-date-select [(ngModel)]="toDate"></hx-date-select>
        </div>
      </div>

      <div class="form-group m-form__group row">
        <label class="col-form-label col-lg-4 col-md-4 col-sm-12 is--required">
          {{ 'user-contract.accrualType' | transloco }}:
        </label>
        <div class="col-lg-8 col-md-8 col-sm-12">
          <hx-enum-select [enumType]="AccrualType" [enumName]="'AccrualType'" [(ngModel)]="accrualType"></hx-enum-select>
        </div>
      </div>

      <div class="form-group m-form__group row">
        <label class="col-form-label col-lg-4 col-md-4 col-sm-12 is--required">
          {{ 'user-contract.accrualValue' | transloco }}:
        </label>
        <div class="col-lg-8 col-md-8 col-sm-12">
          <input [(ngModel)]="accrualValue" type="number" class="form-control m-input">
        </div>
      </div>

      <div class="form-group m-form__group row">
        <label class="col-form-label col-lg-4 col-md-4 col-sm-12">
          {{ 'user-contract.bonus' | transloco }}:
        </label>
        <div class="col-lg-8 col-md-8 col-sm-12">
          <input [(ngModel)]="bonus" type="number" class="form-control m-input">
        </div>
      </div>

      <div class="form-group m-form__group row">
        <label class="col-form-label col-lg-4 col-md-4 col-sm-12">
          {{ 'user-contract.bonusSalesPercent' | transloco }}:
        </label>
        <div class="col-lg-8 col-md-8 col-sm-12">
          <input [(ngModel)]="bonusSalesPercent" type="number" class="form-control m-input">
        </div>
      </div>

      <div class="form-group m-form__group row">
        <label class="col-form-label col-lg-4 col-md-4 col-sm-12">
          {{ 'user-contract.bonusDeliveryPercent' | transloco }}:
        </label>
        <div class="col-lg-8 col-md-8 col-sm-12">
          <input [(ngModel)]="bonusDeliveryValue" type="number" class="form-control m-input">
        </div>
      </div>

      <div class="form-group m-form__group row">
        <label class="col-form-label col-lg-4 col-md-4 col-sm-12">
          {{ 'user-contract.amortizationValue' | transloco }}:
        </label>
        <div class="col-lg-8 col-md-8 col-sm-12">
          <input [(ngModel)]="amortizationValue" type="number" class="form-control m-input">
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer modal-footer--pad-right">
    <button type="submit" class="btn btn-success" (click)="save()">
      {{ 'btn.save' | transloco }}
    </button>

    <button type="button" (click)="modalDismiss()" class="btn btn-secondary">
      {{ 'btn.cancel' | transloco }}
    </button>
  </div>
</div>
