import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HxSupplierService, SupplierFullModel, SupplierModel } from 'hx-services';


@Component({
  selector: 'app-suppliers-search',
  templateUrl: './suppliers-search.component.html',
  styleUrls: ['./suppliers-search.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SuppliersSearchComponent),  // replace name as appropriate
      multi: true
    }
  ]
})
export class SuppliersSearchComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() storeId?: number;
  @Input() storeIds?: number[];
  @Input() organizationId?: number;
  @Output() selectChange = new EventEmitter<SupplierModel | undefined>();
  private _selected: number;
  get selected() {
    return this._selected || undefined;
  }

  set selected(val: number | undefined) {
    this._selected = val || undefined;
    if (val) {
      this.loadSupplierById(val);
    } else {
      this.model = undefined;
    }
    this.onChange(val);
  }

  items: SupplierModel[] = [];
  model?: SupplierFullModel;
  itemLoading = false;
  itemInput$ = new Subject<string>();
  private $destroyed = new Subject<void>();

  constructor(
    private supplierService: HxSupplierService,
  ) {
  }

  ngOnInit() {
    this.loadItems();
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  writeValue(id: number): void {
    this.selected = id;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  onChange(val: any) {
  }

  onSelectChanged(supplier?: SupplierModel) {
    if (supplier) {
      this.selected = supplier.id;
      this.selectChange.emit(supplier);
    } else {
      this.selected = undefined;
      this.selectChange.emit(undefined);
    }
  }

  trackByFn(item: SupplierModel) {
    return item.id;
  }

  private loadSupplierById(id: number): void {
    this.supplierService.getSupplierById(id).subscribe(result => {
      this.model = result;
    });
  }

  private loadItems() {
    this.itemInput$.pipe(
      takeUntil(this.$destroyed),
      debounceTime(500),
      distinctUntilChanged(),
      filter(term => {
        const isOk = (term ?? '').trim() !== '';
        this.itemLoading = isOk;
        return isOk;
      }),
      switchMap(term => {
        let storeIdList: number[] = [];
        if (this.storeId) {
          storeIdList.push(this.storeId);
        }
        if (this.storeIds && this.storeIds.length) {
          storeIdList = this.storeIds;
        }
        return this.supplierService.getSuppliers({
          storeIds: storeIdList,
          organizationId: this.organizationId,
          query: term,
          limit: 1000
        }).pipe(
          map(pagedResult => pagedResult.list),
          catchError(() => of([] as SupplierModel[])), // empty list on error
          tap(() => this.itemLoading = false)
        );
      })
    ).subscribe(searchResult => this.items = searchResult);
  }
}
