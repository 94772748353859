<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title title">
        {{ 'invoice-list.title' | transloco }}
      </h3>
    </div>

    <div class="m-subheader__btn-group">
      <span class="m--margin-right-10">
        <button *ngIf="discardProductCount > 0" (click)="showDiscardModal()" class="hc-button hc-button--danger" [title]="'invoice-list.writeProducts' | transloco">
          <span>
            <span>
              <span class="m--hidden-mobile">{{ 'invoice-list.inventory' | transloco }}</span>
              <span> - {{ discardProductCount }}</span>
            </span>
          </span>
        </button>
      </span>

      <button type="button" class="hc-button hc-button--success" (click)="exportBalance()">
        <span>
          {{ 'invoice-list.export' | transloco }}
        </span>
      </button>
    </div>
  </div>
</div>

<div class="hc-content">
  <div class="row">
    <div class="col-md-12">
      <div class="hc-content__wrap">
        <app-balance-view [storeId]="params.storeId" (storeChange)="onStoreChanged($event)"></app-balance-view>

        <div *ngIf="params.storeId">
          <hr class="hr">

          <h4 class="title title--small m--margin-bottom-20">{{ 'invoice-list.invoice' | transloco }}</h4>

          <div class="row m--margin-bottom-20">
            <div class="col-md-3">
              <hx-enum-select [(ngModel)]="params.invoiceStatus" enumName="InvoiceStatus" [enumType]="InvoiceStatus"
                              (selectChange)="applyFilters()"></hx-enum-select>
            </div>

            <div class="col-md-3">
              <hx-date-select [(ngModel)]="params.date" (selectChange)="applyFilters()"></hx-date-select>
            </div>

            <div class="col-md-2">
              <button (click)="resetFilter()" class="btn btn-primary hc-button hc-button--secondary" type="button">
                <hc-icon icon="refresh"></hc-icon>
                <span>{{ 'button.reset' | transloco }}</span>
              </button>
            </div>
          </div>

          <div class="is--responsive-table m--margin-top-30 m--margin-bottom-30" *ngIf="list.length && !isLoading.list">
            <table class="hc-table hc-table--brand">
              <thead class="hc-table__head">
                <tr class="hc-table__row hc-table__row--head">
                  <th class="hc-table__cell">
                    <span>{{ 'invoice-list.departureData' | transloco }}</span>
                  </th>
                  <th class="hc-table__cell">
                    <span>{{ 'invoice-list.status' | transloco }}</span>
                  </th>
                  <th class="hc-table__cell">
                    <span>{{ 'invoice-list.were' | transloco }}</span>
                  </th>
                  <th class="hc-table__cell">
                    <span>{{ 'invoice-list.received' | transloco }}</span>
                  </th>
                  <th class="hc-table__cell">
                    <span>{{ 'invoice-list.description' | transloco }}</span>
                  </th>
                </tr>
              </thead>

              <tbody class="hc-table__body">
                <tr *ngFor="let i = index; let item of list" class="hc-table__row" (click)="showInvoiceModal(item)">
                  <td class="hc-table__cell">
                    <hc-icon icon="clock"></hc-icon>
                    <span>{{ item.sendDate | date: 'dd.MM.yy / HH:mm' }}</span>
                  </td>

                  <td class="hc-table__cell">
                    <span class="m-badge  m-badge--{{ statusList[item.status]?.badge }} m-badge--wide m-badge--rounded">
                      {{ 'hx.enum.InvoiceStatus.' + item.status | transloco }}
                    </span>
                  </td>

                  <td class="hc-table__cell">
                    <span class="m--margin-right-10">{{ item.fromStore?.title | uiLabel }}</span>
                    <hc-icon icon="left-arrow"></hc-icon>
                    <span>{{ item.toStore?.title | uiLabel }}</span>
                  </td>

                  <td class="hc-table__cell">
                    <span *ngIf="item.senderUser" class="m--margin-right-10"
                          [ngbTooltip]="item.senderUser.fullname">{{ item.senderUser.fullname | fio }}</span>
                    <hc-icon icon="left-arrow" *ngIf="item.receiveUser"></hc-icon>
                    <span *ngIf="item.receiveUser"
                          [ngbTooltip]="item.receiveUser.fullname">{{ item.receiveUser.fullname | fio }}</span>
                  </td>

                  <td class="hc-table__cell">
                    {{ item.description }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <hx-loader [length]="list.length" [isLoading]="isLoading.list"></hx-loader>

          <div class="m--align-center" *ngIf="list.length && pagination?.allItemCount > 0">
            <ngb-pagination [maxSize]="5" [rotate]="true" [collectionSize]="pagination.allItemCount"
                            [(page)]="params.page" [pageSize]="params.limit" (pageChange)="pageChanged($event)"
                            class="d-flex justify-content-center mt-4">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
