import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HxOrganizationService, OrganizationModel, OrganizationWithUinModel } from 'hx-services';

@Component({
  selector: 'app-organization-select',
  templateUrl: './organization-select.component.html',
  styleUrls: ['./organization-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OrganizationSelectComponent),
    multi: true
  }]
})
export class OrganizationSelectComponent implements OnInit, ControlValueAccessor {
  @Input() multiple = false;
  @Input() countryId?: number;
  @Output() selectChange = new EventEmitter<OrganizationModel | OrganizationModel[] | undefined>();

  options: OrganizationWithUinModel[] = [];
  isDisabled = false;
  private $selected?: number | number[];

  get selected(): number | number[] | undefined {
    return this.$selected;
  }

  set selected(val: number | number[] | undefined) {
    this.$selected = val;
    this.onChange(val);
  }

  constructor(
    private orgService: HxOrganizationService,
  ) {
  }

  ngOnInit(): void {
    this.getOrganizations();
  }

  onModelChanged(val: any) {
    this.selectChange.emit(this.getSelectedOptions());
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    this.selected = obj;
    this.selectChange.emit(this.getSelectedOptions());
    setTimeout(() => this.onChange(this.selected)); // fix for usage with reactive forms (formControlName)
  }

  private getSelectedOptions(): OrganizationModel | OrganizationModel[] | undefined {
    if (!this.multiple) {
      if (this.selected) {
        return this.options.find(p => p.organization.id === this.selected)?.organization;
      } else {
        return undefined;
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        return this.options.filter(p => ids.includes(p.organization.id)).map(p => p.organization);
      } else {
        return [];
      }
    }
  }

  private async getOrganizations() {
    this.options = await this.orgService.getMyOrganizations({countryId: this.countryId});
  }
}
